<template>
  <!-- 标准件 -->
  <!-- 1 —— 路内停车场 -->
  <!-- 2 —— 路外停车场 -->
  <!-- 3 —— 巡检人员 -->
  <!-- 4 —— 长沙地图 -->
  <!-- 5 —— 中国地图 -->
  <!-- 6 —— 停车场总览 -->
  <!-- 7 —— 用户分析 -->
  <!-- 8 —— 支付方式 -->
  <!-- 9 —— 订单统计 -->
  <!-- 10 —— 收入分析 -->
  <!-- 11 —— 车流量分析 -->
  <!-- 12 —— 设备 -->
  <!-- 13 —— 停车时长 -->
  <!-- 14 —— 出入记录 -->
  <!-- 15 —— 设备数量统计 -->
  <!-- 16 —— 设备状态统计 -->
  <!-- 17 —— 人流量分析 -->
  <!-- 18 —— 人员统计 -->
  <!-- 19 —— 实时抓拍 -->
  <!-- 20 —— 总收入 -->
  <!-- 21 —— 今日收入 -->
  <!-- 22 —— 总订单 -->
  <!-- 23 —— 今日订单 -->
  <!-- 24 —— 组织 -->
  <!-- 25 —— 设备总数 -->
  <!-- 26 —— 人员 -->
  <!-- 27 —— 访客 -->
  <!-- 29 —— 异常事件概览 -->
  <!-- 30 —— 告警类型 -->
  <!-- 31 —— 告警排名 -->
  <!-- 32 —— 异常事件告警 -->
  <!-- 33 —— 异常事件趋势分析 -->
  <!-- 34 —— 视频监控 -->
  <!-- 35 —— 场景 -->
  <!-- 36 —— 告警次数 -->
  <!-- 37 —— 场景 -->
  <!-- 38 —— 组织 -->
  <MapRightItem :title="data.standard_name" icon="lebo-lntcq" :isCheck="isShowInParkingMark" @click="toggleInParking"
    v-if="data.block_enum == 1"></MapRightItem>
  <MapRightItem :title="data.standard_name" icon="lebo-lwtcc" :isCheck="isShowOutParkingMark" @click="toggleOutParking"
    v-else-if="data.block_enum == 2"></MapRightItem>
  <MapRightItem :title="data.standard_name" icon="lebo-xjry" :isCheck="isShowInspectorMarker"
    @click="toggleInspectorMarker" v-else-if="data.block_enum == 3"></MapRightItem>
  <MapRightItem :title="data.standard_name" icon="lebo-shipinjiankong" :isCheck="isShowScenceMarker"
    @click="toggleScenceMarker" v-else-if="data.block_enum == 37"></MapRightItem>
  <MapRightItem :title="data.standard_name" icon="lebo-qiye" :isCheck="isShowGroupMarker" @click="toggleGroupMarker"
    v-else-if="data.block_enum == 38"></MapRightItem>
  <!-- 长沙地图  -->
  <bigScreenModel icon="lebo-ditu" :title="data.standard_name" v-else-if="data.block_enum == 4">
    <!-- <div class="echarts_box" :id="`ecartsBox${data.block_enum}-${index}`" v-if="dataList && dataList.length > 0"></div> -->
    <div class="echarts_box" :id="`ecartsBox${data.block_enum}-${index}`"></div>
    <!-- <div class="empty_text" v-else>暂无数据</div> -->
  </bigScreenModel>
  <!-- 中国地图 -->
  <bigScreenModel icon="lebo-ditu" :title="data.standard_name" v-else-if="data.block_enum == 5">
    <!-- <div class="echarts_box" :id="`ecartsBox${data.block_enum}-${index}`" v-if="dataList && dataList.length > 0"></div> -->
    <div class="echarts_box" :id="`ecartsBox${data.block_enum}-${index}`"></div>
    <!-- <div class="empty_text" v-else>暂无数据</div> -->
  </bigScreenModel>
  <!-- 停车场  -->
  <bigScreenModel icon="lebo-tingchechang2" :title="data.standard_name" v-else-if="data.block_enum == 6">
    <ul class="custom_big_screen_parking_box">
      <li class="command-center-parking-item"
        v-if="data.select_data.indexOf('1') !== -1 || data.select_data.indexOf('9a233028cc4548a89fd2f910') !== -1">
        <span class="count">{{ dataList.countTotalParking }}</span>
        <span class="title">车场总数</span>
      </li>
      <li class="command-center-parking-item"
        v-if="data.select_data.indexOf('2') !== -1 || data.select_data.indexOf('9a233028cc4548a89fd2f911') !== -1">
        <span class="count">{{ dataList.countInRoadParking }}</span>
        <span class="title">路内停车场</span>
      </li>
      <li class="command-center-parking-item"
        v-if="data.select_data.indexOf('3') !== -1 || data.select_data.indexOf('9a233028cc4548a89fd2f912') !== -1">
        <span class="count">{{ dataList.countOffRoadParking }}</span>
        <span class="title">路外停车场</span>
      </li>
      <li class="command-center-parking-item"
        v-if="data.select_data.indexOf('4') !== -1 || data.select_data.indexOf('9a233028cc4548a89fd2f913') !== -1">
        <span class="count">{{ dataList.berth_num }}</span>
        <span class="title">车位数</span>
      </li>

      <li class="command-center-parking-item"
        v-if="data.select_data.indexOf('5') !== -1 || data.select_data.indexOf('9a233028cc4548a89fd2f914') !== -1">
        <span class="count">{{ dataList.countTotaldevic }}</span>
        <span class="title">设备数</span>
      </li>
      <li class="command-center-parking-item"
        v-if="data.select_data.indexOf('6') !== -1 || data.select_data.indexOf('9a233028cc4548a89fd2f915') !== -1">
        <span class="count">{{ dataList.countCarflows }}</span>
        <span class="title">车流量</span>
      </li>
    </ul>
  </bigScreenModel>
  <!-- 用户 -->
  <bigScreenModel icon="lebo-yonghu" :title="data.standard_name" v-else-if="data.block_enum == 7">
    <ul class="custom_big_screen_parking_box" v-if="dataList.countAccount > 0">
      <li class="command-center-user-item">
        <span class="count">{{ dataList.countAccount }}</span>
        <span class="title">车主用户</span>
      </li>
      <li class="command-center-user-item">
        <span class="count">{{ dataList.countCar }}</span>
        <span class="title">绑定车牌数</span>
      </li>
      <li class="command-center-user-item">
        <span class="count">{{ dataList.countActive }}</span>
        <span class="title">30日活跃数</span>
      </li>
    </ul>
    <div class="empty_text" v-else>暂无数据</div>
  </bigScreenModel>
  <!-- 支付 -->
  <bigScreenModel icon="lebo-zffs" :title="data.standard_name" v-else-if="data.block_enum == 8">
    <div class="innerPie">
      <!-- <div class="innerPic"></div> -->
    </div>
    <div class="echarts_box" :id="`ecartsBox${data.block_enum}-${index}`"></div>
  </bigScreenModel>
  <!-- 订单 -->
  <bigScreenModel icon="lebo-dingdan1" :title="data.standard_name" :rightList="orderRightList"
    :order_status="order_status" @rightClick="fnOrderRightClick" v-else-if="data.block_enum == 9">
    <div class="command-center-order" v-if="dataList &&
      dataList.parkingOrders &&
      dataList.parkingOrders.length > 0 &&
      order_status == 1
      ">
      <li class="command-center-heard">
        <span class="heard-col col-1">车牌号</span>
        <span class="heard-col col-1">停车场</span>
        <span class="heard-col col-2">金额</span>
      </li>
      <ul class="command-center-item_box">
        <li class="command-center-item" v-for="(item, index) in dataList.parkingOrders" :key="index">
          <span class="item-col col-1">{{ item.plate_number }}</span>
          <span class="item-col col-1">{{ item.parking_name }}</span>
          <span class="item-count col-2">￥{{ item.final_cost.toFixed(2) }}</span>
        </li>
      </ul>
    </div>
    <div class="command-center-order" v-else-if="dataList &&
      dataList.orderRankingList &&
      dataList.orderRankingList.length > 0 &&
      order_status == 2
      ">
      <li class="command-center-heard">
        <span class="heard-col col-3">排名</span>
        <span class="heard-col col-1">停车场</span>
        <span class="heard-col col-3">订单数</span>
        <span class="heard-col col-2">总收入</span>
      </li>
      <ul class="command-center-item_box">
        <li class="command-center-item" v-for="(item, index) in dataList.orderRankingList" :key="index">
          <span class="item-col col-3">{{ index + 1 }}</span>
          <span class="item-col col-1"> {{ item.parking_name }}</span>
          <span class="item-col col-3">{{ item.order_number }}</span>
          <span class="item-count col-2">￥{{
            ((item.order_cost_sum * -1).toFixed(2)) * -1 ? ((item.order_cost_sum * -1).toFixed(2)) * -1 : '0.00'
          }}</span>
        </li>
      </ul>
    </div>
    <div class="command-center-order" v-else>
      <li class="command-center-heard">
        <span class="heard-col col-1">车牌号</span>
        <span class="heard-col col-1">停车场</span>
        <span class="heard-col col-2">金额</span>
      </li>
      <ul class="command-center-item_box">
        <li class="command-center-item" v-for="(item, index) in 5" :key="index">
          <span class="item-col col-1">暂无车辆</span>
          <span class="item-col col-1">暂无停车场</span>
          <span class="item-count col-2">￥0.00</span>
        </li>
      </ul>
    </div>
    <!-- <div class="empty_text" v-else>暂无数据</div> -->
  </bigScreenModel>
  <!-- 收入分析 -->
  <bigScreenModel icon="lebo-shouru" :title="data.standard_name" :rightList="shouruList" :order_status="shouru_status"
    @rightClick="fnShouRuRightClick" :small="true" v-else-if="data.block_enum == 10">
    <div class="echarts_box" :id="`ecartsBox${data.block_enum}-${index}`"></div>
  </bigScreenModel>
  <!-- 车流量 -->
  <bigScreenModel icon="lebo-cll" :title="data.standard_name" :rightList="shouruList" :order_status="carFlow_status"
    @rightClick="fnCarRightClick" :small="true" v-else-if="data.block_enum == 11">
    <div class="echarts_box" :id="`ecartsBox${data.block_enum}-${index}`"></div>
  </bigScreenModel>
  <!-- 设备 -->
  <bigScreenModel icon="lebo-shebei" :title="data.standard_name" v-else-if="data.block_enum == 12 && dataList">
    <div class="command-center-equipment">
      <deviceItem icon="lebo-gwcpsbxj" title="高位车牌识别相机" :count="dataList.countHighCamera"
        v-if="data.select_data.indexOf('3') !== -1 || data.select_data.indexOf('9a233028cc4548a89fd2f945') !== -1">
      </deviceItem>
      <deviceItem icon="lebo-cpsbspz" title="车牌识别视频桩" :count="dataList.countVideoPile"
        v-if="data.select_data.indexOf('1') !== -1 || data.select_data.indexOf('f884246c5ee84cf2bf232a03') !== -1">
      </deviceItem>
      <deviceItem icon="lebo-cpsblyj" title="车牌识别路牙机" :count="dataList.countMachine"
        v-if="data.select_data.indexOf('4') !== -1 || data.select_data.indexOf('120b2032410c4ab0ac6ab134') !== -1">
      </deviceItem>
      <deviceItem icon="lebo-cpsbxj" title="车牌识别相机" :count="dataList.countIdentifycamera"
        v-if="data.select_data.indexOf('5') !== -1 || data.select_data.indexOf('1d8069629ccd4eca9220cec2') !== -1">
      </deviceItem>
      <deviceItem icon="lebo-qrsdc" title="嵌入式地磁" :count="dataList.countGeomagnetic"
        v-if="data.select_data.indexOf('2') !== -1 || data.select_data.indexOf('e589b2f9778449da969827d2') !== -1">
      </deviceItem>
      <deviceItem icon="lebo-yitiji" title="支付一体机" :count="dataList.countIntegratedmachine"
        v-if="data.select_data.indexOf('7') !== -1 || data.select_data.indexOf('6da09262862e409aafbe19d2') !== -1">
      </deviceItem>
      <deviceItem icon="lebo-rlsbytj" title="人脸识别一体机" :count="dataList.countdeviceface"
        v-if="data.select_data.indexOf('9') !== -1 || data.select_data.indexOf('4241ac584cc046a988068e48') !== -1">
      </deviceItem>
      <deviceItem icon="lebo-spjk" title="视频监控" :count="dataList.countMonitor"
        v-if="data.select_data.indexOf('10') !== -1 || data.select_data.indexOf('e726f13bbd7e434b86f22e75') !== -1">
      </deviceItem>
      <deviceItem icon="lebo-bianyuanfenxihe_x" title="边缘分析盒" :count="dataList.countaiboxdevice"
        v-if="data.select_data.indexOf('14') !== -1 || data.select_data.indexOf('14c00660898442e78a085a6d') !== -1">
      </deviceItem>
    </div>
  </bigScreenModel>
  <!-- 停车时长 -->
  <bigScreenModel icon="lebo-tingcheshichang" :title="data.standard_name" v-else-if="data.block_enum == 13">
    <div class="echarts_box" :id="`ecartsBox${data.block_enum}-${index}`"></div>
  </bigScreenModel>
  <!-- 出入记录 -->
  <bigScreenModel icon="lebo-churujilu" :title="data.standard_name" :rightList="recordRightList" :order_status="parktype"
    @rightClick="fnRecordRightClick" v-else-if="data.block_enum == 14">
    <ul class="access-records-box" v-if="dataList && dataList.length > 0">
      <li class="access-records-item" v-for="(item, index) in dataList" :key="index">
        <el-image fit="cover" :src="getFirstImage(item.go_image)"
          :preview-src-list="formatImage(getFirstImage(item.go_image))" :z-index="10000">
          <div slot="error" class="image-error">
            <i class="iconfont lebo-picerr"></i>
            <span>加载失败</span>
          </div>
        </el-image>
      </li>
    </ul>
    <div class='empty_text' v-else>
      <ul class="access-records-box">
        <li class="access-records-item" v-for="(item, index) in 4" :key="index">
          <el-image fit="contain" :src="model === 1 ? noCarsInDark : noCarsInLight"
            :preview-src-list="formatImage(getFirstImage(item.go_image))" :z-index="10000">
            <div slot="error" class="image-error">
              <i class="iconfont lebo-picerr"></i>
              <span>加载失败</span>
            </div>
          </el-image>
        </li>
      </ul>
      <!-- <div :class="['emptyBox', darkLisgt]">
      </div>
      <span>暂无数据</span> -->
    </div>
  </bigScreenModel>
  <!-- 设备数量统计 -->
  <bigScreenModel icon="lebo-sbsltj" :title="data.standard_name" v-else-if="data.block_enum == 15">
    <div class="echarts_box" :id="`ecartsBox${data.block_enum}-${index}`"></div>
  </bigScreenModel>
  <!-- 16 —— 设备状态统计 -->
  <bigScreenModel icon="lebo-sbzttj" :title="data.standard_name" v-else-if="data.block_enum == 16">
    <div class="echarts_box" :id="`ecartsBox${data.block_enum}-${index}`"></div>
  </bigScreenModel>
  <!-- 17 —— 人流量分析 -->
  <bigScreenModel icon="lebo-rllfx" :title="data.standard_name" :rightList="shouruList" :order_status="people_status"
    @rightClick="fnPeopleClick" :small="true" v-else-if="data.block_enum == 17">
    <div class="echarts_box" :id="`ecartsBox${data.block_enum}-${index}`"></div>
  </bigScreenModel>
  <!-- 18 —— 人员统计 -->
  <bigScreenModel icon="lebo-rytj" :title="data.standard_name" v-else-if="data.block_enum == 18">
    <div class="echarts_box" :id="`ecartsBox${data.block_enum}-${index}`"></div>
  </bigScreenModel>
  <!-- 19 —— 实时抓拍 -->
  <bigScreenModel icon="lebo-sszp" :title="data.standard_name" v-else-if="data.block_enum == 19">
    <ul class="access-records-box" v-if="dataList && dataList.length > 0">
      <li class="face-records-item" v-for="(item, index) in dataList" :key="index">
        <el-image fit="cover" :src="getFirstImage(item.pic)" :preview-src-list="formatImage(getFirstImage(item.pic))"
          :z-index="10000">
          <div slot="error" class="image-error">
            <i class="iconfont lebo-picerr"></i>
            <span>加载失败</span>
          </div>
        </el-image>
      </li>
    </ul>
    <div class='empty_text' v-else>
      <ul class="access-records-box">
        <li class="face-records-item" v-for="(item, index) in 6" :key="index">
          <el-image fit="contain" :src="model === 1 ? noPeopleInDark : noPeopleInLight" :z-index="10000">
          </el-image>
        </li>
      </ul>
      <!-- <div :class="['emptyBox', picCatch]">
      </div>
      <span>暂无数据</span> -->
    </div>
  </bigScreenModel>
  <!-- 20 —— 总收入 -->
  <!-- 21 —— 今日收入 -->
  <!-- 22 —— 总订单 -->
  <!-- 23 —— 今日订单 -->
  <!-- 24 —— 组织 -->
  <!-- 25 —— 设备总数 -->
  <!-- 26 —— 人员 -->
  <!-- 27 —— 访客 -->
  <div class="command-center-total" v-else-if="data.block_enum == 20 && dataList">
    <span class="count">{{ dataList.totalIncome }}</span>
    <span class="title-box">
      <span class="title">{{ data.standard_name }}</span>
      <span class="sub-title">(元)</span>
    </span>
  </div>
  <div class="command-center-total" v-else-if="data.block_enum == 21 && dataList">
    <span class="count">{{ dataList.totalDayIncome }}</span>
    <span class="title-box">
      <span class="title">{{ data.standard_name }}</span>
      <span class="sub-title">(元)</span>
    </span>
  </div>
  <div class="command-center-total" v-else-if="data.block_enum == 22 && dataList">
    <span class="count">{{ dataList.totalOrder }}</span>
    <span class="title-box">
      <span class="title">{{ data.standard_name }}</span>
      <span class="sub-title">(个)</span>
    </span>
  </div>
  <div class="command-center-total" v-else-if="data.block_enum == 23 && dataList">
    <span class="count">{{ dataList.totalDayOrder }}</span>
    <span class="title-box">
      <span class="title">{{ data.standard_name }}</span>
      <span class="sub-title">(个)</span>
    </span>
  </div>
  <div class="command-center-total" v-else-if="data.block_enum == 24 && dataList">
    <span class="count">{{ dataList.totalorganizationcount }}</span>
    <span class="title-box">
      <span class="title">{{ data.standard_name }}</span>
      <span class="sub-title">(个)</span>
    </span>
  </div>
  <div class="command-center-total" v-else-if="data.block_enum == 25 && dataList">
    <span class="count">{{ dataList.totaldevicecount }}</span>
    <span class="title-box">
      <span class="title">{{ data.standard_name }}</span>
      <span class="sub-title">(个)</span>
    </span>
  </div>
  <div class="command-center-total" v-else-if="data.block_enum == 26 && dataList">
    <span class="count">{{ dataList.totalpersonnelcount }}</span>
    <span class="title-box">
      <span class="title">{{ data.standard_name }}</span>
      <span class="sub-title">(个)</span>
    </span>
  </div>
  <div class="command-center-total" v-else-if="data.block_enum == 27 && dataList">
    <span class="count">{{ dataList.totalvisitorcount }}</span>
    <span class="title-box">
      <span class="title">{{ data.standard_name }}</span>
      <span class="sub-title">(个)</span>
    </span>
  </div>
  <!-- <MapRightItem title="充电站" icon="lebo-cdz" :isCheck="false"></MapRightItem>
  <MapRightItem title="诱导屏" icon="lebo-ydp" :isCheck="false"></MapRightItem> -->
  <!-- <bigScreenModel icon="lebo-zffs" :title="data.standard_name" v-else></bigScreenModel> -->
  <!-- new--- -->
  <!-- ** 异常事件概览 -->
  <bigScreenModel icon="lebo-shebei" :title="data.standard_name" v-else-if="data.block_enum == 29">
    <div class="command-center-equipment" v-if="dataList && dataList.length > 0">
      <deviceItem icon="lebo-weichuanchushifu" title="未穿工作服" :count="findAlarmTotal(dataList, '4')"
        v-if="data.select_data.indexOf('4') !== -1 || data.select_data.indexOf('1a46c6bf8fb84778b9cb9ab9') !== -1">
      </deviceItem>
      <deviceItem icon="lebo-weidaikouzhao" title="未戴口罩" :count="findAlarmTotal(dataList, '2')"
        v-if="data.select_data.indexOf('2') !== -1 || data.select_data.indexOf('90326d7472ad4dc9adcbddb5') !== -1">
      </deviceItem>
      <deviceItem icon="lebo-dadianhua" title="打电话" :count="findAlarmTotal(dataList, '8')"
        v-if="data.select_data.indexOf('8') !== -1 || data.select_data.indexOf('89a3cc4b4a2647178603fdab') !== -1">
      </deviceItem>
      <deviceItem icon="lebo-weidaichushimao" title="未戴厨师帽" :count="findAlarmTotal(dataList, '1')"
        v-if="data.select_data.indexOf('1') !== -1 || data.select_data.indexOf('847c4f65417141e1a7839b54') !== -1">
      </deviceItem>
      <deviceItem icon="lebo-xiyan" title="吸烟" :count="findAlarmTotal(dataList, '16')"
        v-if="data.select_data.indexOf('16') !== -1 || data.select_data.indexOf('75a9ffca112c4f148bcfc0ce') !== -1">
      </deviceItem>
      <deviceItem icon="lebo-youhaishengwu" title="有害生物" :count="findAlarmTotal(dataList, '1024')"
        v-if="data.select_data.indexOf('1024') !== -1 || data.select_data.indexOf('0a67b1c4fb2d4980872de189') !== -1">
      </deviceItem>
      <deviceItem icon="lebo-weigailajitong" title="未盖垃圾桶" :count="findAlarmTotal(dataList, '65536')"
        v-if="data.select_data.indexOf('65536') !== -1 || data.select_data.indexOf('6f5a4caecf3f402ba9680943') !== -1">
      </deviceItem>
    </div>
    <div class="empty_text" v-else>暂无数据</div>
  </bigScreenModel>
  <!-- ** 告警类型 -->
  <bigScreenModel icon="lebo-cll" :title="data.standard_name" v-else-if="data.block_enum == 30">
    <template v-if="dataList && dataList.length > 0">
      <div class="echarts_box" :id="`ecartsBox${data.block_enum}-${index}`"></div>
    </template>
    <div class="empty_text" v-else>暂无数据</div>
  </bigScreenModel>
  <!-- ** 告警排名 -->
  <bigScreenModel icon="lebo-cll" :title="data.standard_name" v-else-if="data.block_enum == 31">
    <template slot="rightClick">
      <div class="rightSelect">
        <el-select class="select" v-model="yearValue" placeholder="请选择" size="medium" @change="changeYear"
          :append-to-body="false">
          <el-option v-for="item in yearOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select class="select" v-model="monthValue" placeholder="请选择" size="medium" @change="changeMonth">
          <el-option v-for="item in monthOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </template>
    <template v-if="dataList && dataList.length > 0">
      <div class="echarts_box" :id="`charAlarmLineBox-${index}`"></div>
    </template>
    <div class="empty_text" v-else>暂无数据</div>
    <!-- <div class="echarts_box" :id="`ecartsBox${data.block_enum}-${index}`"></div> -->
  </bigScreenModel>
  <!-- ** 异常事件告警 -->
  <bigScreenModel icon="lebo-shebei" :title="data.standard_name" v-else-if="data.block_enum == 32">
    <template v-if="dataList && dataList.length > 0">
      <div v-for="(item, index) in dataList" :key="index">
        <issueAlarm :item="item" :backModel="model" />
      </div>
    </template>
    <div class="empty_text" v-else>暂无数据</div>
  </bigScreenModel>
  <!-- ** 异常事件趋势分析 -->
  <bigScreenModel icon="lebo-shebei" :title="data.standard_name" v-else-if="data.block_enum == 33">
    <template slot="rightClick">
      <div class="rightSelect">
        <el-select class="select" v-model="yearValue" placeholder="请选择" size="medium" @change="changeEventTrentYear"
          :append-to-body="false">
          <el-option v-for="item in yearOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select class="select" v-model="monthValue" placeholder="请选择" size="medium" @change="changeEventTrentMonth">
          <el-option v-for="item in monthOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </template>
    <template v-if="dataList && dataList.length > 0">
      <div class="echarts_box" :id="`ecartsBox${data.block_enum}-${index}`"></div>
    </template>
    <div class="empty_text" v-else>暂无数据</div>
  </bigScreenModel>
  <!-- ** 视频监控 -->
  <bigScreenModel icon="lebo-shebei" :title="data.standard_name" v-else-if="data.block_enum == 34">
    <template v-if="dataList && dataList.length > 0">
      <div class="command-center-video" v-if="this.data.picture_size === 4">
        <videoBox :videoMessage="item" :uniqueIndex="index + 'inner-four' + inx" :backModel="model" class="inner-four"
          v-for="(item, inx) in videoPlayList" :key="inx" @fullVideo="dbVideo"></videoBox>
      </div>
      <div class="command-center-video" v-if="this.data.picture_size === 9">
        <videoBox :videoMessage="item" :uniqueIndex="index + 'inner-nine' + inx" :backModel="model" class="inner-nine"
          v-for="(item, inx) in videoPlayList" :key="inx" @fullVideo="dbVideo"></videoBox>
      </div>
      <div class="command-center-video" v-if="this.data.picture_size === 1">
        <videoBox :videoMessage="item" :uniqueIndex="index + 'inner-one' + inx" :backModel="model"
          v-for="(item, inx) in videoPlayList" :key="inx" class="inner-one" @fullVideo="dbVideo"></videoBox>
      </div>
      <!-- 全屏视频弹框 -->
      <div v-if="centerDialogVisible" class="fullVideoBox">
        <video id="fullScreenVideo" controls></video>
      </div>
    </template>
    <div class="empty_text" v-else>暂无数据</div>
  </bigScreenModel>
  <!-- 场景 -->
  <div class="command-center-total" v-else-if="data.block_enum == 35 && dataList">
    <span class="count">{{ dataList.sceneCount }}</span>
    <span class="title-box">
      <span class="title">{{ data.standard_name }}</span>
    </span>
  </div>
  <!-- 告警次数 -->
  <div class="command-center-total" v-else-if="data.block_enum == 36 && dataList">
    <span class="count">{{ dataList.recordCount }}</span>
    <span class="title-box">
      <span class="title">{{ data.standard_name }}</span>
    </span>
  </div>
  <!-- 设备次数
  <div class="command-center-total" v-else-if="data.block_enum == 37 && dataList">
    <span class="count">{{ dataList.deviceCount }}</span>
    <span class="title-box">
      <span class="title">{{ data.standard_name }}</span>
    </span>
  </div> -->

  <!-- <el-dialog close-on-click-modal append-to-body :visible.sync="centerDialogVisible" fullscreen destroy-on-close>
    <video id="fullScreenVideo" controls></video>
  </el-dialog> -->
</template>
<script>
import axios from '@/api/http'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import deviceItem from '../../cityParkCommandCenter/components/deviceItem.vue'
import issueAlarm from '../../cityParkCommandCenter/components/issueAlarm.vue'
import videoBox from '../../cityParkCommandCenter/components/videoBox.vue'
import * as echarts from 'echarts'
import 'echarts-gl'
import chinaMap from './china.json'
import changshaMap from './changsha.json'
// import axiosAll from "axios";
import MapRightItem from '../../cityParkCommandCenter/components/mapRightItem.vue'
import { getalarmRanking, getabnormalEventTrent } from '@/api/mclzapi'
import moment from 'moment'
// import bottonImg from '@/assets/img/mclz/buttonbackground.png'
// import buttonbackgroundLight from '@/assets/img/mclz/buttonbackgroundLight.png'
import alarmBack from '@/assets/img/mclz/alarmBack.png'
import alarmBackLight from '@/assets/img/mclz/alarmBackLight.png'
import { getMonitorUrlList } from '@/api/monitoring'
import flv from 'flv.js'
export default {
  components: { deviceItem, MapRightItem, issueAlarm, videoBox },
  props: {
    data: { type: Object },
    index: { type: Number },
    model: { type: Number },
    outBoxWidth: { type: Number }
  },
  data () {
    return {
      dataList: null,
      order_status: 1,
      orderRightList: [
        { title: '实时', status: 1 },
        { title: '排名', status: 2 }
      ],
      shouru_status: 3,
      carFlow_status: 3,
      people_status: 3,
      shouruList: [
        { title: '年', status: 1 },
        { title: '月', status: 2 },
        { title: '日', status: 3 }
      ],
      parktype: 1,
      recordRightList: [
        { title: '路内', status: 1 },
        { title: '路外', status: 2 }
      ],
      isShowInParkingMark: true,
      isShowOutParkingMark: true,
      isShowInspectorMarker: true,
      isShowScenceMarker: true,
      isShowGroupMarker: true,
      requestTimer: null, // 定时器（3秒请求一次数据）
      noPeopleInDark: require('@/assets/img/noMessage/noPeopleIn.png'),
      noPeopleInLight: require('@/assets/img/noMessage/noPeopleInLight.png'),
      noCarsInDark: require('@/assets/img/noMessage/noCarsIn.png'),
      noCarsInLight: require('@/assets/img/noMessage/noCarsInLight.png'),
      yearOptions: [],
      monthOptions: [{
        value: '01',
        label: '1月'
      }, {
        value: '02',
        label: '2月'
      }, {
        value: '03',
        label: '3月'
      }, {
        value: '04',
        label: '4月'
      }, {
        value: '05',
        label: '5月'
      }, {
        value: '06',
        label: '6月'
      }, {
        value: '07',
        label: '7月'
      }, {
        value: '08',
        label: '8月'
      }, {
        value: '09',
        label: '9月'
      }, {
        value: '10',
        label: '10月'
      }, {
        value: '11',
        label: '11月'
      }, {
        value: '12',
        label: '12月'
      }
      ],
      yearXlist: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      monthXbiglist: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
      monthXsmalllist: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
      monthXferblist: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'],
      monthXferblist4: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29'],
      yearValue: '', // 选择的年份
      monthValue: '', // 选择的月份
      dateParams: {
        begintime: '',
        endtime: ''
      },
      monthEventTrentParams: '',
      yearEventTrentParams: '',
      dateEventTrentParams: { // 查询时间
        begintime: '',
        type: ''
      },
      videoPlayList: [], // 视屏播放数组
      mainIdList: [], // 获取视频流mainid数组
      videoDetialList: [], // 视频详细
      videoChangeTimer: null, // 定时切换定时器
      totalVideoList: [], // 视频地址
      zoomShow: false, // 控制滚动条显示隐藏
      centerDialogVisible: false // 展示视频弹框
    }
  },
  created () {
    // console.log('报表', this.data)
    // 切换前去除背景地图标准件
    // const filterList = this.data.filter(item => {
    //   return item.block_enum !== 28
    // })
    // this.currentItem.children = filterList
  },
  mounted () {
    // 定义年份选择
    this.yearOptions = []
    const nowYear = moment().year()
    this.yearOptions.push({ label: nowYear - 1, value: nowYear - 1 },
      { label: nowYear, value: nowYear },
      { label: nowYear + 1, value: nowYear + 1 })
    this.requestData()
    // 需要循环更新的标准间
    const flag = this.data.block_enum === 6 || this.data.block_enum === 7 || this.data.block_enum === 9 || this.data.block_enum === 12 || this.data.block_enum === 14 || this.data.block_enum === 19 || this.data.block_enum === 20 || this.data.block_enum === 21 || this.data.block_enum === 22 || this.data.block_enum === 23 || this.data.block_enum === 24 || this.data.block_enum === 25 || this.data.block_enum === 26 || this.data.block_enum === 27 || this.data.block_enum === 32
    if (flag) {
      this.requestTimer = setInterval(() => {
        this.requestData()
      }, 1000 * 3)
    }
  },
  destroyed () {
    // console.log('leave')
    // console.log('this.videoChangeTimer', this.videoChangeTimer)
    clearInterval(this.videoChangeTimer)
    clearInterval(this.requestTimer)
    this.videoChangeTimer = null
  },
  beforeRouteLeave (to, from, next) {
    if (this.videoChangeTimer) {
      clearInterval(this.videoChangeTimer)
      this.videoChangeTimer = null
    }
    next()
  },
  updated () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName']),
    echartsColor () {
      if (this.model === 1) {
        return '#fff'
      } else {
        return '#666'
      }
    },
    darkLisgt () {
      if (this.model === 1) {
        return 'noCarsInDark'
      } else {
        return 'noCarsInLight'
      }
    },
    picCatch () {
      if (this.model === 1) {
        return 'noPeopleInDark'
      } else {
        return 'noPeopleInLight'
      }
    }

  },
  watch: {
    data (val, old) {
      // console.log("watch---item--", val);
      if (val) {
        this.requestData()
        // var self = this;
        // const viewElem = document.body;
        // const resizeObserver = new ResizeObserver(() => {
        //   // 加个if约束，当Echarts1和Echarts2存在时再执行resize()，否则图表不存在时运行到这会报错。
        //   if (self.$refs[`echartRef${self.data.select_graph_type}-${self.index}`]) {
        //     self.$refs[`echartRef${self.data.select_graph_type}-${self.index}`].resize();
        //   }
        // });
        // resizeObserver.observe(viewElem);
      }
    },
    yearValue: {
      immediate: true,
      handler (newVal, oldVal) {
        this.dateParams.begintime = newVal + '-' + this.monthValue + '-' + '01' + ' ' + '00:00:00'
      }
    },
    monthValue: {
      immediate: true,
      handler (newVal, oldVal) {
        if (this.yearValue) {
          this.dateParams.begintime = this.yearValue + '-' + newVal + '-' + '01' + ' ' + '00:00:00'
        }
      }
    },
    dateParams: { // 时间变化时发起请求
      deep: true,
      handler (newVal) {
        // console.log('newVal', newVal)
        if (this.yearValue !== 0) {
          if (this.monthValue === '') {
            newVal = {
              begintime: this.yearValue + '-01-31 00:00:00',
              endtime: this.yearValue + '-12-31 23:59:59'
            }
            this.fngetalarmRanking(newVal)
          } else if (this.yearValue % 4 === 0 && this.monthValue === '02') {
            newVal.endtime = this.yearValue + '-' + this.monthValue + '-' + '29' + ' ' + '23:59:59'
            this.fngetalarmRanking(this.dateParams)
          } else if (this.yearValue % 4 !== 0 && this.monthValue === '02') {
            newVal.endtime = this.yearValue + '-' + this.monthValue + '-' + '28' + ' ' + '23:59:59'
            this.fngetalarmRanking(this.dateParams)
          } else if (this.monthValue === '01' || this.monthValue === '03' || this.monthValue === '05' || this.monthValue === '07' || this.monthValue === '08' || this.monthValue === '10' || this.monthValue === '12') {
            newVal.endtime = this.yearValue + '-' + this.monthValue + '-' + '31' + ' ' + '23:59:59'
            this.fngetalarmRanking(this.dateParams)
          } else if (this.monthValue === '04' || this.monthValue === '06' || this.monthValue === '09' || this.monthValue === '11') {
            newVal.endtime = this.yearValue + '-' + this.monthValue + '-' + '30' + ' ' + '23:59:59'
            this.fngetalarmRanking(this.dateParams)
          }
        }
      }
    },
    yearEventTrentParams: {
      handler (newVal, oldVal) {
        this.dateEventTrentParams.begintime = newVal + '-' + this.monthEventTrentParams + '-' + '01' + ' ' + '00:00:00'
        // console.log(this.dateParams.begintime)
      }
    },
    monthEventTrentParams: {
      handler (newVal, oldVal) {
        this.dateEventTrentParams.begintime = this.yearEventTrentParams + '-' + newVal + '-' + '01' + ' ' + '00:00:00'
        // console.log(this.dateParams.begintime)
      }
    },
    dateEventTrentParams: { // 时间变化时发起请求
      deep: true,
      handler (newVal) {
        if (this.monthEventTrentParams === '' && this.yearEventTrentParams !== '') {
          newVal = {
            begintime: this.yearEventTrentParams + '-01-01 00:00:00',
            type: '1'
          }
          this.fngetabnormalEventTrent(newVal)
        } else if (this.monthEventTrentParams !== '' && this.yearEventTrentParams !== '') {
          newVal = {
            begintime: this.yearEventTrentParams + '-' + this.monthEventTrentParams + '-' + '01' + ' ' + '00:00:00',
            type: '2'
          }
          this.fngetabnormalEventTrent(newVal)
        }
        // console.log('params', newVal)
      }
    }
  },
  methods: {
    // 筛选异常告警数据
    findAlarmTotal (arr, type) {
      // console.log('arr', arr, type)
      const alarmTotal = arr.filter(item => {
        if (item.alarm_type === type) { return item }
      })
      // console.log('alarmTotal', alarmTotal)
      return alarmTotal[0] && alarmTotal[0].total
    },
    showMark () {
      this.isShowInParkingMark = true
      this.isShowOutParkingMark = true
      this.isShowInspectorMarker = true
      this.isShowScenceMarker = true
      this.isShowGroupMarker = true
    },
    changeEventTrentYear (val) {
      this.yearEventTrentParams = val
      this.monthEventTrentParams = ''
    },
    changeEventTrentMonth (val) {
      this.monthEventTrentParams = val
    },
    // 选择框时间变化时传递给父级时间数据
    changeYear (val) {
      this.yearValue = val
      this.monthValue = ''
      // console.log('年份', val)
    },
    changeMonth (val) {
      this.monthValue = val
      // console.log('月份', val)
    },
    async requestData () {
      if (!this.data.interface_address) return
      var params = {}
      var data = {}
      let res = ''
      // 设置请求内容
      if (this.data.block_enum === 10) {
        params.dateType = this.typeDate(this.shouru_status)
        res = await axios.get(this.data.interface_address + '?block_enum=' + this.data.block_enum, { params: params })
      } else if (this.data.block_enum === 11) {
        params.dateType = this.typeDate(this.carFlow_status)
        res = await axios.get(this.data.interface_address + '?block_enum=' + this.data.block_enum, { params: params })
      } else if (this.data.block_enum === 14) {
        params.parktype = this.parktype
        params.fixation_size = this.data.fixation_size
        res = await axios.get(this.data.interface_address + '?block_enum=' + this.data.block_enum, { params: params })
      } else if (this.data.block_enum === 17) {
        params.dateType = this.typeDate(this.people_status)
        res = await axios.get(this.data.interface_address + '?block_enum=' + this.data.block_enum, { params: params })
      } else if (this.data.block_enum === 19) {
        // 1.展示6张图片 2.展示10张图片
        params.type = 1
        params.fixation_size = this.data.fixation_size
        res = await axios.get(this.data.interface_address + '?block_enum=' + this.data.block_enum, { params: params })
      } else if (this.data.block_enum === 5) {
        data.type = 1
        const choosenList = []
        this.data.select_data.forEach(item => {
          if (item === ('02be1f7d9b6144a48f0eb489')) {
            choosenList.push('1')
          } else if (item === ('c9cb240bb801490fb58bc78b')) {
            choosenList.push('2')
          } else if (item === ('b65e19c748ec46f099c053af')) {
            choosenList.push('3')
          }
        })

        if (choosenList.length > 0) {
          data.select_data = choosenList
        } else {
          data.select_data = this.data.select_data
        }
        res = await axios.post(this.data.interface_address + '?block_enum=' + this.data.block_enum, data)
      } else if (this.data.block_enum === 4) {
        data.type = 2
        const choosenList = []
        this.data.select_data.forEach(item => {
          if (item === ('02be1f7d9b6144a48f0eb489')) {
            choosenList.push('1')
          } else if (item === ('c9cb240bb801490fb58bc78b')) {
            choosenList.push('2')
          } else if (item === ('b65e19c748ec46f099c053af')) {
            choosenList.push('3')
          }
        })

        if (choosenList.length > 0) {
          data.select_data = choosenList
        } else {
          data.select_data = this.data.select_data
        }
        res = await axios.post(this.data.interface_address + '?block_enum=' + this.data.block_enum, data)
      } else if (this.data.block_enum === 31) {
        params.begintime = moment().format('YYYY') + '-01-31 00:00:00'
        params.endtime = moment().format('YYYY') + '-12-31 23:59:59'
        res = await axios.get(this.data.interface_address + '?block_enum=' + this.data.block_enum, { params: params })
      } else if (this.data.block_enum === 33) {
        params.type = 1
        params.begintime = moment().format('YYYY') + '-01-31 00:00:00'
        res = await axios.get(this.data.interface_address + '?block_enum=' + this.data.block_enum, { params: params })
      } else if (this.data.block_enum === 12) {
        const choosenList = []
        this.data.select_data.forEach(item => {
          if (item === ('9a233028cc4548a89fd2f945')) {
            choosenList.push('3')
          } else if (item === ('f884246c5ee84cf2bf232a03')) {
            choosenList.push('1')
          } else if (item === ('120b2032410c4ab0ac6ab134')) {
            choosenList.push('4')
          } else if (item === ('1d8069629ccd4eca9220cec2')) {
            choosenList.push('5')
          } else if (item === ('e589b2f9778449da969827d2')) {
            choosenList.push('2')
          } else if (item === ('6da09262862e409aafbe19d2')) {
            choosenList.push('7')
          } else if (item === ('4241ac584cc046a988068e48')) {
            choosenList.push('9')
          } else if (item === ('e726f13bbd7e434b86f22e75')) {
            choosenList.push('10')
          } else if (item === ('14c00660898442e78a085a6d')) {
            choosenList.push('14')
          }
        })

        if (choosenList.length > 0) {
          data.select_data = choosenList
        } else {
          data.select_data = this.data.select_data
        }
        res = await axios.post(this.data.interface_address + '?block_enum=' + this.data.block_enum, data)
      } else if (this.data.block_enum === 32) {
        params.fixation_size = this.data.fixation_size
        res = await axios.get(this.data.interface_address, { params: params })
      } else if (this.data.block_enum === 34) {
        res = await axios.get(this.data.interface_address + '?block_enum=' + this.data.block_enum, { params: params })
      } else if (this.data.block_enum === 30) {
        data.select_data = []
        res = await axios.post(this.data.interface_address + '?block_enum=' + this.data.block_enum, data)
      } else if (this.data.block_enum === 29) {
        const choosenList = []
        this.data.select_data.forEach(item => {
          if (item === ('1a46c6bf8fb84778b9cb9ab9')) {
            choosenList.push('4')
          } else if (item === ('90326d7472ad4dc9adcbddb5')) {
            choosenList.push('2')
          } else if (item === ('89a3cc4b4a2647178603fdab')) {
            choosenList.push('8')
          } else if (item === ('847c4f65417141e1a7839b54')) {
            choosenList.push('1')
          } else if (item === ('75a9ffca112c4f148bcfc0ce')) {
            choosenList.push('16')
          } else if (item === ('0a67b1c4fb2d4980872de189')) {
            choosenList.push('1024')
          } else if (item === ('6f5a4caecf3f402ba9680943')) {
            choosenList.push('65536')
          }
        })
        if (choosenList.length > 0) {
          data.select_data = choosenList
        } else {
          data.select_data = this.data.select_data
        }
        res = await axios.post(this.data.interface_address + '?block_enum=' + this.data.block_enum, data)
      } else if (this.data.block_enum === 6) {
        const choosenList = []
        this.data.select_data.forEach(item => {
          if (item === ('9a233028cc4548a89fd2f910')) {
            choosenList.push('1')
          } else if (item === ('9a233028cc4548a89fd2f911')) {
            choosenList.push('2')
          } else if (item === ('9a233028cc4548a89fd2f912')) {
            choosenList.push('3')
          } else if (item === ('9a233028cc4548a89fd2f913')) {
            choosenList.push('4')
          } else if (item === ('9a233028cc4548a89fd2f914')) {
            choosenList.push('5')
          } else if (item === ('9a233028cc4548a89fd2f915')) {
            choosenList.push('6')
          }
        })
        if (choosenList.length > 0) {
          data.select_data = choosenList
        } else {
          data.select_data = this.data.select_data
        }
        res = await axios.post(this.data.interface_address + '?block_enum=' + this.data.block_enum, data)
      } else {
        // console.log('其余get标注件', this.data.block_enum)
        res = await axios.get(this.data.interface_address + '?block_enum=' + this.data.block_enum, { params: params })
      }
      // switch (this.data.block_enum) {
      //   case 4:
      //     data.type = 2
      //     var choosenList = []
      //     this.data.select_data.forEach(item => {
      //       if (item === ('02be1f7d9b6144a48f0eb489')) {
      //         choosenList.push('1')
      //       } else if (item === ('c9cb240bb801490fb58bc78b')) {
      //         choosenList.push('2')
      //       } else if (item === ('b65e19c748ec46f099c053af')) {
      //         choosenList.push('3')
      //       }
      //     })

      //     if (choosenList.length > 0) {
      //       data.select_data = choosenList
      //     } else {
      //       data.select_data = this.data.select_data
      //     }
      //     res = await axios.post(this.data.interface_address + '?block_enum=' + this.data.block_enum, data)
      //     break

      //   default:
      //     console.log('其余get标注件', this.data.block_enum)
      //     res = await axios.get(this.data.interface_address + '?block_enum=' + this.data.block_enum, { params: params })
      //     break
      // }
      // console.log('block_enum-----', this.data.block_enum, '----', this.getWayName('BlockEnum', this.data.block_enum));
      // console.log('requestData----', res.Data)
      if (res && res.Code === 200 && res.Data) {
        this.dataList = res.Data
        // console.log('this.dataList', this.dataList)
        this.$nextTick(() => {
          if (this.data.block_enum === 8) {
            // 支付方式
            this.requestInit(res.Data.paymentMethod)
          } else if (this.data.block_enum === 10) {
            // 收入分析
            this.incomneData(res.Data)
          } else if (this.data.block_enum === 11) {
            // 车流量分析
            this.carFlowData(res.Data)
          } else if (this.data.block_enum === 13) {
            // 停车时长
            this.parkTimeData(res.Data)
          } else if (this.data.block_enum === 15) {
            // 设备统计
            this.deviceCountData(res.Data)
          } else if (this.data.block_enum === 16) {
            // 设备状态
            this.deviceStatusData(res.Data)
          } else if (this.data.block_enum === 17) {
            // 人流量分析
            this.peopleFlowData(res.Data)
          } else if (this.data.block_enum === 18) {
            // 人员统计
            this.peopleData(res.Data)
          } else if (this.data.block_enum === 5) {
            // 中国地图
            this.getChinaEChart(res.Data)
          } else if (this.data.block_enum === 4) {
            // 长沙地图
            this.getCSEChart(res.Data)
          } else if (this.data.block_enum === 31) {
            // 告警排名
            this.getAlarmRankingChart(res.Data)
          } else if (this.data.block_enum === 30) {
            // 告警类型
            this.getAlarmTypeChart(res.Data)
          } else if (this.data.block_enum === 33) {
            // 异常事件趋势分析
            this.getTrendAnalysis(res.Data)
          } else if (this.data.block_enum === 34) {
            // 视频监控
            this.getChangeVideo(res.Data)
          }
        })
      }
    },
    typeDate (t) {
      switch (t) {
        case 1:
          return '年'
        case 2:
          return '月'
        case 3:
          return '日'
        default:
          return '日'
      }
    },
    fnOrderRightClick (index) {
      // console.log('index', index)
      this.order_status = index
      this.requestData()
    },
    fnShouRuRightClick (index) {
      this.shouru_status = index
      // console.log('shouru_status', this.shouru_status)
      this.requestData()
    },
    fnCarRightClick (index) {
      this.carFlow_status = index
      this.requestData()
    },
    fnPeopleClick (index) {
      // console.log('fnPeopleClick--', index);
      this.people_status = index
      this.requestData()
    },
    fnRecordRightClick (index) {
      this.parktype = index
      this.requestData()
    },
    toggleInParking () {
      this.isShowInParkingMark = !this.isShowInParkingMark
      this.$emit('toggleInParking')
    },
    toggleOutParking () {
      this.isShowOutParkingMark = !this.isShowOutParkingMark
      this.$emit('toggleOutParking')
    },
    toggleInspectorMarker () {
      this.isShowInspectorMarker = !this.isShowInspectorMarker
      this.$emit('toggleInspectorMarker')
    },
    toggleScenceMarker () {
      this.isShowScenceMarker = !this.isShowScenceMarker
      this.$emit('toggleScenceMarker')
    },
    toggleGroupMarker () {
      this.isShowGroupMarker = !this.isShowGroupMarker
      this.$emit('toggleGroupMarker')
    },
    // 初始化请求回来的数据(支付方式)
    requestInit (newV) {
      // console.log('requestInit-----------', newV)
      const newArray = [] // 数据
      var that = this
      if (!newV || newV.length === 0) {
        // 微信 = 1,支付宝 = 2,现金 = 3,ETC = 4
        newArray.push(
          { value: 0, name: '微信' },
          { value: 0, name: '支付宝' },
          { value: 0, name: '现金' },
          { value: 0, name: 'ETC' }
        )
      } else {
        newV.forEach((item) => {
          // console.log(item);
          newArray.push({
            value: item.count,
            name: that.getWayName('PaymentMethod', item.payment_method)
          })
        })
      }
      // newV.forEach((item) => {
      //   // console.log(item);
      //   newArray.push({
      //     value: item.count,
      //     name: that.getWayName("PaymentMethod", item.payment_method),
      //   });
      // });
      // 0: { name: "地图", value: 1 }
      // 1: { name: "折线图", value: 2 }
      // 2: { name: "饼图", value: 3 }
      // 3: { name: "柱状图", value: 4 }
      // 4: { name: "指标", value: 5 }
      // 5: { name: "数据", value: 6 }
      // 6: { name: "列表", value: 7 }
      if (this.data.select_graph_type === 2) {
        const formatter = function (params) {
          return `<div style="text-align: left;">${params[0].name}：<span style="font-weight: 700;color:${params[0].color};">￥${params[0].value}</span></div>`
        }
        this.$nextTick(() => {
          const data = this.objArrtransArr(newArray, 'name', 'value')
          if (this.data.graph_type_style === 1) {
            that.getLineChart(data.nameArr, data.valArr, formatter, null)
          } else if (this.data.graph_type_style === 2) {
            that.getLineChartTwo(data.nameArr, data.valArr, formatter, null)
          } else {
            that.getLineChartThree(data.nameArr, data.valArr, formatter, null)
          }
        })
      } else if (this.data.select_graph_type === 3) {
        const formatter = function (params) {
          // console.log(params)
          var tip = ''
          if (params.seriesIndex === 0) {
            tip = `<div style="text-align: left;">${params.name}：<span style="font-weight: 700;color:${params.color};">￥${params.value.toFixed(2)}</span></div>
                <div style="text-align: left;">占比：<span style="font-weight: 700;color:${params.color};">${params.percent.toFixed(2)}%</span></div>`
          } else {
            tip = ''
          }
          return tip
        }
        this.$nextTick(() => {
          if (this.data.graph_type_style === 1) {
            //  饼图样式一
            this.getPieChart(newArray, formatter, null)
          } else if (this.data.graph_type_style === 2) {
            //  饼图样式二
            this.getPieChartTwo(newArray, formatter, null)
          } else {
            //  饼图样式三
            this.getPieChartThree(newArray, formatter, null)
          }
        })
      } else if (this.data.select_graph_type === 4) {
        const formatter = function (params) {
          return `<div style="text-align: left;">${params[0].name}：<span style="font-weight: 700;color:${params[0].color};">￥${params[0].value}</span></div>`
        }
        this.$nextTick(() => {
          const data = this.objArrtransArr(newArray, 'name', 'value')
          if (this.data.graph_type_style === 1) {
            //  柱状图样式一
            that.getBarChart(data.nameArr, data.valArr, formatter, null)
          } else if (this.data.graph_type_style === 2) {
            //  柱状图样式二
            that.getBarChartTwo(data.nameArr, data.valArr, formatter, null)
          } else {
            //  柱状图样式三
            that.getBarChartThree(data.nameArr, data.valArr, formatter, null)
          }
        })
      }
    },
    // 告警类型
    getAlarmTypeChart (data) {
      // console.log('告警类型', data)
      // 处理枚举
      var serdata = data.map(item => {
        return {
          name: this.getWayName('AiboxAlarmType', parseInt(item.alarm_type)),
          // name: item.alarm_type,
          value: item.total
        }
      })
      var formatter = '{b}: {c}次'
      this.initChart(serdata, formatter, '单位：次')
      // 同支付方式
    },
    // 车流量
    carFlowData (list) {
      var seiesData = []
      list.forEach((item) => {
        seiesData.push({
          name: item.str + this.typeDate(this.carFlow_status),
          value: item.carflow_number
        })
      })
      var formatter
      var year = new Date().getFullYear()
      var m = new Date().getMonth() + 1
      if (this.typeDate(this.carFlow_status) === '年') {
        formatter = '{b}：{c} 辆'
      } else if (this.typeDate(this.carFlow_status) === '月') {
        formatter = year + '年{b}：{c} 辆'
      } else if (this.typeDate(this.carFlow_status) === '日') {
        formatter = year + '年' + m + '月{b}：{c} 辆'
      }
      this.initChart(seiesData, formatter, '单位：辆')
    },
    // 收入
    incomneData (list) {
      var seiesData = []
      list.forEach((item) => {
        if (this.shouru_status === 1) {
          // 年
          seiesData.push({
            value: item.income,
            name: item.str + '年'
          })
        } else if (this.shouru_status === 2) {
          // 月
          seiesData.push({
            value: item.income,
            name: item.str + '月'
          })
        } else if (this.shouru_status === 3) {
          // 日
          seiesData.push({
            value: item.income,
            name: item.str + '日'
          })
        }
      })
      let formatter
      var year = new Date().getFullYear()
      var m = new Date().getMonth() + 1
      // if (this.dateType === 1) {
      //   formatter = '{b}: {c}元'
      // } else if (this.dateType === 2) {
      //   formatter = year + '年{b}: {c}元'
      // } else if (this.dateType === 3) {
      //   formatter = year + '年' + m + '月{b}: {c}元'
      // }
      if (this.shouru_status === 3) {
        formatter = '{b}: {c}元'
      } else if (this.shouru_status === 2) {
        formatter = year + '年{b}: {c}元'
      } else if (this.shouru_status === 1) {
        formatter = '{b}: {c}元'
      }
      // console.log('seiesData, formatter', seiesData, formatter, this.dateType)
      this.initChart(seiesData, formatter, '单位：元')
    },
    // 停车时长
    parkTimeData (data) {
      var dataList = []
      for (var k in data) {
        dataList.push({
          name: this.getyAxisStr(k),
          value: data[k]
        })
      }
      var formatter = '{b}：{c} 辆'
      if (this.data.select_graph_type === 2) {
        this.$nextTick(() => {
          const data = this.objArrtransArr(dataList, 'name', 'value')
          // this.getLineChartHorizontal(
          //   data.nameArr,
          //   data.valArr,
          //   formatter,
          //   null
          // )
          if (this.data.graph_type_style === 1) {
            this.getLineChart(
              data.nameArr,
              data.valArr,
              formatter,
              null
            )
          } else if (this.data.graph_type_style === 2) {
            this.getLineChartTwo(
              data.nameArr,
              data.valArr,
              formatter,
              null
            )
          } else {
            this.getLineChartThree(
              data.nameArr,
              data.valArr,
              formatter,
              null
            )
          }
        })
      } else if (this.data.select_graph_type === 3) {
        this.$nextTick(() => {
          if (this.data.graph_type_style === 1) {
            //  饼图样式一
            this.getPieChart(dataList, formatter, null)
          } else if (this.data.graph_type_style === 2) {
            //  饼图样式二
            this.getPieChartTwo(dataList, formatter, null)
          } else {
            //  饼图样式三
            this.getPieChartThree(dataList, formatter, null)
          }
        })
      } else if (this.data.select_graph_type === 4) {
        this.$nextTick(() => {
          const data = this.objArrtransArr(dataList, 'name', 'value')
          // this.getBarChartHorizontal(
          //   data.valArr,
          //   data.nameArr,
          //   formatter,
          //   null
          // )
          if (this.data.graph_type_style === 1) {
            //  柱状图样式一
            this.getBarChart(
              data.nameArr,
              data.valArr,
              formatter,
              null
            )
          } else if (this.data.graph_type_style === 2) {
            //  柱状图样式二
            this.getBarChartTwo(
              data.nameArr,
              data.valArr,
              formatter,
              null
            )
          } else {
            //  柱状图样式三
            this.getBarChartThree(
              data.nameArr,
              data.valArr,
              formatter,
              null
            )
          }
        })
      }
    },
    // 告警排名
    getAlarmRankingChart (data) {
      const newList = []
      const nameList = []
      const numberList = []
      data.forEach(item => {
        newList.push({ value: item.total, name: item.scene_name })
      })
      newList.forEach(item => {
        nameList.push(item.name)
        numberList.push(item.value)
      })
      this.initAlarmRankingEcharts(nameList, numberList)
    },
    // 获取告警排名
    async fngetalarmRanking (params) {
      const res = await axios.get(this.data.interface_address, { params: params })
      // const res = await getalarmRanking(params)
      const newList = []
      const nameList = []
      const numberList = []
      res.Data.forEach(item => {
        newList.push({ value: item.total, name: item.scene_name })
      })
      newList.forEach(item => {
        nameList.push(item.name)
        numberList.push(item.value)
      })
      if (res.Data) {
        this.initAlarmRankingEcharts(nameList, numberList)
      }
    },
    // 获取事件趋势
    async fngetabnormalEventTrent (params) {
      const nowMonth = this.yearXlist[parseInt(params.begintime.slice(5, 7)) - 1]
      const res = await axios.get(this.data.interface_address, { params: params })
      // const res = await getabnormalEventTrent(params)
      this.getTrendAnalysis(res.Data)
      // if (res.Data) {
      //   this.selectData(res.Data)
      //   this.refreshEcharts(this.seriesList[0].data.length, nowMonth)
      // } else {
      //   this.noResource = true
      // }
    },
    // 异常事件趋势分析
    getTrendAnalysis (data) {
      if (this.data.select_graph_type === 2) {
        const nameList = []
        const seriesList = []
        // 处理数据
        data.forEach((item, index) => {
          nameList.push(this.getWayName('AiboxAlarmType', item.alarm_type))
          const monthTotal = []
          item.group.forEach(element => {
            monthTotal.push(element.total)
          })
          seriesList.push({
            name: nameList[index],
            type: 'line',
            data: monthTotal
          })
        })
        // 当前月份
        const nowMonth = moment().format('M')
        const length = seriesList[0].data.length
        // console.log('nameListseriesList', nameList, seriesList, nowMonth)
        // 异常事件折线图
        if (length === 12) {
          this.initTrendAnalysisEcharts(nameList, seriesList, this.yearXlist, '')
        } else if (length === 28) {
          this.initTrendAnalysisEcharts(nameList, seriesList, this.monthXferblist, nowMonth)
        } else if (length === 29) {
          this.initTrendAnalysisEcharts(nameList, seriesList, this.monthXferblist4, nowMonth)
        } else if (length === 30) {
          this.initTrendAnalysisEcharts(nameList, seriesList, this.monthXsmalllist, nowMonth)
        } else if (length === 31) {
          this.initTrendAnalysisEcharts(nameList, seriesList, this.monthXbiglist, nowMonth)
        }
      } else if (this.data.select_graph_type === 4) {
        // 异常事件柱状图
        // this.$nextTick(() => {
        //   this.initTrendAnalysisBarEcharts(seiesData, xData)
        //   // this.getBarChart(nameList, seriesList)
        // })
        const seriesList = []
        data.forEach((item, index) => {
          const seriesData = []
          item.group.forEach(element => {
            seriesData.push(element.total)
          })
          seriesList.push({
            name: this.getWayName('AiboxAlarmType', item.alarm_type),
            type: 'bar',
            stack: 'Ad',
            barWidth: 8,
            emphasis: { focus: 'series' },
            data: seriesData
          })
        })
        // console.log('seriesList', seriesList)
        const length = seriesList[0].data.length
        if (this.data.graph_type_style === 3) {
          if (length === 12) {
            this.initTrendAnalysisBarEcharts3D(seriesList, this.yearXlist)
          } else if (length === 28) {
            this.initTrendAnalysisBarEcharts3D(seriesList, this.monthXferblist)
          } else if (length === 29) {
            this.initTrendAnalysisBarEcharts3D(seriesList, this.monthXferblist4)
          } else if (length === 30) {
            this.initTrendAnalysisBarEcharts3D(seriesList, this.monthXsmalllist)
          } else if (length === 31) {
            this.initTrendAnalysisBarEcharts3D(seriesList, this.monthXbiglist)
          }
        } else if (this.data.graph_type_style === 1) {
          if (length === 12) {
            this.initTrendAnalysisBarEcharts(seriesList, this.yearXlist)
          } else if (length === 28) {
            this.initTrendAnalysisBarEcharts(seriesList, this.monthXferblist)
          } else if (length === 29) {
            this.initTrendAnalysisBarEcharts(seriesList, this.monthXferblist4)
          } else if (length === 30) {
            this.initTrendAnalysisBarEcharts(seriesList, this.monthXsmalllist)
          } else if (length === 31) {
            this.initTrendAnalysisBarEcharts(seriesList, this.monthXbiglist)
          }
        }
      }
    },
    // 数组对象求和 total字段
    getListTotal (arr) {
      let sum = 0
      for (let i = 0; i < arr.length; i++) {
        sum += arr[i].total
      }
      return sum
    },
    // 设备统计
    deviceCountData (data) {
      // console.log('设备统计----', data)
      var formatter = '{b}: {c}个'
      this.initChart(data, formatter, '单位：个')
    },
    // 设备状态
    deviceStatusData (data) {
      var formatter = '{b}: {c}个'
      this.initChart(data, formatter, '单位：个')
    },
    // 人流量分析
    peopleFlowData (data) {
      // console.log('人流量分析----', data);
      var seiesData = []
      data.forEach((item) => {
        seiesData.push({
          name: item.datetime + this.typeDate(this.people_status),
          value: item.totalcount
        })
      })
      var formatter
      var year = new Date().getFullYear()
      var m = new Date().getMonth() + 1
      if (this.people_status === 1) {
        formatter = '{b}：{c} 人'
      } else if (this.people_status === 2) {
        formatter = year + '年{b}：{c} 人'
      } else if (this.people_status === 3) {
        formatter = year + '年' + m + '月{b}：{c} 人'
      }
      this.initChart(seiesData, formatter, '单位：人')
    },
    // 人员统计
    peopleData (data) {
      // console.log('人员统计--------', data)
      var serdata = data.map(item => {
        return {
          name: item.organize_name,
          value: item.personcount
        }
      })
      var formatter = '{b}: {c}人'
      this.initChart(serdata, formatter, '单位：人')
    },
    getyAxisStr (str) {
      switch (str) {
        case 'time_0':
          return '0~0.5小时'
        case 'time_0_5':
          return '0.5~1小时'
        case 'time_1':
          return '1~3小时'
        case 'time_3':
          return '3~6小时'
        case 'time_6':
          return '6~12小时'
        case 'time_12':
          return '12小时以上'
      }
    },
    objArrtransArr (arr, oldname, oldnum) {
      // 名称信息
      var nameArr = []
      // 数值
      var valArr = []
      arr.forEach((item) => {
        nameArr.push(item[oldname])
        valArr.push(item[oldnum])
      })
      return { nameArr, valArr }
    },
    // 根据所选择的图标属性展示不同样式表
    initChart (seiesData, formatter, title) {
      const data = this.objArrtransArr(seiesData, 'name', 'value')
      if (this.data.select_graph_type === 2) { // 折线
        this.$nextTick(() => {
          if (this.data.graph_type_style === 1) {
            // 折线样式一
            this.getLineChart(data.nameArr, data.valArr, formatter, title)
          } else if (this.data.graph_type_style === 2) {
            // 折线样式二
            this.getLineChartTwo(data.nameArr, data.valArr, formatter, title)
          } else {
            // 折线样式三
            this.getLineChartThree(data.nameArr, data.valArr, formatter, title)
          }
        })
      } else if (this.data.select_graph_type === 3) { // 饼图
        this.$nextTick(() => {
          if (this.data.graph_type_style === 1) {
            //  饼图样式一
            this.getPieChart(seiesData, formatter, null)
          } else if (this.data.graph_type_style === 2) {
            //  饼图样式二
            this.getPieChartTwo(seiesData, formatter, null)
          } else {
            //  饼图样式三
            this.getPieChartThree(seiesData, formatter, null)
          }
        })
      } else if (this.data.select_graph_type === 4) { // 柱状图
        this.$nextTick(() => {
          if (this.data.graph_type_style === 1) {
            //  柱状图样式一
            this.getBarChart(data.nameArr, data.valArr, formatter, title)
          } else if (this.data.graph_type_style === 2) {
            //  柱状图样式二
            this.getBarChartTwo(data.nameArr, data.valArr, formatter, title)
          } else {
            //  柱状图样式三
            this.getBarChartThree(data.nameArr, data.valArr, formatter, title)
          }
        })
      }
    },
    // 扇形统计图 样式一
    getPieChart (seiesData, formatter) {
      var dom = document.getElementById(
        `ecartsBox${this.data.block_enum}-${this.index}`
      )
      if (!dom) return
      //  基于准备好的dom，初始化echarts实例
      var myChart1 = echarts.init(dom)
      var colorList = ['#FCB12D', '#EF5F47', '#FF278D', '#BA27FF', '#0060D6', '#01FAFD', '#1EE6A3']
      var legendData = {
        formatter: function (name) {
          if (name.length > 7) {
            name = name.slice(0, 7) + '...'
          }
          return name
        },
        // 强制设置图形为圆。
        icon: 'circle',
        orient: 'vertical',
        top: 'center',
        right: '1%',
        textStyle: {
          color: this.echartsColor,
          fontSize: 11,
          align: 'left',
          verticalAlign: 'middle'
        },
        data: seiesData,
        type: 'scroll',
        pageIconColor: this.echartsColor, // 图例分页左右箭头图标颜色
        pageTextStyle: {
          color: this.echartsColor // 图例分页页码的颜色设置
        },
        pageIconSize: 12, // 当然就是按钮的大小
        pageIconInactiveColor: '#7f7f7f' // 禁用的按钮颜色
      }
      // if (seiesData.length > 3) {
      //   legendData = [{
      //     // 强制设置图形为圆。
      //     icon: 'circle',
      //     orient: "vertical",
      //     top: "center",
      //     right: "15%",
      //     textStyle: {
      //       color: "#ffffff",
      //       fontSize: 14,
      //       align: "left",
      //       verticalAlign: "middle",
      //     },
      //     data: seiesData.slice(Math.floor(seiesData.length / 2), seiesData.length),
      //   },
      //   {
      //     // 强制设置图形为圆。
      //     icon: 'circle',
      //     orient: "vertical",
      //     top: "center",
      //     right: "35%",
      //     textStyle: {
      //       color: "#ffffff",
      //       fontSize: 14,
      //       align: "left",
      //       verticalAlign: "middle",
      //     },
      //     data: seiesData.slice(0, Math.ceil(seiesData.length / 2)),
      //   }];
      // }
      //  绘制图表
      var option1 = {
        color: colorList,
        // title: [
        //   {
        //     // 第一个圆环标题
        //     text: total + '%', // 主标题
        //     textStyle: {
        //       // 主标题样式
        //       color: "#01a6fb",
        //       fontSize: 14,
        //       fontWeight: "700",
        //     },
        //     left: "22%", // 定位到适合的位置
        //     top: "40%", // 定位到适合的位置
        //     textAlign: "center", // 主、副标题水平居中显示
        //   },
        // ],
        legend: legendData,
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#333',
          textStyle: {
            color: '#333'
          },
          borderWidth: 1,
          trigger: 'item',
          formatter: formatter,
          confine: true
        },
        grid: {
          left: '1%',
          // right: '4%',
          containLabel: true
        },
        series: [
          // 环形展示层
          {
            type: 'pie',
            color: colorList,
            left: '1%',
            radius: ['40%', '65%'],
            center: ['32%', '50%'],
            label: {
              show: false
            },
            //
            emptyCircleStyle: {
              color: '#04A8FB'
            },
            data: seiesData
          }
        ]
      }
      myChart1.resize()
      myChart1.clear()
      myChart1.setOption(option1)
    },
    // 扇形统计图 样式二
    getPieChartTwo (seiesData, formatter) {
      var dom = document.getElementById(
        `ecartsBox${this.data.block_enum}-${this.index}`
      )
      if (!dom) return
      //  基于准备好的dom，初始化echarts实例
      var myChart1 = echarts.init(dom)
      var colorList = ['#FCB12D', '#EF5F47', '#FF278D', '#BA27FF', '#0060D6', '#01FAFD', '#1EE6A3']
      var legendData = {
        formatter: function (name) {
          if (name.length > 8) {
            name = name.slice(0, 8) + '...'
          }
          return name
        },
        // 强制设置图形为圆。
        icon: 'circle',
        orient: 'horizontal',
        bottom: '0',
        right: 'center',
        left: 'center',
        // width: 300,
        textStyle: {
          color: this.echartsColor,
          fontSize: 11,
          align: 'left',
          verticalAlign: 'middle'
        },
        itemStyle: {
          borderColor: '#ccc',
          borderWidth: 2
        },
        data: seiesData,
        type: 'scroll',
        pageIconColor: this.echartsColor, // 图例分页左右箭头图标颜色
        pageTextStyle: {
          color: this.echartsColor // 图例分页页码的颜色设置
        },
        pageIconSize: 12, // 当然就是按钮的大小
        pageIconInactiveColor: '#7f7f7f' // 禁用的按钮颜色
      }
      //  绘制图表
      var option1 = {
        color: colorList,
        legend: legendData,
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#333',
          textStyle: {
            color: '#333'
          },
          borderWidth: 1,
          trigger: 'item',
          formatter: formatter,
          confine: true
        },

        series: [
          // 环形展示层
          {
            type: 'pie',
            roseType: true,
            color: colorList,
            radius: ['35%', '65%'],
            center: ['50%', '50%'],
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 12,
                fontWeight: 'bold',
                formatter: '{d}%'
              }
            },
            labelLine: {
              show: false
            },
            //
            emptyCircleStyle: {
              color: '#04A8FB'
            },
            data: seiesData
          },
          {
            type: 'pie',
            center: ['50%', '50%'],
            radius: [0, '30%'],
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            itemStyle: {
              color: 'rgba(75, 126, 203,.1)'
            },
            silent: true,
            data: [
              {
                value: 100,
                name: ''
              }
            ]
          }
        ]
      }
      myChart1.resize()
      myChart1.clear()
      myChart1.setOption(option1)
    },
    // // 扇形统计图 样式三
    getPieChartThree (seiesData, formatter) {
      // console.log('数据seiesData', seiesData, formatter)
      var dom = document.getElementById(
        `ecartsBox${this.data.block_enum}-${this.index}`
      )
      if (!dom) return
      //  基于准备好的dom，初始化echarts实例
      var myChart1 = echarts.init(dom)
      var colorList = ['#FCB12D', '#EF5F47', '#FF278D', '#BA27FF', '#0060D6', '#01FAFD', '#1EE6A3']
      var dataList = []
      seiesData.forEach((item, index) => {
        dataList.push({
          name: item.name,
          value: item.value,
          itemStyle: {
            opacity: 0.5,
            color: colorList[index]
          }
        })
      })
      //  绘制图表
      // 传入数据生成 option
      var option = this.getPie3D(dataList, 3, formatter)
      myChart1.resize()
      myChart1.clear()
      myChart1.setOption(option)
    },
    // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
    getParametricEquation (startRatio, endRatio, isSelected, isHovered, k) {
      // 计算
      const midRatio = (startRatio + endRatio) / 2

      const startRadian = startRatio * Math.PI * 2
      const endRadian = endRatio * Math.PI * 2
      const midRadian = midRatio * Math.PI * 2

      // 如果只有一个扇形，则不实现选中效果。
      if (startRatio === 0 && endRatio === 1) {
        isSelected = false
      }

      // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
      k = typeof k !== 'undefined' ? k : 1 / 3

      // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
      const offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0
      const offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0

      // 计算高亮效果的放大比例（未高亮，则比例为 1）
      const hoverRate = isHovered ? 1.05 : 1

      // 返回曲面参数方程
      return {
        u: {
          min: -Math.PI,
          max: Math.PI * 3,
          step: Math.PI / 32
        },

        v: {
          min: 0,
          max: Math.PI * 2,
          step: Math.PI / 20
        },

        x: function (u, v) {
          if (u < startRadian) {
            return (
              offsetX +
              Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            )
          }
          if (u > endRadian) {
            return (
              offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            )
          }
          return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate
        },

        y: function (u, v) {
          if (u < startRadian) {
            return (
              offsetY +
              Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            )
          }
          if (u > endRadian) {
            return (
              offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            )
          }
          return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate
        },

        z: function (u, v) {
          if (u < -Math.PI * 0.5) {
            return Math.sin(u)
          }
          if (u > Math.PI * 2.5) {
            return Math.sin(u)
          }
          return Math.sin(v) > 0 ? 1 : -1
        }
      }
    },
    // 生成模拟 3D 饼图的配置项
    getPie3D (pieData, internalDiameterRatio, formatter) {
      const series = []
      let sumValue = 0
      let startValue = 0
      let endValue = 0
      const legendData = []
      const k =
        typeof internalDiameterRatio !== 'undefined'
          ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
          : 1 / 3

      // 为每一个饼图数据，生成一个 series-surface 配置
      for (let i = 0; i < pieData.length; i++) {
        sumValue += pieData[i].value

        const seriesItem = {
          name: typeof pieData[i].name === 'undefined'
            ? `series${i}` : pieData[i].name,
          type: 'surface',
          parametric: true,
          wireframe: {
            show: false
          },
          pieData: pieData[i],
          pieStatus: {
            selected: false,
            hovered: false,
            k: k
          }
        }

        if (typeof pieData[i].itemStyle !== 'undefined') {
          const itemStyle = {}

          typeof pieData[i].itemStyle.color !== 'undefined'
            ? (itemStyle.color = pieData[i].itemStyle.color)
            : null
          typeof pieData[i].itemStyle.opacity !== 'undefined'
            ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
            : null
          seriesItem.itemStyle = itemStyle
        }
        series.push(seriesItem)
      }
      // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
      // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
      for (let i = 0; i < series.length; i++) {
        endValue = startValue + series[i].pieData.value

        series[i].pieData.startRatio = startValue / sumValue
        series[i].pieData.endRatio = endValue / sumValue
        series[i].parametricEquation = this.getParametricEquation(
          series[i].pieData.startRatio,
          series[i].pieData.endRatio,
          true,
          false,
          1
        )

        startValue = endValue

        legendData.push(series[i].name)
      }

      // 补充一个透明的圆环，用于支撑高亮功能的近似实现。
      // series.push({
      //   name: 'mouseoutSeries',
      //   type: 'surface',
      //   parametric: true,
      //   wireframe: {
      //     show: false
      //   },
      //   itemStyle: {
      //     opacity: 1,
      //     color: 'rgba(18,236,252,.5)'
      //   },
      //   parametricEquation: {
      //     u: {
      //       min: 0,
      //       max: Math.PI * 2,
      //       step: Math.PI / 20
      //     },
      //     v: {
      //       min: 0,
      //       max: Math.PI,
      //       step: Math.PI / 1.4
      //     },
      //     x: function (u, v) {
      //       return Math.sin(v) * Math.sin(u) + Math.sin(u)
      //     },
      //     y: function (u, v) {
      //       return Math.sin(v) * Math.cos(u) + Math.cos(u)
      //     },
      //     z: function (u, v) {
      //       return Math.cos(v) > 0 ? 0.1 : -0.1
      //     }
      //   }
      // })

      // 准备待返回的配置项，把准备好的 legendData、series 传入。
      const option = {
        // animation: false,
        legend: {
          show: true,
          textStyle: {
            color: this.model === 1 ? '#fff' : '#666'
          },
          type: 'scroll',
          pageIconColor: this.echartsColor, // 图例分页左右箭头图标颜色
          pageTextStyle: {
            color: this.echartsColor // 图例分页页码的颜色设置
          },
          pageIconSize: 12, // 当然就是按钮的大小
          pageIconInactiveColor: '#7f7f7f', // 禁用的按钮颜色
          data: legendData
        },
        tooltip: {
          formatter: params => {
            if (params.seriesName !== 'mouseoutSeries') {
              return `${params.seriesName}<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>${option.series[params.seriesIndex].pieData.value}`
            }
          }
        },
        xAxis3D: {
          min: -1.3,
          max: 1.3
        },
        yAxis3D: {
          min: -1.3,
          max: 1.3
        },
        zAxis3D: {
          min: -1.3,
          max: 1.3
        },
        grid3D: {
          show: false,
          boxHeight: 10,
          // top: '30%',
          left: '0',
          bottom: '50%'
          // viewControl: {
          // 3d效果可以放大、旋转等，请自己去查看官方配置
          // alpha: 20,
          // beta: 40,
          // rotateSensitivity: 0,
          // zoomSensitivity: 0,
          // panSensitivity: 0,
          // autoRotate: true,
          //   autoRotateSpeed: 5,
          //   autoRotateAfterStill: 10
          // },
        },
        series: series
      }
      return option
    },
    // 柱状图样式一
    getBarChart (nameArr, valArr, formatter, title) {
      // console.log('柱状图nameArr, valArr, formatter, title', nameArr, valArr, formatter, title)
      var chartDom = document.getElementById(
        `ecartsBox${this.data.block_enum}-${this.index}`
      )
      if (!chartDom) return
      // 根据宽度设置横坐标最多展示多少个
      // 判断横坐标数据是否需要展示
      // let maxShowX = ''
      // if (this.data.location.wide_fill <= 10) {
      //   maxShowX = 5
      // } else if (this.data.location.wide_fill <= 20) {
      //   maxShowX = 10
      // } else if (this.data.location.wide_fill <= 30) {
      //   maxShowX = 15
      // } else if (this.data.location.wide_fill <= 40) {
      //   maxShowX = 20
      // } else {
      //   maxShowX = 25
      // }
      // if (nameArr.length <= maxShowX) {
      //   this.zoomShow = false
      // } else {
      //   this.zoomShow = true
      // }
      var myChart = echarts.init(chartDom)
      var option = {
        //  标题
        // title: title
        //   ? {
        //     text: title,
        //     textStyle: {
        //       color: this.echartsColor,
        //       fontSize: 14
        //     }
        //   }
        //   : null,
        // dataZoom: [
        //   {
        //     // 设置滚动条的隐藏与显示
        //     show: this.zoomShow,
        //     // 设置滚动条类型
        //     type: 'slider',
        //     // 设置背景颜色
        //     backgroundColor: 'transparent',
        //     // 设置选中范围的填充颜色
        //     fillerColor: this.model === 1 ? 'rgba(27, 102, 177, 1)' : '#666',
        //     // 设置边框颜色
        //     borderColor: this.model === 1 ? '#666' : '#002944',
        //     // 是否显示detail，即拖拽时候显示详细数值信息
        //     showDetail: false,
        //     // 数据窗口范围的起始数值
        //     startValue: 0,
        //     // 数据窗口范围的结束数值（一页显示多少条数据）
        //     endValue: maxShowX,
        //     // empty：当前数据窗口外的数据，被设置为空。
        //     // 即不会影响其他轴的数据范围
        //     filterMode: 'empty',
        //     // 设置滚动条宽度，相对于盒子宽度
        //     width: '70%',
        //     // 设置滚动条高度
        //     height: 8,
        //     // 设置滚动条显示位置
        //     left: 'center',
        //     // 是否锁定选择区域（或叫做数据窗口）的大小
        //     zoomLoxk: true,
        //     // 控制手柄的尺寸
        //     handleSize: 0,
        //     // dataZoom-slider组件离容器下侧的距离
        //     bottom: '0',
        //     handleStyle: {
        //       color: '#3AAAF0',
        //       borderColor: '#007acc'
        //     },
        //     selectedDataBackground: {
        //       lineStyle: {
        //         opacity: 0
        //       }
        //     }
        //   },
        //   {
        //     // 没有下面这块的话，只能拖动滚动条，
        //     // 鼠标滚轮在区域内不能控制外部滚动条
        //     type: 'inside',
        //     // 滚轮是否触发缩放
        //     zoomOnMouseWheel: false,
        //     // 鼠标滚轮触发滚动
        //     moveOnMouseMove: true,
        //     moveOnMouseWheel: true
        //   }
        // ],
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#333',
          textStyle: {
            color: '#333'
          },
          borderWidth: 1,
          trigger: 'axis',
          formatter: formatter,
          confine: true
        },
        grid: {
          left: '3%',
          right: '4%',
          top: title ? '14%' : '5%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: nameArr,
            axisTick: {
              show: false,
              alignWithLabel: true
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: this.echartsColor
              }
            },
            scale: true,
            axisLabel: {
              show: true,
              rotate: '25', // 修改展示方式为斜向展示
              margin: '15',
              textStyle: {
                color: this.echartsColor
              },
              formatter: function (params) {
                var newParamsName = ''// 最终拼接成的字符串
                var paramsNameNumber = params.length// 实际标签的个数
                var provideNumber = 5// 每行能显示的字的个数
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber)// 换行的话，需要显示几行，向上取整
                /**
                 * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                 */
                // 条件等同于rowNumber>1
                if (paramsNameNumber > provideNumber) {
                  /** 循环每一行,p表示行 */
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ''// 表示每一次截取的字符串
                    var start = p * provideNumber// 开始截取的位置
                    var end = start + provideNumber// 结束截取的位置
                    // 此处特殊处理最后一行的索引值
                    if (p === rowNumber - 1) {
                      // 最后一次不换行
                      tempStr = params.substring(start, paramsNameNumber)
                    } else {
                      // 每一次拼接字符串并换行
                      tempStr = params.substring(start, end) + '\n'
                    }
                    newParamsName += tempStr // 最终拼成的字符串
                  }
                } else {
                  // 将旧标签的值赋给新标签
                  newParamsName = params
                }
                // 将最终的字符串返回
                return newParamsName
              }
            }
          }
        ],
        // yAxis: yAxis,
        yAxis: [
          {
            type: 'value',
            scale: true,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: this.echartsColor
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: this.echartsColor
              }
            }
          }
        ],
        series: [
          // 柱体
          {
            data: valArr,
            type: 'bar',
            barWidth: 12, // 柱子宽度
            barBorderRadius: [6, 6, 6, 6],
            label: {
              show: false
              // color: '#A7D6F4',
              // fontSize: 14,
              // distance: 20, // 距离
              // formatter: '{c} ', // 这里是数据展示的时候显示的数据
              // align: "center",
              // position: [290, 0]
            }, // 柱子上方的数值
            itemStyle: {
              barBorderRadius: [10, 10, 10, 10], // 圆角（左上、右上、右下、左下）
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  { offset: 0, color: 'rgba(1, 166, 251, 1)' }, // 100% 处的颜色
                  { offset: 0.3, color: 'rgba(1, 166, 251, 0.75)' },
                  { offset: 0.8, color: 'rgba(1, 166, 251, 0.5)' },
                  { offset: 1, color: 'rgba(1, 166, 251, 0.1)' } // 0% 处的颜色
                ],
                false
              ) // 渐变
            }
          },
          // 柱顶圆片
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [4, 4], // 调整截面形状
            symbolOffset: [0, 0],
            z: 12,
            itemStyle: {
              color: '#5ff3d9'
            },
            data: valArr
          }
        ]
      }
      myChart.resize()
      myChart.clear()
      myChart.setOption(option)
    },
    // 柱状图 样式二
    getBarChartTwo (nameArr, valArr, formatter, title) {
      // console.log('柱状图nameArr, valArr, formatter, title', nameArr, valArr, formatter, title)
      var chartDom = document.getElementById(
        `ecartsBox${this.data.block_enum}-${this.index}`
      )
      if (!chartDom) return
      // 根据宽度设置横坐标最多展示多少个
      // 判断横坐标数据是否需要展示
      // let maxShowX = ''
      // if (this.data.location.wide_fill <= 10) {
      //   maxShowX = 5
      // } else if (this.data.location.wide_fill <= 20) {
      //   maxShowX = 10
      // } else if (this.data.location.wide_fill <= 30) {
      //   maxShowX = 15
      // } else if (this.data.location.wide_fill <= 40) {
      //   maxShowX = 20
      // } else {
      //   maxShowX = 25
      // }
      // if (nameArr.length <= maxShowX) {
      //   this.zoomShow = false
      // } else {
      //   this.zoomShow = true
      // }
      var myChart = echarts.init(chartDom)
      var option = {
        //  标题
        // title: title
        //   ? {
        //     text: title,
        //     textStyle: {
        //       color: this.echartsColor,
        //       fontSize: 14
        //     }
        //   }
        //   : null,
        // dataZoom: [
        //   {
        //     // 设置滚动条的隐藏与显示
        //     show: this.zoomShow,
        //     // 设置滚动条类型
        //     type: 'slider',
        //     // 设置背景颜色
        //     backgroundColor: 'transparent',
        //     // 设置选中范围的填充颜色
        //     fillerColor: this.model === 1 ? 'rgba(27, 102, 177, 1)' : '#666',
        //     // 设置边框颜色
        //     borderColor: this.model === 1 ? '#666' : '#002944',
        //     // 是否显示detail，即拖拽时候显示详细数值信息
        //     showDetail: false,
        //     // 数据窗口范围的起始数值
        //     startValue: 0,
        //     // 数据窗口范围的结束数值（一页显示多少条数据）
        //     endValue: maxShowX,
        //     // empty：当前数据窗口外的数据，被设置为空。
        //     // 即不会影响其他轴的数据范围
        //     filterMode: 'empty',
        //     // 设置滚动条宽度，相对于盒子宽度
        //     width: '70%',
        //     // 设置滚动条高度
        //     height: 8,
        //     // 设置滚动条显示位置
        //     left: 'center',
        //     // 是否锁定选择区域（或叫做数据窗口）的大小
        //     zoomLoxk: true,
        //     // 控制手柄的尺寸
        //     handleSize: 0,
        //     // dataZoom-slider组件离容器下侧的距离
        //     bottom: '0',
        //     handleStyle: {
        //       color: '#3AAAF0',
        //       borderColor: '#007acc'
        //     },
        //     selectedDataBackground: {
        //       lineStyle: {
        //         opacity: 0
        //       }
        //     }
        //   },
        //   {
        //     // 没有下面这块的话，只能拖动滚动条，
        //     // 鼠标滚轮在区域内不能控制外部滚动条
        //     type: 'inside',
        //     // 滚轮是否触发缩放
        //     zoomOnMouseWheel: false,
        //     // 鼠标滚轮触发滚动
        //     moveOnMouseMove: true,
        //     moveOnMouseWheel: true
        //   }
        // ],
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#333',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          textStyle: {
            color: '#333'
          },
          borderWidth: 1,
          trigger: 'axis',
          formatter: formatter,
          confine: true
        },
        grid: {
          left: '3%',
          right: '4%',
          top: title ? '14%' : '5%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: nameArr,
            axisTick: {
              show: false,
              alignWithLabel: true
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: this.echartsColor
              }
            },
            scale: true,
            axisLabel: {
              show: true,
              rotate: '25', // 修改展示方式为斜向展示
              margin: '15',
              textStyle: {
                color: this.echartsColor
              },
              formatter: function (params) {
                var newParamsName = ''// 最终拼接成的字符串
                var paramsNameNumber = params.length// 实际标签的个数
                var provideNumber = 5// 每行能显示的字的个数
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber)// 换行的话，需要显示几行，向上取整
                /**
                 * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                 */
                // 条件等同于rowNumber>1
                if (paramsNameNumber > provideNumber) {
                  /** 循环每一行,p表示行 */
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ''// 表示每一次截取的字符串
                    var start = p * provideNumber// 开始截取的位置
                    var end = start + provideNumber// 结束截取的位置
                    // 此处特殊处理最后一行的索引值
                    if (p === rowNumber - 1) {
                      // 最后一次不换行
                      tempStr = params.substring(start, paramsNameNumber)
                    } else {
                      // 每一次拼接字符串并换行
                      tempStr = params.substring(start, end) + '\n'
                    }
                    newParamsName += tempStr // 最终拼成的字符串
                  }
                } else {
                  // 将旧标签的值赋给新标签
                  newParamsName = params
                }
                // 将最终的字符串返回
                return newParamsName
              }
            }
          }
        ],
        // yAxis: yAxis,
        yAxis: [
          {
            type: 'value',
            scale: true,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: this.echartsColor
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: this.echartsColor
              }
            }
          }
        ],
        series: [
          // 柱体
          {
            data: valArr,
            type: 'bar',
            barWidth: 12, // 柱子宽度
            barBorderRadius: [6, 6, 6, 6],
            // showBackground: true,
            // backgroundStyle: {
            //   color: 'rgba(128,128,128,0.2)'
            // },
            label: {
              show: false
              // color: '#A7D6F4',
              // fontSize: 14,
              // distance: 20, // 距离
              // formatter: '{c} ', // 这里是数据展示的时候显示的数据
              // align: "center",
              // position: [290, 0]
            }, // 柱子上方的数值
            itemStyle: {
              // barBorderRadius: [10, 10, 10, 10], // 圆角（左上、右上、右下、左下）
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  { offset: 0, color: 'rgba(0,231,254,1.000)' }, // 100% 处的颜色
                  { offset: 0.3, color: 'rgba(0,231,254,0.75)' },
                  { offset: 0.8, color: 'rgba(13,81,180,0.6)' },
                  { offset: 1, color: 'rgba(13,81,180,1.000)' } // 0% 处的颜色
                ],
                false
              ) // 渐变
            }
          },
          // 柱顶圆片
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [12, 6], // 调整截面形状
            symbolPosition: 'end',
            symbolOffset: [0, -2],
            z: 12,
            itemStyle: {
              color: this.model === 1 ? '#fff' : '#02E8FF'
            },
            data: valArr
          }
        ]
      }
      myChart.resize()
      myChart.clear()
      myChart.setOption(option)
    },
    // 柱状图 样式三
    getBarChartThree (nameArr, valArr, formatter, title) {
      // console.log('柱状图nameArr, valArr, formatter, title', nameArr, valArr, formatter, title)
      var chartDom = document.getElementById(
        `ecartsBox${this.data.block_enum}-${this.index}`
      )
      if (!chartDom) return
      // 根据宽度设置横坐标最多展示多少个
      // 判断横坐标数据是否需要展示
      // let maxShowX = ''
      // if (this.data.location.wide_fill <= 10) {
      //   maxShowX = 5
      // } else if (this.data.location.wide_fill <= 20) {
      //   maxShowX = 10
      // } else if (this.data.location.wide_fill <= 30) {
      //   maxShowX = 15
      // } else if (this.data.location.wide_fill <= 40) {
      //   maxShowX = 20
      // } else {
      //   maxShowX = 25
      // }
      // if (nameArr.length <= maxShowX) {
      //   this.zoomShow = false
      // } else {
      //   this.zoomShow = true
      // }
      var myChart = echarts.init(chartDom)
      // 柱状图粗细
      const offsetX = 6
      const offsetY = 3
      // 绘制左侧面
      const CubeLeft = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function (ctx, shape) {
          // 会canvas的应该都能看得懂，shape是从custom传入的
          const xAxisPoint = shape.xAxisPoint
          // console.log(shape);
          const c0 = [shape.x, shape.y]
          const c1 = [shape.x - offsetX, shape.y - offsetY]
          const c2 = [xAxisPoint[0] - offsetX, xAxisPoint[1] - offsetY]
          const c3 = [xAxisPoint[0], xAxisPoint[1]]
          ctx.moveTo(c0[0], c0[1]).lineTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).closePath()
        }
      })
      // 绘制右侧面
      const CubeRight = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function (ctx, shape) {
          const xAxisPoint = shape.xAxisPoint
          const c1 = [shape.x, shape.y]
          const c2 = [xAxisPoint[0], xAxisPoint[1]]
          const c3 = [xAxisPoint[0] + offsetX, xAxisPoint[1] - offsetY]
          const c4 = [shape.x + offsetX, shape.y - offsetY]
          ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath()
        }
      })
      // 绘制顶面
      const CubeTop = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function (ctx, shape) {
          const c1 = [shape.x, shape.y]
          const c2 = [shape.x + offsetX, shape.y - offsetY] // 右点
          const c3 = [shape.x, shape.y - offsetX]
          const c4 = [shape.x - offsetX, shape.y - offsetY]
          ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath()
        }
      })
      // 注册三个面图形
      echarts.graphic.registerShape('CubeLeft', CubeLeft)
      echarts.graphic.registerShape('CubeRight', CubeRight)
      echarts.graphic.registerShape('CubeTop', CubeTop)
      var option = {
        //  标题
        // title: title
        //   ? {
        //     text: title,
        //     textStyle: {
        //       color: this.echartsColor,
        //       fontSize: 14
        //     }
        //   }
        //   : null,
        // dataZoom: [
        //   {
        //     // 设置滚动条的隐藏与显示
        //     show: this.zoomShow,
        //     // 设置滚动条类型
        //     type: 'slider',
        //     // 设置背景颜色
        //     backgroundColor: 'transparent',
        //     // 设置选中范围的填充颜色
        //     fillerColor: this.model === 1 ? 'rgba(27, 102, 177, 1)' : '#666',
        //     // 设置边框颜色
        //     borderColor: this.model === 1 ? '#666' : '#002944',
        //     // 是否显示detail，即拖拽时候显示详细数值信息
        //     showDetail: false,
        //     // 数据窗口范围的起始数值
        //     startValue: 0,
        //     // 数据窗口范围的结束数值（一页显示多少条数据）
        //     endValue: maxShowX,
        //     // empty：当前数据窗口外的数据，被设置为空。
        //     // 即不会影响其他轴的数据范围
        //     filterMode: 'empty',
        //     // 设置滚动条宽度，相对于盒子宽度
        //     width: '70%',
        //     // 设置滚动条高度
        //     height: 8,
        //     // 设置滚动条显示位置
        //     left: 'center',
        //     // 是否锁定选择区域（或叫做数据窗口）的大小
        //     zoomLoxk: true,
        //     // 控制手柄的尺寸
        //     handleSize: 0,
        //     // dataZoom-slider组件离容器下侧的距离
        //     bottom: '0',
        //     handleStyle: {
        //       color: '#3AAAF0',
        //       borderColor: '#007acc'
        //     },
        //     selectedDataBackground: {
        //       lineStyle: {
        //         opacity: 0
        //       }
        //     }
        //   },
        //   {
        //     // 没有下面这块的话，只能拖动滚动条，
        //     // 鼠标滚轮在区域内不能控制外部滚动条
        //     type: 'inside',
        //     // 滚轮是否触发缩放
        //     zoomOnMouseWheel: false,
        //     // 鼠标滚轮触发滚动
        //     moveOnMouseMove: true,
        //     moveOnMouseWheel: true
        //   }
        // ],
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#333',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },
          textStyle: {
            color: '#333'
          },
          borderWidth: 1,
          trigger: 'axis',
          formatter: formatter,
          confine: true
        },
        grid: {
          left: '3%',
          right: '4%',
          top: title ? '14%' : '5%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: nameArr,
            axisTick: {
              show: false,
              alignWithLabel: true
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: this.echartsColor
              }
            },
            scale: true,
            axisLabel: {
              show: true,
              rotate: '25', // 修改展示方式为斜向展示
              margin: '15',
              textStyle: {
                color: this.echartsColor
              },
              formatter: function (params) {
                var newParamsName = ''// 最终拼接成的字符串
                var paramsNameNumber = params.length// 实际标签的个数
                var provideNumber = 5// 每行能显示的字的个数
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber)// 换行的话，需要显示几行，向上取整
                /**
                 * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                 */
                // 条件等同于rowNumber>1
                if (paramsNameNumber > provideNumber) {
                  /** 循环每一行,p表示行 */
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ''// 表示每一次截取的字符串
                    var start = p * provideNumber// 开始截取的位置
                    var end = start + provideNumber// 结束截取的位置
                    // 此处特殊处理最后一行的索引值
                    if (p === rowNumber - 1) {
                      // 最后一次不换行
                      tempStr = params.substring(start, paramsNameNumber)
                    } else {
                      // 每一次拼接字符串并换行
                      tempStr = params.substring(start, end) + '\n'
                    }
                    newParamsName += tempStr // 最终拼成的字符串
                  }
                } else {
                  // 将旧标签的值赋给新标签
                  newParamsName = params
                }
                // 将最终的字符串返回
                return newParamsName
              }
            }
          }
        ],
        // yAxis: yAxis,
        yAxis: [
          {
            type: 'value',
            scale: true,
            min: 0,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: this.echartsColor
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: this.echartsColor
              }
            }
          }
        ],
        series: [
          // 柱体
          {
            data: valArr,
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              return {
                type: 'group',
                children: [
                  {
                    type: 'CubeLeft',
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0])
                    },
                    style: {
                      fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: this.model === 1 ? 'rgba(192,192,192,0.2)' : 'RGBA(191, 245, 255, 1)'
                        },
                        {
                          offset: 1,
                          color: this.model === 1 ? 'rgba(192,192,192,0.3)' : 'RGBA(191, 245, 255, 1)'
                        }
                      ])
                    }
                  },
                  {
                    type: 'CubeRight',
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0])
                    },
                    style: {
                      fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: 'rgba(6,229,251,1.000)'
                        },
                        {
                          offset: 1,
                          color: 'rgba(6,229,251,0.2)'
                        }
                      ])
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0])
                    },
                    style: {
                      fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: this.model === 1 ? '#fff' : '#00E4FF'
                        },
                        {
                          offset: 1,
                          color: this.model === 1 ? '#fff' : '#00E4FF'
                        }
                      ])
                    }
                  }
                ]
              }
            }
          }
        ]
      }
      myChart.resize()
      myChart.clear()
      myChart.setOption(option)
    },
    // // 折线图 样式一
    getLineChart (nameArr, valArr, formatter, title) {
      var chartDom = document.getElementById(
        `ecartsBox${this.data.block_enum}-${this.index}`
      )
      // console.log('-carFlowBox--', chartDom);
      if (!chartDom) return
      // 根据宽度设置横坐标最多展示多少个
      // // 判断横坐标数据是否需要展示
      // let maxShowX = ''
      // if (this.data.location.wide_fill <= 10) {
      //   maxShowX = 5
      // } else if (this.data.location.wide_fill <= 20) {
      //   maxShowX = 10
      // } else if (this.data.location.wide_fill <= 30) {
      //   maxShowX = 15
      // } else if (this.data.location.wide_fill <= 40) {
      //   maxShowX = 20
      // } else {
      //   maxShowX = 25
      // }
      // if (nameArr.length <= maxShowX) {
      //   this.zoomShow = false
      // } else {
      //   this.zoomShow = true
      // }
      var myChart = echarts.init(chartDom)
      var option = {
        //  标题
        // title: title
        //   ? {
        //     text: title,
        //     textStyle: {
        //       color: this.echartsColor,
        //       fontSize: 14
        //     }
        //   }
        //   : null,
        // dataZoom: [
        //   {
        //     // 设置滚动条的隐藏与显示
        //     show: this.zoomShow,
        //     // 设置滚动条类型
        //     type: 'slider',
        //     // 设置背景颜色
        //     backgroundColor: 'transparent',
        //     // 设置选中范围的填充颜色
        //     fillerColor: this.model === 1 ? 'rgba(27, 102, 177, 1)' : '#666',
        //     // 设置边框颜色
        //     borderColor: this.model === 1 ? '#666' : '#002944',
        //     // 是否显示detail，即拖拽时候显示详细数值信息
        //     showDetail: false,
        //     // 数据窗口范围的起始数值
        //     startValue: 0,
        //     // 数据窗口范围的结束数值（一页显示多少条数据）
        //     endValue: maxShowX,
        //     // empty：当前数据窗口外的数据，被设置为空。
        //     // 即不会影响其他轴的数据范围
        //     filterMode: 'empty',
        //     // 设置滚动条宽度，相对于盒子宽度
        //     width: '70%',
        //     // 设置滚动条高度
        //     height: 8,
        //     // 设置滚动条显示位置
        //     left: 'center',
        //     // 是否锁定选择区域（或叫做数据窗口）的大小
        //     zoomLoxk: true,
        //     // 控制手柄的尺寸
        //     handleSize: 0,
        //     // dataZoom-slider组件离容器下侧的距离
        //     bottom: '0',
        //     handleStyle: {
        //       color: '#3AAAF0',
        //       borderColor: '#007acc'
        //     },
        //     selectedDataBackground: {
        //       lineStyle: {
        //         opacity: 0
        //       }
        //     }
        //   },
        //   {
        //     // 没有下面这块的话，只能拖动滚动条，
        //     // 鼠标滚轮在区域内不能控制外部滚动条
        //     type: 'inside',
        //     // 滚轮是否触发缩放
        //     zoomOnMouseWheel: false,
        //     // 鼠标滚轮触发滚动
        //     moveOnMouseMove: true,
        //     moveOnMouseWheel: true
        //   }
        // ],
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#333',
          textStyle: {
            color: '#333'
          },
          borderWidth: 1,
          trigger: 'axis',
          formatter: formatter,
          confine: true
        },
        grid: {
          left: '3%',
          right: '4%',
          top: title ? '14%' : '5%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: nameArr,
            axisTick: {
              show: false,
              alignWithLabel: true
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: this.echartsColor
              }
            },
            scale: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: this.echartsColor
              },
              rotate: '25', // 修改展示方式为斜向展示
              margin: '15',
              formatter: function (params) {
                var newParamsName = ''// 最终拼接成的字符串
                var paramsNameNumber = params.length// 实际标签的个数
                var provideNumber = 5// 每行能显示的字的个数
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber)// 换行的话，需要显示几行，向上取整
                /**
                 * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                 */
                // 条件等同于rowNumber>1
                if (paramsNameNumber > provideNumber) {
                  /** 循环每一行,p表示行 */
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ''// 表示每一次截取的字符串
                    var start = p * provideNumber// 开始截取的位置
                    var end = start + provideNumber// 结束截取的位置
                    // 此处特殊处理最后一行的索引值
                    if (p === rowNumber - 1) {
                      // 最后一次不换行
                      tempStr = params.substring(start, paramsNameNumber)
                    } else {
                      // 每一次拼接字符串并换行
                      tempStr = params.substring(start, end) + '\n'
                    }
                    newParamsName += tempStr // 最终拼成的字符串
                  }
                } else {
                  // 将旧标签的值赋给新标签
                  newParamsName = params
                }
                // 将最终的字符串返回
                return newParamsName
              }
            }
          }
        ],
        // yAxis: yAxis,
        yAxis: [
          {
            type: 'value',
            scale: true,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: this.echartsColor
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: this.echartsColor
              }
            }
            // max: parseInt(max * 1.4).toFixed(0) <= 1 ? 1 : parseInt(max * 1.4).toFixed(0)
          }
        ],
        series: [
          {
            type: 'line',
            showAllSymbol: true,
            symbol: 'circle',
            symbolSize: 8,
            smooth: true,
            lineStyle: {
              normal: {
                width: 3,
                color: '#30c0e2' // 线条颜色
              },
              type: 'solid'
              // borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: '#30c0e2',
              borderWidth: 1,
              borderColor: '#fff'
            },
            tooltip: {
              backgroundColor: '#fff',
              borderColor: '#333',
              textStyle: {
                color: '#333'
              },
              borderWidth: 1,
              show: true,
              confine: true
            },
            label: {
              show: false
            },
            areaStyle: {
              // 区域填充样式
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: 'rgba(1, 166, 251, 1)' }, // 100% 处的颜色
                    { offset: 0.3, color: 'rgba(1, 166, 251, 0.5)' },
                    { offset: 0.8, color: 'rgba(1, 166, 251, 0.1)' },
                    { offset: 1, color: 'rgba(1, 166, 251, 0.1)' } // 0% 处的颜色
                  ],
                  false
                ), // 渐变
                shadowColor: 'rgba(1, 166, 251, 0.5)', // 阴影颜色
                shadowBlur: 10 // shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: valArr
          }
        ]
      }
      myChart.resize()
      myChart.clear()
      myChart.setOption(option)
    },
    // // 折线图 样式二
    getLineChartTwo (nameArr, valArr, formatter, title) {
      var chartDom = document.getElementById(
        `ecartsBox${this.data.block_enum}-${this.index}`
      )
      // console.log('-carFlowBox--', chartDom);
      if (!chartDom) return
      // 根据宽度设置横坐标最多展示多少个
      // 判断横坐标数据是否需要展示
      // let maxShowX = ''
      // if (this.data.location.wide_fill <= 10) {
      //   maxShowX = 5
      // } else if (this.data.location.wide_fill <= 20) {
      //   maxShowX = 10
      // } else if (this.data.location.wide_fill <= 30) {
      //   maxShowX = 15
      // } else if (this.data.location.wide_fill <= 40) {
      //   maxShowX = 20
      // } else {
      //   maxShowX = 25
      // }
      // if (nameArr.length <= maxShowX) {
      //   this.zoomShow = false
      // } else {
      //   this.zoomShow = true
      // }
      var myChart = echarts.init(chartDom)
      var option = {
        //  标题
        // title: title
        //   ? {
        //     text: title,
        //     textStyle: {
        //       color: this.echartsColor,
        //       fontSize: 14
        //     }
        //   }
        //   : null,
        // dataZoom: [
        //   {
        //     // 设置滚动条的隐藏与显示
        //     show: this.zoomShow,
        //     // 设置滚动条类型
        //     type: 'slider',
        //     // 设置背景颜色
        //     backgroundColor: 'transparent',
        //     // 设置选中范围的填充颜色
        //     fillerColor: this.model === 1 ? 'rgba(27, 102, 177, 1)' : '#666',
        //     // 设置边框颜色
        //     borderColor: this.model === 1 ? '#666' : '#002944',
        //     // 是否显示detail，即拖拽时候显示详细数值信息
        //     showDetail: false,
        //     // 数据窗口范围的起始数值
        //     startValue: 0,
        //     // 数据窗口范围的结束数值（一页显示多少条数据）
        //     endValue: maxShowX,
        //     // empty：当前数据窗口外的数据，被设置为空。
        //     // 即不会影响其他轴的数据范围
        //     filterMode: 'empty',
        //     // 设置滚动条宽度，相对于盒子宽度
        //     width: '70%',
        //     // 设置滚动条高度
        //     height: 8,
        //     // 设置滚动条显示位置
        //     left: 'center',
        //     // 是否锁定选择区域（或叫做数据窗口）的大小
        //     zoomLoxk: true,
        //     // 控制手柄的尺寸
        //     handleSize: 0,
        //     // dataZoom-slider组件离容器下侧的距离
        //     bottom: '0',
        //     handleStyle: {
        //       color: '#3AAAF0',
        //       borderColor: '#007acc'
        //     },
        //     selectedDataBackground: {
        //       lineStyle: {
        //         opacity: 0
        //       }
        //     }
        //   },
        //   {
        //     // 没有下面这块的话，只能拖动滚动条，
        //     // 鼠标滚轮在区域内不能控制外部滚动条
        //     type: 'inside',
        //     // 滚轮是否触发缩放
        //     zoomOnMouseWheel: false,
        //     // 鼠标滚轮触发滚动
        //     moveOnMouseMove: true,
        //     moveOnMouseWheel: true
        //   }
        // ],
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#333',
          textStyle: {
            color: '#333'
          },
          borderWidth: 1,
          trigger: 'axis',
          formatter: formatter,
          confine: true
        },
        grid: {
          left: '3%',
          right: '4%',
          top: title ? '14%' : '5%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: nameArr,
            axisTick: {
              show: false,
              alignWithLabel: true
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: this.echartsColor
              }
            },
            scale: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: this.echartsColor
              },
              rotate: '25', // 修改展示方式为斜向展示
              margin: '15',
              formatter: function (params) {
                var newParamsName = ''// 最终拼接成的字符串
                var paramsNameNumber = params.length// 实际标签的个数
                var provideNumber = 5// 每行能显示的字的个数
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber)// 换行的话，需要显示几行，向上取整
                /**
                 * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                 */
                // 条件等同于rowNumber>1
                if (paramsNameNumber > provideNumber) {
                  /** 循环每一行,p表示行 */
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ''// 表示每一次截取的字符串
                    var start = p * provideNumber// 开始截取的位置
                    var end = start + provideNumber// 结束截取的位置
                    // 此处特殊处理最后一行的索引值
                    if (p === rowNumber - 1) {
                      // 最后一次不换行
                      tempStr = params.substring(start, paramsNameNumber)
                    } else {
                      // 每一次拼接字符串并换行
                      tempStr = params.substring(start, end) + '\n'
                    }
                    newParamsName += tempStr // 最终拼成的字符串
                  }
                } else {
                  // 将旧标签的值赋给新标签
                  newParamsName = params
                }
                // 将最终的字符串返回
                return newParamsName
              }
            }
          }
        ],
        // yAxis: yAxis,
        yAxis: [
          {
            type: 'value',
            scale: true,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: this.echartsColor
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: this.echartsColor
              }
            }
            // max: parseInt(max * 1.4).toFixed(0) <= 1 ? 1 : parseInt(max * 1.4).toFixed(0)
          }
        ],
        series: [
          {
            type: 'line',
            showAllSymbol: true,
            symbol: 'circle',
            symbolSize: 5,
            smooth: false,
            lineStyle: {
              normal: {
                width: 3,
                color: '#30c0e2' // 线条颜色
              },
              type: 'solid'
              // borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: '#30c0e2',
              borderWidth: 1,
              borderColor: '#fff'
            },
            tooltip: {
              backgroundColor: '#fff',
              borderColor: '#333',
              textStyle: {
                color: '#333'
              },
              borderWidth: 1,
              show: true,
              confine: true
            },
            label: {
              show: false
            },
            data: valArr
          }
        ]
      }
      myChart.resize()
      myChart.clear()
      myChart.setOption(option)
    },
    // // 折线图 样式三
    getLineChartThree (nameArr, valArr, formatter, title) {
      var chartDom = document.getElementById(
        `ecartsBox${this.data.block_enum}-${this.index}`
      )
      // console.log('-carFlowBox--', chartDom);
      if (!chartDom) return
      // 根据宽度设置横坐标最多展示多少个
      // 判断横坐标数据是否需要展示
      // let maxShowX = ''
      // if (this.data.location.wide_fill <= 10) {
      //   maxShowX = 5
      // } else if (this.data.location.wide_fill <= 20) {
      //   maxShowX = 10
      // } else if (this.data.location.wide_fill <= 30) {
      //   maxShowX = 15
      // } else if (this.data.location.wide_fill <= 40) {
      //   maxShowX = 20
      // } else {
      //   maxShowX = 25
      // }
      // if (nameArr.length <= maxShowX) {
      //   this.zoomShow = false
      // } else {
      //   this.zoomShow = true
      // }
      var myChart = echarts.init(chartDom)
      var option = {
        //  标题
        // title: title
        //   ? {
        //     text: title,
        //     textStyle: {
        //       color: this.echartsColor,
        //       fontSize: 14
        //     }
        //   }
        //   : null,
        // dataZoom: [
        //   {
        //     // 设置滚动条的隐藏与显示
        //     show: this.zoomShow,
        //     // 设置滚动条类型
        //     type: 'slider',
        //     // 设置背景颜色
        //     backgroundColor: 'transparent',
        //     // 设置选中范围的填充颜色
        //     fillerColor: this.model === 1 ? 'rgba(27, 102, 177, 1)' : '#666',
        //     // 设置边框颜色
        //     borderColor: this.model === 1 ? '#666' : '#002944',
        //     // 是否显示detail，即拖拽时候显示详细数值信息
        //     showDetail: false,
        //     // 数据窗口范围的起始数值
        //     startValue: 0,
        //     // 数据窗口范围的结束数值（一页显示多少条数据）
        //     endValue: maxShowX,
        //     // empty：当前数据窗口外的数据，被设置为空。
        //     // 即不会影响其他轴的数据范围
        //     filterMode: 'empty',
        //     // 设置滚动条宽度，相对于盒子宽度
        //     width: '70%',
        //     // 设置滚动条高度
        //     height: 8,
        //     // 设置滚动条显示位置
        //     left: 'center',
        //     // 是否锁定选择区域（或叫做数据窗口）的大小
        //     zoomLoxk: true,
        //     // 控制手柄的尺寸
        //     handleSize: 0,
        //     // dataZoom-slider组件离容器下侧的距离
        //     bottom: '0',
        //     handleStyle: {
        //       color: '#3AAAF0',
        //       borderColor: '#007acc'
        //     },
        //     selectedDataBackground: {
        //       lineStyle: {
        //         opacity: 0
        //       }
        //     }
        //   },
        //   {
        //     // 没有下面这块的话，只能拖动滚动条，
        //     // 鼠标滚轮在区域内不能控制外部滚动条
        //     type: 'inside',
        //     // 滚轮是否触发缩放
        //     zoomOnMouseWheel: false,
        //     // 鼠标滚轮触发滚动
        //     moveOnMouseMove: true,
        //     moveOnMouseWheel: true
        //   }
        // ],
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#333',
          textStyle: {
            color: '#333'
          },
          borderWidth: 1,
          trigger: 'axis',
          formatter: formatter,
          confine: true
        },
        grid: {
          left: '3%',
          right: '4%',
          top: title ? '14%' : '5%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: nameArr,
            axisTick: {
              show: false,
              alignWithLabel: true
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: this.echartsColor
              }
            },
            scale: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: this.echartsColor
              },
              rotate: '25', // 修改展示方式为斜向展示
              margin: '15',
              formatter: function (params) {
                var newParamsName = ''// 最终拼接成的字符串
                var paramsNameNumber = params.length// 实际标签的个数
                var provideNumber = 5// 每行能显示的字的个数
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber)// 换行的话，需要显示几行，向上取整
                /**
                 * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                 */
                // 条件等同于rowNumber>1
                if (paramsNameNumber > provideNumber) {
                  /** 循环每一行,p表示行 */
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ''// 表示每一次截取的字符串
                    var start = p * provideNumber// 开始截取的位置
                    var end = start + provideNumber// 结束截取的位置
                    // 此处特殊处理最后一行的索引值
                    if (p === rowNumber - 1) {
                      // 最后一次不换行
                      tempStr = params.substring(start, paramsNameNumber)
                    } else {
                      // 每一次拼接字符串并换行
                      tempStr = params.substring(start, end) + '\n'
                    }
                    newParamsName += tempStr // 最终拼成的字符串
                  }
                } else {
                  // 将旧标签的值赋给新标签
                  newParamsName = params
                }
                // 将最终的字符串返回
                return newParamsName
              }
            }
          }
        ],
        // yAxis: yAxis,
        yAxis: [
          {
            type: 'value',
            scale: true,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: this.echartsColor
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: this.echartsColor
              }
            }
            // max: parseInt(max * 1.4).toFixed(0) <= 1 ? 1 : parseInt(max * 1.4).toFixed(0)
          }
        ],
        series: [
          {
            type: 'line',
            showAllSymbol: true,
            symbol: 'circle',
            symbolSize: 5,
            smooth: false,
            lineStyle: {
              normal: {
                width: 3,
                color: '#30c0e2' // 线条颜色
              },
              type: 'solid'
              // borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: '#30c0e2',
              borderWidth: 1,
              borderColor: '#fff'
            },
            tooltip: {
              backgroundColor: '#fff',
              borderColor: '#333',
              textStyle: {
                color: '#333'
              },
              borderWidth: 1,
              show: true,
              confine: true
            },
            label: {
              show: false
            },
            areaStyle: {
              // 区域填充样式
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: 'rgba(1, 166, 251, 1)' }, // 100% 处的颜色
                    { offset: 0.3, color: 'rgba(1, 166, 251, 0.5)' },
                    { offset: 0.8, color: 'rgba(1, 166, 251, 0.1)' },
                    { offset: 1, color: 'rgba(1, 166, 251, 0.1)' } // 0% 处的颜色
                  ],
                  false
                ), // 渐变
                shadowColor: 'rgba(1, 166, 251, 0.5)', // 阴影颜色
                shadowBlur: 10 // shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: valArr
          }
        ]
      }
      myChart.resize()
      myChart.clear()
      myChart.setOption(option)
    },
    // 折线图（横向）
    getLineChartHorizontal (nameArr, valArr, formatter, title) {
      var chartDom = document.getElementById(
        `ecartsBox${this.data.block_enum}-${this.index}`
      )
      // console.log('-carFlowBox--', chartDom);
      if (!chartDom) return
      var myChart = echarts.init(chartDom)
      var option = {
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#333',
          textStyle: {
            color: '#333'
          },
          borderWidth: 1,
          trigger: 'axis',
          formatter: formatter,
          confine: true
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '5%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: {
          type: 'category',
          data: nameArr,
          axisTick: {
            show: false,
            alignWithLabel: true
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: this.echartsColor
            }
          },
          scale: true,
          axisLabel: {
            show: true,
            textStyle: {
              color: this.echartsColor
            }
          }
        },
        xAxis: {
          type: 'value',
          scale: true,
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: this.echartsColor
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: this.echartsColor
            }
            // overflow: 'truncate',
            // ellipsis: '...'
          }
        },
        series: [
          {
            type: 'line',
            showAllSymbol: true,
            symbol: 'circle',
            symbolSize: 8,
            smooth: true,
            lineStyle: {
              normal: {
                width: 3,
                color: '#30c0e2' // 线条颜色
              },
              type: 'solid'
              // borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: '#30c0e2',
              borderWidth: 1,
              borderColor: '#fff'
            },
            tooltip: {
              backgroundColor: '#fff',
              borderColor: '#333',
              textStyle: {
                color: '#333'
              },
              borderWidth: 1,
              show: true,
              confine: true
            },
            label: {
              show: false
            },
            areaStyle: {
              // 区域填充样式
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: 'rgba(1, 166, 251, 1)' }, // 100% 处的颜色
                    { offset: 0.3, color: 'rgba(1, 166, 251, 0.5)' },
                    { offset: 0.8, color: 'rgba(1, 166, 251, 0.1)' },
                    { offset: 1, color: 'rgba(1, 166, 251, 0.1)' } // 0% 处的颜色
                  ],
                  false
                ), // 渐变
                shadowColor: 'rgba(1, 166, 251, 0.5)', // 阴影颜色
                shadowBlur: 10 // shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              }
            },
            data: valArr
          }
        ]
      }
      myChart.resize()
      myChart.clear()
      myChart.setOption(option)
    },
    // 柱状图（横向） 样式一
    getBarChartHorizontal (dataList, nameList, formatter, title) {
      var chartDom = document.getElementById(
        `ecartsBox${this.data.block_enum}-${this.index}`
      )
      if (!chartDom) return
      var myChart = echarts.init(chartDom)
      var option = {
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#333',
          textStyle: {
            color: '#333'
          },
          borderWidth: 1,
          trigger: 'item',
          formatter: formatter,
          confine: true
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '4%',
          top: '4%',
          containLabel: true
        },
        yAxis: {
          type: 'category',
          data: nameList,
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: this.echartsColor
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: this.echartsColor
            }
          }
        },
        xAxis: {
          type: 'value',
          scale: true,
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: this.echartsColor
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: this.echartsColor
            }
          }
        },
        series: [
          // 柱体
          {
            data: dataList,
            type: 'bar',
            barWidth: 12, // 柱子宽度
            barBorderRadius: [6, 6, 6, 6],
            label: {
              show: false
              // color: '#A7D6F4',
              // fontSize: 14,
              // distance: 20, // 距离
              // formatter: '{c} ', // 这里是数据展示的时候显示的数据
              // align: "center",
              // position: [290, 0]
            }, // 柱子上方的数值
            itemStyle: {
              barBorderRadius: [8, 8, 8, 8], // 圆角（左上、右上、右下、左下）
              color: new echarts.graphic.LinearGradient(
                1,
                0,
                0,
                0,
                [
                  { offset: 0, color: 'rgba(1, 166, 251, 1)' }, // 100% 处的颜色
                  { offset: 0.3, color: 'rgba(1, 166, 251, 0.75)' },
                  { offset: 0.8, color: 'rgba(1, 166, 251, 0.5)' },
                  { offset: 1, color: 'rgba(1, 166, 251, 0.1)' } // 0% 处的颜色
                ],
                false
              ) // 渐变
            }
          },
          // 柱顶圆片
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [9, 9], // 调整截面形状
            symbolOffset: [-2, 0],
            z: 12,
            symbolPosition: 'end',
            itemStyle: {
              color: '#fff'
            },
            data: dataList
          }
        ]
      }
      myChart.resize()
      myChart.clear()
      myChart.setOption(option)
    },
    // // 柱状图（横向） 样式二
    // getBarChartHorizontal (dataList, nameList, formatter, title) {
    //   var chartDom = document.getElementById(
    //     `ecartsBox${this.data.block_enum}-${this.index}`
    //   )
    //   if (!chartDom) return
    //   var colorList = [
    //     '#125AF3',
    //     '#2AAFFA',
    //     '#1CF9F2',
    //     '#30DFA3',
    //     '#FCCA28',
    //     '#F8437A'
    //   ]
    //   var myChart = echarts.init(chartDom)
    //   var option = {
    //     tooltip: {
    //       backgroundColor: '#fff',
    //       borderColor: '#333',
    //       axisPointer: {
    //         show: true,
    //         // 坐标轴指示器，坐标轴触发有效
    //         type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    //       },
    //       textStyle: {
    //         color: '#333'
    //       },
    //       borderWidth: 1,
    //       trigger: 'axis',
    //       formatter: formatter,
    //       confine: true
    //     },
    //     grid: {
    //       left: '3%',
    //       right: '4%',
    //       bottom: '4%',
    //       top: '4%',
    //       containLabel: true
    //     },
    //     yAxis: {
    //       type: 'category',
    //       data: nameList,
    //       axisTick: {
    //         show: false
    //       },
    //       axisLine: {
    //         show: true,
    //         lineStyle: {
    //           width: 1,
    //           color: this.echartsColor
    //         }
    //       },
    //       axisLabel: {
    //         show: true,
    //         textStyle: {
    //           color: this.echartsColor
    //         }
    //       }
    //     },
    //     xAxis: {
    //       type: 'value',
    //       scale: true,
    //       splitLine: {
    //         show: true,
    //         lineStyle: {
    //           type: 'dashed',
    //           color: this.echartsColor
    //         }
    //       },
    //       axisLabel: {
    //         show: true,
    //         textStyle: {
    //           color: this.echartsColor
    //         }
    //       }
    //     },
    //     series: [
    //       // 柱体
    //       {
    //         data: dataList,
    //         type: 'bar',
    //         barWidth: 12, // 柱子宽度
    //         // showBackground: true,
    //         // backgroundStyle: {
    //         //   color: 'rgba(128,128,128,0.1)'
    //         // },
    //         barBorderRadius: [6, 6, 6, 6],
    //         label: {
    //           show: false
    //           // color: '#A7D6F4',
    //           // fontSize: 14,
    //           // distance: 20, // 距离
    //           // formatter: '{c} ', // 这里是数据展示的时候显示的数据
    //           // align: "center",
    //           // position: [290, 0]
    //         }, // 柱子上方的数值
    //         itemStyle: {
    //           // barBorderRadius: [8, 8, 8, 8], // 圆角（左上、右上、右下、左下）
    //           color: (params) => {
    //             return new echarts.graphic.LinearGradient(
    //               1,
    //               0,
    //               0,
    //               0,
    //               [
    //                 {
    //                   offset: 0,
    //                   color: colorList[params.dataIndex]
    //                 },
    //                 {
    //                   offset: 1,
    //                   color: 'rgba(128,128,128,0.1)'
    //                 }
    //               ],
    //               false
    //             ) // 渐变
    //           }
    //         }
    //       },
    //       // 柱顶圆片
    //       {
    //         name: '',
    //         type: 'pictorialBar',
    //         symbolSize: [4, 12], // 调整截面形状
    //         symbolOffset: [1, 0],
    //         z: 12,
    //         symbolPosition: 'end',
    //         itemStyle: {
    //           color: '#fff'
    //         },
    //         data: dataList
    //       }
    //     ]
    //   }
    //   myChart.resize()
    //   myChart.clear()
    //   myChart.setOption(option)
    // },

    // // 柱状图（横向） 样式三
    // getBarChartHorizontal (dataList, nameList, formatter, title) {
    //   var chartDom = document.getElementById(
    //     `ecartsBox${this.data.block_enum}-${this.index}`
    //   )
    //   if (!chartDom) return
    //   var colorList = [
    //     '#125AF3',
    //     '#2AAFFA',
    //     '#1CF9F2',
    //     '#30DFA3',
    //     '#FCCA28',
    //     '#F8437A'
    //   ]
    //   // 柱状图粗细
    //   const offsetX = 3
    //   const offsetY = 6
    //   // 绘制顶面
    //   const CubeTop = echarts.graphic.extendShape({
    //     shape: {
    //       x: 0,
    //       y: 0
    //     },
    //     buildPath: function (ctx, shape) {
    //       const c1 = [shape.x, shape.y]
    //       const c2 = [shape.x + offsetX, shape.y + offsetY] // 右点
    //       const c3 = [shape.x + offsetY, shape.y]
    //       const c4 = [shape.x + offsetX, shape.y - offsetY]
    //       ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath()
    //     }
    //   })
    //   // 绘制左侧面
    //   const CubeLeft = echarts.graphic.extendShape({
    //     shape: {
    //       x: 0,
    //       y: 0
    //     },
    //     buildPath: function (ctx, shape) {
    //       // 会canvas的应该都能看得懂，shape是从custom传入的
    //       const xAxisPoint = shape.xAxisPoint
    //       // console.log(shape);
    //       const c0 = [shape.x, shape.y]
    //       const c1 = [shape.x + offsetX, shape.y + offsetY]
    //       const c2 = [xAxisPoint[0] + offsetX, xAxisPoint[1] + offsetY]
    //       const c3 = [xAxisPoint[0], xAxisPoint[1]]
    //       ctx.moveTo(c0[0], c0[1]).lineTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).closePath()
    //     }
    //   })
    //   // 绘制右侧面
    //   const CubeRight = echarts.graphic.extendShape({
    //     shape: {
    //       x: 0,
    //       y: 0
    //     },
    //     buildPath: function (ctx, shape) {
    //       const xAxisPoint = shape.xAxisPoint
    //       const c1 = [shape.x, shape.y]
    //       const c2 = [xAxisPoint[0], xAxisPoint[1]]
    //       const c3 = [xAxisPoint[0] + offsetX, xAxisPoint[1] - offsetY]
    //       const c4 = [shape.x + offsetX, shape.y - offsetY]
    //       ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1]).closePath()
    //     }
    //   })
    //   // 注册三个面图形
    //   echarts.graphic.registerShape('CubeLeft', CubeLeft)
    //   echarts.graphic.registerShape('CubeRight', CubeRight)
    //   echarts.graphic.registerShape('CubeTop', CubeTop)
    //   var myChart = echarts.init(chartDom)
    //   var option = {
    //     tooltip: {
    //       backgroundColor: '#fff',
    //       borderColor: '#333',
    //       axisPointer: {
    //         show: true,
    //         // 坐标轴指示器，坐标轴触发有效
    //         type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
    //       },
    //       textStyle: {
    //         color: '#333'
    //       },
    //       borderWidth: 1,
    //       trigger: 'axis',
    //       formatter: formatter,
    //       confine: true
    //     },
    //     grid: {
    //       left: '3%',
    //       right: '4%',
    //       bottom: '4%',
    //       top: '4%',
    //       containLabel: true
    //     },
    //     yAxis: {
    //       type: 'category',
    //       data: nameList,
    //       axisTick: {
    //         show: false
    //       },
    //       axisLine: {
    //         show: true,
    //         lineStyle: {
    //           width: 1,
    //           color: this.echartsColor
    //         }
    //       },
    //       axisLabel: {
    //         show: true,
    //         textStyle: {
    //           color: this.echartsColor
    //         }
    //       }
    //     },
    //     xAxis: {
    //       type: 'value',
    //       scale: true,
    //       splitLine: {
    //         show: true,
    //         lineStyle: {
    //           type: 'dashed',
    //           color: this.echartsColor
    //         }
    //       },
    //       axisLabel: {
    //         show: true,
    //         textStyle: {
    //           color: this.echartsColor
    //         }
    //       }
    //     },
    //     series: [
    //       // 柱体
    //       {
    //         data: dataList,
    //         type: 'custom',
    //         renderItem: (params, api) => {
    //           const location = api.coord([api.value(0), api.value(1)])
    //           return {
    //             type: 'group',
    //             children: [
    //               {
    //                 type: 'CubeLeft',
    //                 shape: {
    //                   api,
    //                   xValue: api.value(0),
    //                   yValue: api.value(1),
    //                   x: location[0],
    //                   y: location[1],
    //                   xAxisPoint: api.coord([0, api.value(1)])
    //                 },
    //                 style: {
    //                   fill: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
    //                     {
    //                       offset: 1,
    //                       color: colorList[params.dataIndex]
    //                     },
    //                     {
    //                       offset: 0,
    //                       color: 'rgba(192,192,192,0.3)'
    //                     }
    //                   ])
    //                 }
    //               },
    //               {
    //                 type: 'CubeRight',
    //                 shape: {
    //                   api,
    //                   xValue: api.value(0),
    //                   yValue: api.value(1),
    //                   x: location[0],
    //                   y: location[1],
    //                   xAxisPoint: api.coord([0, api.value(1)])
    //                 },
    //                 style: {
    //                   fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //                     {
    //                       offset: 0,
    //                       color: 'rgba(192,192,192,0.3)'
    //                     },
    //                     {
    //                       offset: 1,
    //                       color: 'rgba(192,192,192,0.3)'
    //                     }
    //                   ])
    //                 }
    //               },
    //               {
    //                 type: 'CubeTop',
    //                 shape: {
    //                   api,
    //                   xValue: api.value(0),
    //                   yValue: api.value(1),
    //                   x: location[0],
    //                   y: location[1],
    //                   xAxisPoint: api.coord([0, api.value(1)])
    //                 },
    //                 style: {
    //                   fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //                     {
    //                       offset: 0,
    //                       color: '#fff'
    //                     },
    //                     {
    //                       offset: 1,
    //                       color: '#fff'
    //                     }
    //                   ])
    //                 }
    //               }
    //             ]
    //           }
    //         }
    //         // barWidth: 12, // 柱子宽度
    //         // showBackground: true,
    //         // backgroundStyle: {
    //         //   color: 'rgba(128,128,128,0.2)'
    //         // },
    //         // barBorderRadius: [6, 6, 6, 6],
    //         // label: {
    //         //   show: false
    //         //   // color: '#A7D6F4',
    //         //   // fontSize: 14,
    //         //   // distance: 20, // 距离
    //         //   // formatter: '{c} ', // 这里是数据展示的时候显示的数据
    //         //   // align: "center",
    //         //   // position: [290, 0]
    //         // }, // 柱子上方的数值
    //         // itemStyle: {
    //         //   // barBorderRadius: [8, 8, 8, 8], // 圆角（左上、右上、右下、左下）
    //         //   color: (params) => {
    //         //     return new echarts.graphic.LinearGradient(
    //         //       1,
    //         //       0,
    //         //       0,
    //         //       0,
    //         //       [
    //         //         {
    //         //           offset: 0,
    //         //           color: colorList[params.dataIndex]
    //         //         },
    //         //         {
    //         //           offset: 1,
    //         //           color: 'rgba(128,128,128,0.1)'
    //         //         }
    //         //       ],
    //         //       false
    //         //     ) // 渐变
    //         //   }
    //         // }
    //       }
    //       // 柱顶圆片
    //       // {
    //       //   name: '',
    //       //   type: 'pictorialBar',
    //       //   symbolSize: [4, 12], // 调整截面形状
    //       //   symbolOffset: [1, 0],
    //       //   z: 12,
    //       //   symbolPosition: 'end',
    //       //   itemStyle: {
    //       //     color: '#fff'
    //       //   },
    //       //   data: dataList
    //       // }
    //     ]
    //   }
    //   myChart.resize()
    //   myChart.clear()
    //   myChart.setOption(option)
    // },
    // 中国地图
    getChinaEChart (newArray) {
      this.getMapEcharts(newArray, 'china', chinaMap)
    },
    // 告警排名图表
    initAlarmRankingEcharts (nameList, numberList) {
      const chartDom = document.getElementById(`charAlarmLineBox-${this.index}`)
      console.log('outBoxWidth', this.outBoxWidth)
      const wide = this.outBoxWidth * 0.6
      if (!chartDom) return
      let maxShowX = ''
      if (this.data.location.high_fill <= 10) {
        maxShowX = 2
      } else if (this.data.location.high_fill <= 12) {
        maxShowX = 3
      } else if (this.data.location.high_fill <= 15) {
        maxShowX = 4
      } else if (this.data.location.high_fill <= 20) {
        maxShowX = 6
      } else if (this.data.location.high_fill <= 25) {
        maxShowX = 8
      } else {
        maxShowX = 12
      }
      if (nameList.length <= maxShowX) {
        this.zoomShow = false
      } else {
        this.zoomShow = true
      }
      const myChart = echarts.init(chartDom)
      var charts = { // 按顺序排列从大到小
        cityList: nameList,
        cityData: numberList
      }
      var top10CityList = charts.cityList
      var top10CityData = charts.cityData
      // 前端颜色
      var color = ['#fff', '#fff', '#fff']
      // 后段颜色
      var color1 = this.model === 1 ? ['#FDD56A', '#20D3AB', '#49CCFF'] : ['#FFB94A', '#8B8D96', '#A75E3F']
      // 边框颜色
      var color2 = this.model === 1 ? ['#FDD56A22', '#20D3AB22', '#49CCFF22'] : ['#fff', '#fff', '#fff']
      const lineY = []
      const lineT = []
      for (var i = 0; i < charts.cityList.length; i++) {
        var x = i
        if (x > 1) {
          x = 2
        }
        var data = {
          name: charts.cityList[i],
          color: color[x],
          value: top10CityData[i],
          barGap: '-100%',
          itemStyle: {
            normal: {
              show: true,
              borderWidth: 2,
              shadowBlur: 1,
              borderColor: color2[x],
              shadowColor: color[x],
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                offset: 0,
                color: color[x]
              }, {
                offset: 1,
                color: color1[x]
              }], false),
              barBorderRadius: 10
            }
          }
        }
        var data1 = {
          color: this.model === 1 ? '#59f7f9' : '#666',
          value: top10CityData[i],
          // barGap: '-100%',
          itemStyle: {
            color: '#00123500', // 剩余部分颜色
            barBorderRadius: 10
          },
          label: {
            show: true,
            formatter: '{a|' + top10CityData[i] + '次}',
            position: 'insideEnd',
            offset: [wide, -25],
            color: color[x],
            distance: 20,
            rich: {
              a: {
                color: this.model === 1 ? '#59f7f9' : '#666',
                // borderColor: color[x],
                // borderWidth: 0.5,
                padding: [5, 5, 0, 15],
                height: 18,
                fontSize: 14,
                align: 'left',
                verticalAlign: 'middle'
                // backgroundColor: color2[x]
              }
            }
          }
        }
        lineY.push(data)
        lineT.push(data1)
      }
      const option = {
        // backgroundColor: "#1F312F",
        title: {
          show: false
        },
        tooltip: {
          trigger: 'item',
          formatter: (p) => {
            if (p.seriesName === 'total') {
              return ''
            }
            return `<div>${p.name}：${p.value}次告警</div>`
          }
        },
        grid: {
          borderWidth: 0,
          top: '20px',
          left: '15%',
          right: '10%',
          bottom: '5%'
        },
        // 滚动配置
        dataZoom: [{
          // 设置滚动条的隐藏与显示
          show: this.zoomShow,
          type: 'slider',
          yAxisIndex: 0,
          zoomLock: true,
          width: 1,
          height: '70%',
          handleSize: 0,
          handleStyle: {
            color: '#3AAAF0',
            borderColor: '#007acc'
          },
          selectedDataBackground: {
            lineStyle: {
              opacity: 0
            }
          },
          // 即不会影响其他轴的数据范围
          filterMode: 'empty',
          fillerColor: this.model === 1 ? 'rgba(27, 102, 177, 1)' : '#666',
          backgroundColor: 'transparent',
          showDetail: false,
          borderColor: this.model === 1 ? '#666' : '#002944',
          start: 0,
          end: 30,
          endValue: maxShowX, // 最多六个
          minValueSpan: maxShowX, // 放大到最少几个
          maxValueSpan: 2 //  缩小到最多几个
        }, {
          type: 'inside',
          id: 'insideY',
          yAxisIndex: 0,
          start: 0,
          end: 50,
          zoomOnMouseWheel: false,
          moveOnMouseMove: false,
          moveOnMouseWheel: true,
          preventDefaultMouseMove: true
        }],
        color: color,
        yAxis: [{
          type: 'category',
          inverse: true,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false,
            inside: false
          },
          data: top10CityList
        }, {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
            inside: false,
            verticalAlign: 'bottom',
            lineHeight: '20',
            textStyle: {
              color: '#fff',
              fontSize: '14',
              fontFamily: 'PingFangSC-Regular'
            },
            formatter: function (val) {
              return `${val}`
            }
          },
          splitArea: {
            show: false
          },
          splitLine: {
            show: false
          },
          data: top10CityData
        }],
        xAxis: {
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        series: [
          {
            name: 'total',
            type: 'bar',
            barGap: '-100%',
            barWidth: '15px',
            data: lineT,
            legendHoverLink: false
            // showBackground: true
          },
          {
            name: 'bar',
            type: 'bar',
            barWidth: '9px',
            data: lineY,
            showBackground: true,
            backgroundStyle: {
              color: this.model === 1 ? '#4D628C' : '#F3F4F5',
              borderRadius: 5
            },
            label: {
              normal: {
                color: '#b3ccf8',
                show: true,
                position: [0, '-20px'],
                formatter: function (a) {
                  let str = ''
                  if (a.dataIndex === 0) {
                    str = `{color4|${a.name}}`
                  } else if (a.dataIndex === 1) {
                    str = `{color4|${a.name}}`
                  } else {
                    str = `{color4|${a.name}}`
                  }
                  return str
                },
                rich: {
                  color1: {
                    color: this.model === 1 ? '#FDD56A' : '#FFB94A',
                    fontSize: '14',
                    fontWeight: 700
                  },
                  color2: {
                    color: this.model === 1 ? '#20D3AB' : '#8B8D96',
                    fontSize: '14',
                    fontWeight: 700
                  },
                  color3: {
                    color: this.model === 1 ? '#49CCFF' : '#A75E3F',
                    fontSize: '14',
                    fontWeight: 700
                  },
                  color4: {
                    fontSize: '14',
                    fontWeight: 500,
                    color: this.model === 1 ? '#fff' : '#7F7F7F'
                  }
                }
              }
            }
          }, {
            name: 'bar',
            type: 'bar',
            barWidth: '9px',
            data: lineY,
            label: {
              normal: {
                color: '#b3ccf8',
                show: true,
                width: 50,
                height: 50,
                border: 1,
                borderRadius: '50%',
                position: ['-30px', 0],
                // padding: [0, 0],
                verticalAlign: 'middle',
                lineHeight: 50,
                align: 'center',
                backgroundColor: {
                  image: this.model === 1 ? alarmBack : alarmBackLight
                },
                formatter: function (a) {
                  let num = ''
                  let str = ''
                  if (a.dataIndex + 1 < 10) {
                    num = '0' + (a.dataIndex + 1)
                  } else {
                    num = (a.dataIndex + 1)
                  }
                  if (a.dataIndex === 0) {
                    str = `{color1|${num}} `
                  } else if (a.dataIndex === 1) {
                    str = `{color2|${num}}`
                  } else {
                    str = `{color3|${num}}`
                  }
                  return str
                },
                rich: {
                  color1: {
                    color: this.model === 1 ? '#FDD56A' : '#fff',
                    fontSize: '14',
                    fontWeight: 700
                  },
                  color2: {
                    color: this.model === 1 ? '#20D3AB' : '#fff',
                    fontSize: '14',
                    fontWeight: 700
                  },
                  color3: {
                    color: this.model === 1 ? '#49CCFF' : '#fff',
                    fontSize: '14',
                    fontWeight: 700
                  },
                  color4: {
                    fontSize: '14',
                    fontWeight: 500,
                    color: this.model === 1 ? '#fff' : '#7F7F7F'
                  }
                }
              }
            }
          }]
      }
      option && myChart.setOption(option)
    },
    // 异常事件趋势分析折线图
    initTrendAnalysisEcharts (nameList, seriesList, xData, month) {
      const chartDom = document.getElementById(`ecartsBox${this.data.block_enum}-${this.index}`)
      if (!chartDom) return
      // let maxShowX = ''
      // if (this.data.location.wide_fill <= 10) {
      //   this.zoomShow = true
      //   maxShowX = 8
      // } else if (this.data.location.wide_fill <= 20) {
      //   this.zoomShow = true
      //   maxShowX = 16
      // } else if (this.data.location.wide_fill <= 30) {
      //   this.zoomShow = false
      //   maxShowX = 35
      // } else if (this.data.location.wide_fill <= 40) {
      //   this.zoomShow = false
      //   maxShowX = 35
      // } else {
      //   this.zoomShow = false
      //   maxShowX = 35
      // }
      const myChart = echarts.init(chartDom)
      var colorList = ['#FCB12D', '#EF5F47', '#FF278D', '#BA27FF', '#0060D6', '#01FAFD', '#1EE6A3']
      const option = {
        color: colorList,
        // dataZoom: [
        //   {
        //     // 设置滚动条的隐藏与显示
        //     show: this.zoomShow,
        //     // 设置滚动条类型
        //     type: 'slider',
        //     // 设置背景颜色
        //     backgroundColor: 'transparent',
        //     // 设置选中范围的填充颜色
        //     fillerColor: this.model === 1 ? 'rgba(27, 102, 177, 1)' : '#666',
        //     // 设置边框颜色
        //     borderColor: this.model === 1 ? '#666' : '#002944',
        //     // 是否显示detail，即拖拽时候显示详细数值信息
        //     showDetail: false,
        //     // 数据窗口范围的起始数值
        //     startValue: 0,
        //     // 数据窗口范围的结束数值（一页显示多少条数据）
        //     endValue: maxShowX,
        //     // empty：当前数据窗口外的数据，被设置为空。
        //     // 即不会影响其他轴的数据范围
        //     filterMode: 'empty',
        //     // 设置滚动条宽度，相对于盒子宽度
        //     width: '70%',
        //     // 设置滚动条高度
        //     height: 8,
        //     // 设置滚动条显示位置
        //     left: 'center',
        //     // 是否锁定选择区域（或叫做数据窗口）的大小
        //     zoomLoxk: true,
        //     // 控制手柄的尺寸
        //     handleSize: 0,
        //     // dataZoom-slider组件离容器下侧的距离
        //     bottom: '0',
        //     handleStyle: {
        //       color: '#3AAAF0',
        //       borderColor: '#007acc'
        //     },
        //     selectedDataBackground: {
        //       lineStyle: {
        //         opacity: 0
        //       }
        //     }
        //   },
        //   {
        //     // 没有下面这块的话，只能拖动滚动条，
        //     // 鼠标滚轮在区域内不能控制外部滚动条
        //     type: 'inside',
        //     // 滚轮是否触发缩放
        //     zoomOnMouseWheel: false,
        //     // 鼠标滚轮触发滚动
        //     moveOnMouseMove: true,
        //     moveOnMouseWheel: true
        //   }
        // ],
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(50,50,50,0.7)',
          textStyle: {
            color: '#fff',
            align: 'left'
          },
          formatter: function (params) {
            var res = ''
            if (params[0].axisValue.endsWith('月')) {
              res = params[0].axisValue + '<br/>'
            } else {
              res = month + '月' + params[0].axisValue + '日' + '<br/>'
            }
            for (let i = 0; i < params.length; i++) {
              res += params[i].marker + params[i].seriesName + ':' + params[i].data + '<br/>'
            }
            return res
          },
          confine: true
        },
        legend: {
          top: '2%',
          // itemWidth: '',
          itemStyle: {
            borderWidth: '2',
            borderType: 'solid'
          },
          // itemGap: 25,
          // padding: [2, 2, 0, 2],
          textStyle: {
            color: this.model === 1 ? '#fff' : '#666'
            // backgroundColor: {
            //   image: this.model === 1 ? bottonImg : buttonbackgroundLight
            // },
            // width: 50,
            // height: 8,
            // borderWidth: 10
            // padding: [10, 10]
          },
          type: 'scroll',
          // width: 300,
          pageIconColor: this.echartsColor, // 图例分页左右箭头图标颜色
          pageTextStyle: {
            color: this.echartsColor // 图例分页页码的颜色设置
          },
          pageIconSize: 12, // 当然就是按钮的大小
          pageIconInactiveColor: '#7f7f7f', // 禁用的按钮颜色
          icon: 'circle',
          data: nameList
        },
        grid: {
          left: '2%',
          right: '4%',
          bottom: '8%',
          top: '28%',
          containLabel: true
        },

        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisTick: 'false',
          axisLine: {
            lineStyle: {
              color: this.model === 1 ? '#fff' : '#666'
            }
          },
          axisLabel: {
            // x轴文字的配置
            show: true,
            interval: 0 // 使x轴文字显示全
          },
          data: xData
        },
        yAxis: {
          name: '单位：次',
          type: 'value',
          axisTick: 'false',
          axisLine: {
            lineStyle: {
              color: this.model === 1 ? '#fff' : '#666'
            }
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'gray'
            }
          }
        },
        series: seriesList
      }
      option && myChart.setOption(option)
    },
    // 异常事件趋势分析柱状图
    initTrendAnalysisBarEcharts (seriesData, xData) {
      // console.log('seriesData', seriesData)
      const chartDom = document.getElementById(`ecartsBox${this.data.block_enum}-${this.index}`)
      if (!chartDom) return
      // let maxShowX = ''
      // if (this.data.location.wide_fill <= 10) {
      //   this.zoomShow = true
      //   maxShowX = 8
      // } else if (this.data.location.wide_fill <= 20) {
      //   this.zoomShow = true
      //   maxShowX = 16
      // } else if (this.data.location.wide_fill <= 30) {
      //   this.zoomShow = false
      //   maxShowX = 35
      // } else if (this.data.location.wide_fill <= 40) {
      //   this.zoomShow = false
      //   maxShowX = 35
      // } else {
      //   this.zoomShow = false
      //   maxShowX = 35
      // }
      const myChart = echarts.init(chartDom)
      var colorList = ['#FCB12D', '#EF5F47', '#FF278D', '#BA27FF', '#0060D6', '#01FAFD', '#1EE6A3']
      const option = {
        color: colorList,
        // dataZoom: [
        //   {
        //     // 设置滚动条的隐藏与显示
        //     show: this.zoomShow,
        //     // 设置滚动条类型
        //     type: 'slider',
        //     // 设置背景颜色
        //     backgroundColor: 'transparent',
        //     // 设置选中范围的填充颜色
        //     fillerColor: this.model === 1 ? 'rgba(27, 102, 177, 1)' : '#666',
        //     // 设置边框颜色
        //     borderColor: this.model === 1 ? '#666' : '#002944',
        //     // 是否显示detail，即拖拽时候显示详细数值信息
        //     showDetail: false,
        //     // 数据窗口范围的起始数值
        //     startValue: 0,
        //     // 数据窗口范围的结束数值（一页显示多少条数据）
        //     endValue: maxShowX,
        //     // empty：当前数据窗口外的数据，被设置为空。
        //     // 即不会影响其他轴的数据范围
        //     filterMode: 'empty',
        //     // 设置滚动条宽度，相对于盒子宽度
        //     width: '70%',
        //     // 设置滚动条高度
        //     height: 8,
        //     // 设置滚动条显示位置
        //     left: 'center',
        //     // 是否锁定选择区域（或叫做数据窗口）的大小
        //     zoomLoxk: true,
        //     // 控制手柄的尺寸
        //     handleSize: 0,
        //     // dataZoom-slider组件离容器下侧的距离
        //     bottom: '0',
        //     handleStyle: {
        //       color: '#3AAAF0',
        //       borderColor: '#007acc'
        //     },
        //     selectedDataBackground: {
        //       lineStyle: {
        //         opacity: 0
        //       }
        //     }
        //   },
        //   {
        //     // 没有下面这块的话，只能拖动滚动条，
        //     // 鼠标滚轮在区域内不能控制外部滚动条
        //     type: 'inside',
        //     // 滚轮是否触发缩放
        //     zoomOnMouseWheel: false,
        //     // 鼠标滚轮触发滚动
        //     moveOnMouseMove: true,
        //     moveOnMouseWheel: true
        //   }
        // ],
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(50,50,50,0.7)',
          axisPointer: {
            type: 'shadow'
          },
          textStyle: {
            color: '#fff',
            align: 'left'
          },
          confine: true
        },
        legend: {
          top: '1%',
          icon: 'roundRect',
          itemGap: 20,
          itemWidth: 14,
          itemHeight: 14,
          // width: 300,
          orient: 'horizontal',
          textStyle: {
            color: this.model === 1 ? '#fff' : '#666'
          },
          type: 'scroll',
          pageIconColor: this.echartsColor, // 图例分页左右箭头图标颜色
          pageTextStyle: {
            color: this.echartsColor // 图例分页页码的颜色设置
          },
          pageIconSize: 12, // 当然就是按钮的大小
          pageIconInactiveColor: '#7f7f7f' // 禁用的按钮颜色
        },
        textStyle: {
          color: this.model === 1 ? '#fff' : '#666'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            nameTextStyle: {
              color: this.model === 1 ? '#fff' : '#666',
              fontSize: 14
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: this.model === 1 ? '#fff' : '#666'
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: this.model === 1 ? '#fff' : '#666'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: this.model === 1 ? '#fff' : '#666'
              }
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: this.model === 1 ? '#fff' : '#666'
              }
            }
          }
        ],
        series: seriesData
      }
      option && myChart.setOption(option)
    },
    // 异常事件趋势分析柱状图 3D
    initTrendAnalysisBarEcharts3D (seriesData, xData) {
      const dataList = []
      const xList = xData
      const yList = []
      const maxList = []
      seriesData.forEach((item, index) => {
        yList.push(item.name)
        maxList.push(Math.max(...item.data))
        item.data.forEach((element, inx) => {
          dataList.push([index, inx, element])
        })
      })
      const maxLevel = Math.max(...maxList)
      const chartDom = document.getElementById(`ecartsBox${this.data.block_enum}-${this.index}`)
      if (!chartDom) return
      const myChart = echarts.init(chartDom)
      // const option = {
      //   tooltip: {
      //     trigger: 'axis',
      //     axisPointer: {
      //       type: 'shadow'
      //     }
      //   },
      //   legend: {
      //     right: 'center',
      //     top: 0,
      //     icon: 'roundRect',
      //     itemGap: 20,
      //     itemWidth: 14,
      //     itemHeight: 14,
      //     width: 300,
      //     orient: 'horizontal',
      //     textStyle: {
      //       color: this.model === 1 ? '#fff' : '#666'
      //     },
      //     type: 'scroll',
      //     pageIconColor: this.echartsColor, // 图例分页左右箭头图标颜色
      //     pageTextStyle: {
      //       color: this.echartsColor // 图例分页页码的颜色设置
      //     },
      //     pageIconSize: 12, // 当然就是按钮的大小
      //     pageIconInactiveColor: '#7f7f7f' // 禁用的按钮颜色
      //   },
      //   textStyle: {
      //     color: this.model === 1 ? '#fff' : '#666'
      //   },
      //   grid: {
      //     left: '3%',
      //     right: '4%',
      //     bottom: '3%',
      //     containLabel: true
      //   },
      //   xAxis: [
      //     {
      //       type: 'category',
      //       data: xData,
      //       nameTextStyle: {
      //         color: this.model === 1 ? '#fff' : '#666',
      //         fontSize: 14
      //       },
      //       axisTick: {
      //         show: false
      //       },
      //       splitLine: {
      //         show: false,
      //         lineStyle: {
      //           color: this.model === 1 ? '#fff' : '#666'
      //         }
      //       },
      //       axisLine: {
      //         show: true,
      //         lineStyle: {
      //           color: this.model === 1 ? '#fff' : '#666'
      //         }
      //       }
      //     }
      //   ],
      //   yAxis: [
      //     {
      //       type: 'value',
      //       splitLine: {
      //         show: true,
      //         lineStyle: {
      //           color: this.model === 1 ? '#fff' : '#666'
      //         }
      //       },
      //       axisLine: {
      //         show: false,
      //         lineStyle: {
      //           color: this.model === 1 ? '#fff' : '#666'
      //         }
      //       }
      //     }
      //   ],
      //   color: ['#F86846', '#3DB7CE', '#FFB11A', '#5ED8A9'],
      //   series: seriesData
      // }
      const option = {
        tooltip: {
          formatter: (params) => {
            return `<div>${params.marker}${xList[params.value[0]]}${yList[params.value[1]]}${params.value[2]}次</div>`
          }
        },
        visualMap: {
          max: maxLevel,
          inRange: {
            color: [
              '#313695',
              '#4575b4',
              '#74add1',
              '#abd9e9',
              '#e0f3f8',
              '#ffffbf',
              '#fee090',
              '#fdae61',
              '#f46d43',
              '#d73027',
              '#a50026'
            ]
          }
        },
        xAxis3D: {
          type: 'category',
          axisLabel: {
            color: this.model === 1 ? '#fff' : '#666'
          },
          axisLine: {
            lineStyle: {
              color: this.model === 1 ? '#fff' : '#666'
            }
          },
          data: xList
        },
        yAxis3D: {
          type: 'category',
          axisLabel: {
            color: this.model === 1 ? '#fff' : '#666'
          },
          axisLine: {
            lineStyle: {
              color: this.model === 1 ? '#fff' : '#666'
            }
          },
          data: yList
        },
        zAxis3D: {
          type: 'value',
          axisLabel: {
            color: this.model === 1 ? '#fff' : '#666'
          },
          axisLine: {
            lineStyle: {
              color: this.model === 1 ? '#fff' : '#666'
            }
          }
        },
        grid3D: {
          boxWidth: 200,
          boxDepth: 80,
          viewControl: {
            // projection: 'orthographic'
          },
          light: {
            main: {
              intensity: 1.2,
              shadow: true
            },
            ambient: {
              intensity: 0.3
            }
          }
        },
        series: [
          {
            type: 'bar3D',
            data: dataList.map(function (item) {
              return {
                value: [item[1], item[0], item[2]]
              }
            }),
            shading: 'lambert',
            label: {
              fontSize: 16,
              borderWidth: 1

            },
            emphasis: {
              label: {
                fontSize: 20,
                color: '#900'
              },
              itemStyle: {
                color: '#900'
              }
            }
          }
        ]
      }
      option && myChart.setOption(option)
    },
    // 长沙地图
    getCSEChart (newArray) {
      // console.log('getCSEChart----', newArray)
      // console.log('changshaMap---', changshaMap)
      this.getMapEcharts(newArray, 'changsha', changshaMap)
    },
    getMapEcharts (newArray, mapName, list) {
      var chartDom = document.getElementById(
        `ecartsBox${this.data.block_enum}-${this.index}`
      )
      // console.log(chartDom);
      if (!chartDom) return
      echarts.registerMap(mapName, list)
      var myChart = echarts.init(chartDom)
      var itemStyle
      if (this.model === 1) {
        itemStyle = {
          normal: {
            borderColor: '#4b93da', // 图形的描边颜色
            borderWidth: 1, // 描边线宽。为 0 时无描边。
            borderType: 'solid', // 柱条的描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'。
            areaColor: '#0b2455', // 图形的颜色 #eee
            shadowBlur: 100, // 图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果。
            shadowColor: '#133988', // 阴影色
            shadowOffsetX: 20, // X轴阴影
            shadowOffsetY: -20 // Y轴阴影
          },
          // 鼠标移入时
          emphasis: {
            borderColor: '#005b89',
            borderWidth: '1',
            areaColor: '#0071db',
            label: {
              show: false,
              textStyle: {
                color: 'yellow',
                fontSize: 14
              }
            }
          },

          effect: {
            show: true,
            shadowBlur: 10,
            loop: true
          }
        }
      } else {
        itemStyle = {
          normal: {
            borderColor: '#4b93da', // 图形的描边颜色
            borderWidth: 1, // 描边线宽。为 0 时无描边。
            borderType: 'solid', // 柱条的描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'。
            areaColor: '#ffffff', // 图形的颜色 #eee
            shadowBlur: 100, // 图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果。
            shadowColor: '#ffffff', // 阴影色
            shadowOffsetX: 20, // X轴阴影
            shadowOffsetY: -20 // Y轴阴影
          },
          // 鼠标移入时
          emphasis: {
            borderColor: '#005b89',
            borderWidth: '1',
            areaColor: '#53a8ff',
            label: {
              show: false,
              textStyle: {
                color: 'yellow',
                fontSize: 14
              }
            }
          },

          effect: {
            show: true,
            shadowBlur: 10,
            loop: true
          }
        }
      }
      // console.log('newArray---', newArray);
      // console.log('newArray---', mapName);
      // console.log('newArray---', list);
      var option = {
        /**
         * 你可以自定义样式
         **/
        tooltip: {
          // backgroundColor: '#fff',
          borderColor: '#333',
          borderWidth: 1,
          show: true, // 是否显示提示框组件，包括提示框浮层和 axisPointer。
          trigger: 'item', // 触发类型。item,axis,none
          enterable: true, // 鼠标是否可进入提示框浮层中，默认为false，
          showContent: true, // 是否显示提示框浮层
          // triggerOn: 'mousemove', //提示框触发的条件(mousemove|click|none)
          // showDelay: 200,         //浮层显示的延迟，单位为 ms，默认没有延迟，也不建议设置。在 triggerOn 为 'mousemove' 时有效。
          textStyle: {
            // color: 'white',
            color: '#fff',
            fontSize: 12
          },
          // padding: [0, 8],
          // hideDelay: 10,             //浮层隐藏的延迟
          // formatter: (o) => (o.data) ? `<div class="map-hover-box"><div class="map-hover-mov"><h3>建筑地址：${o.name}</h3></div><i></i></section>` : '',
          formatter: (o) => {
            // console.log('mapss', o)
            if (o.data) {
              let park = ''
              let orange = ''
              let person = ''
              if (this.data.select_data.indexOf('3') !== -1 || this.data.select_data.indexOf('b65e19c748ec46f099c053af') !== -1) {
                person = `<div>人口：${o.data.person_count}人</div>`
              }
              if (this.data.select_data.indexOf('2') !== -1 || this.data.select_data.indexOf('c9cb240bb801490fb58bc78b') !== -1) {
                orange = `<div>组织：${o.data.orange_count}个</div>`
              }
              if (this.data.select_data.indexOf('1') !== -1 || this.data.select_data.indexOf('02be1f7d9b6144a48f0eb489') !== -1) {
                park = `<div>车场：${o.data.park_count}个</div>`
              }
              return `<div>${o.name}</div>
                    <div>
                      ${park + orange + person}
                    </div>`
            }
          },
          backgroundColor: 'rgba(0,0,0,0.8)' // 提示框浮层的背景颜色。
          // alwaysShowContent: true,
          // transitionDuration: 1,      //提示框浮层的移动动画过渡时间，单位是 s，设置为 0 的时候会紧跟着鼠标移动。
        },
        series: [
          {
            map: mapName,
            type: 'map',
            zoom: 1.2, // 当前视角的缩放比例。
            roam: false, // 是否开启鼠标缩放和平移漫游。默认不开启
            label: {
              show: false,
              textStyle: {
                color: 'white',
                fontSize: 12,
                backgroundColor: '' // 文字背景色
              }
            },

            itemStyle: itemStyle,
            data: newArray

          }
        ]
      }
      myChart.resize()
      myChart.clear()
      myChart.setOption(option)
    },
    // 获取视频监控数据
    getChangeVideo (arr) {
      // console.log('所有场景数据', arr)
      // -------
      const changeTime = this.data.interval_time
      const showSize = this.data.picture_size
      for (let i = 0; i < showSize; i++) {
        this.videoPlayList.push([])
      }
      if (changeTime === 0) {
        for (let i = 0; i < showSize; i++) {
          // this.videoPlayList.pop()
          // this.videoPlayList.unshift(arr[i])
          this.videoPlayList.shift()
          this.videoPlayList.push(arr[i])
        }
      } else {
        const silceResult = this.sliceList(arr, showSize)
        let originNum = 0
        // console.log('silceResult', silceResult)
        for (let i = 0; i < silceResult[originNum].length; i++) {
          this.addVideoList(silceResult[originNum][i])
        }
        this.videoChangeTimer = setInterval(() => {
          originNum++
          if (originNum >= silceResult.length) {
            originNum = 0
          }
          const requestList = silceResult[originNum]
          for (let i = 0; i < requestList.length; i++) {
            this.addVideoList(requestList[i])
          }
        }, changeTime * 1000)
      }
      // ---------
      // const IDlist = []
      // arr.forEach(element => {
      //   IDlist.push(element.MainId)
      // })
      // this.fnGetMonitorUrlList(IDlist.join(','))
    },
    // --逐个添加mainid
    addVideoList (list) {
      // console.log('addlist', list)
      // this.videoPlayList.unshift(list)
      this.videoPlayList.shift()
      this.videoPlayList.push(list)
      // this.videoPlayList.pop()
    },
    // 获取监控流
    // fnGetMonitorUrlList (str) {
    //   console.log('请求地址', str)
    //   const allIdList = str.split(',')
    //   console.log('allIdList', allIdList)
    //   const changeTime = this.data.interval_time
    //   const showSize = this.data.picture_size
    //   for (let i = 0; i < showSize; i++) {
    //     this.videoPlayList.push([])
    //   }
    //   // console.log('changeTime', changeTime, 'showSize', showSize)
    //   if (changeTime === 0) {
    //     for (let i = 0; i < showSize; i++) {
    //       this.fnGetPlayUrl(allIdList[i])
    //     }
    //   } else {
    //     this.getRollVideo(allIdList, showSize, changeTime)
    //   }
    // },
    // 循环调用视频
    // getRollVideo (all, size, time) {
    //   console.log('循环调用', all, size, time)
    //   const silceResult = this.sliceList(all, size)
    //   let originNum = 0
    //   for (let i = 0; i < silceResult[originNum].length; i++) {
    //     this.fnGetPlayUrl(silceResult[originNum][i])
    //   }
    //   this.videoChangeTimer = setInterval(() => {
    //     originNum++
    //     if (originNum >= silceResult.length) {
    //       originNum = 0
    //     }
    //     const requestList = silceResult[originNum]
    //     for (let i = 0; i < requestList.length; i++) {
    //       this.fnGetPlayUrl(requestList[i])
    //     }
    //   }, time * 1000)
    // },
    // 请求视频地址
    // async fnGetPlayUrl (str) {
    //   console.log('获取视频地址', str)
    //   const res = await getMonitorUrlList({ MainId: str })
    //   if (res && res.Data.length > 0) {
    //     // console.log('res', res)
    //     this.videoPlayList.unshift(res.Data[0])
    //   } else {
    //     this.videoPlayList.unshift([])
    //   }
    //   this.videoPlayList.pop()
    //   // console.log('this.videoPlayList ', this.videoPlayList)
    // },
    // 等分数组
    sliceList (arr, num) {
      const result = []
      for (let i = 0, len = arr.length; i < len; i += num) {
        result.push(arr.slice(i, i + num))
      }
      return result
    },
    dbVideo (value, ChannelName) {
      console.log('展示video', value, ChannelName)
      if (!value) return
      this.centerDialogVisible = true
      this.$nextTick(() => {
        if (flv.isSupported()) {
          const videoElement = document.getElementById('fullScreenVideo') // 根据video盒子id寻找
          this.player = flv.createPlayer({
            type: 'flv',
            url: value,
            // url: 'wss://fatcity.lebopark.com/188_128_0_240/rtp/576730972146372608.flv',
            isLive: true,
            cors: true
          },
          {
            enableStashBuffer: false,
            stashInitialSize: 128,
            autoCleanupSourceBuffer: true
          }
          )
          this.player.attachMediaElement(videoElement)
          this.player.load() // 加载
          // this.player.play() // 播放
        }
        this.requestFullScreen(document.getElementById('fullScreenVideo'))
      })
    },
    // 全屏
    requestFullScreen (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen()
      } else {
        var docHtml = document.documentElement
        var docBody = document.body
        var videobox = document.getElementById('monitors')
        // var cssText = 'width:100%;height:100%;overflow:hidden;'
        // docHtml.style.cssText = cssText
        // docBody.style.cssText = cssText
        // videobox.style.cssText = cssText + ';' + 'margin:0px;padding:0px;'
        document.IsFullScreen = true
      }
    }
  }

}
</script>
<style scoped lang="less">
.fullVideoBox {
  width: 0;
  height: 0;
}

#fullScreenVideo {
  width: 100%;
  height: -webkit-fill-available;
  // height: 200px;
}

// .noCarsInDark {
//   background-image: url('~@/assets/img/noMessage/noCarsIn.png');
//   background-size: 100% 100%;
//   background-repeat: no-repeat;
//   background-position: center center;
//   position: relative;
// }

// .noCarsInLight {
//   background-image: url('~@/assets/img/noMessage/noCarsInLight.png');
//   background-size:100% 100%;
//   background-repeat: no-repeat;
//   background-position: center center;
//   position: relative;
// }

// .noPeopleInDark {
//   background-image: url('~@/assets/img/noMessage/noPeopleIn.png');
//   background-size: 100%;
//   background-repeat: no-repeat;
//   background-position: center center;
//   position: relative;
// }
// .noPeopleInLight {
//   background-image: url('~@/assets/img/noMessage/noPeopleInLight.png');
//   background-size: 100% 100%;
//   background-repeat: no-repeat;
//   background-position: center center;
//   position: relative;
// }
</style>
