/**
 * 大屏模块
 */
import axios from './http'

// get和post请求的例子
// const exampleGet = (params) => axios.get('', { params: params });
// const examplePost = (data) => axios.post('', data);
// 获取停车场、停车位、设备、车流量个数接口
// const getDataCount = () => axios.get('/ParkingLotApi/BigScreen/GetDataCount');
// 获取卡片统计数据接口
const cardStatistics = () => axios.get('/ParkingLotApi/BigScreen/CardStatistics')
// 收入
const incomeStatistics = (params) => axios.get('/ParkingLotApi/BigScreen/GetParkingIncomeStatistics', { params: params })
// 获取停车场信息接口
const getParkingMap = (params) => axios.get('/ParkingLotApi/BigScreen/GetMapParkingInformation', { params: params })
// 获取前10条出入记录接口
const recordList = () => axios.get('/ParkingLotApi/BigScreen/RecordList')
// 获取停车场数据
const getParkData = () => axios.get('/ParkingLotApi/BigScreen/GetParkingAndIncomeStatistics')
// 获取设备信息
const getDevices = () => axios.get('/ParkingLotApi/BigScreen/GetDeviceStatistics')
// 获取车流量接口（年月日）
const trafficVolume = (params) => axios.get('/ParkingLotApi/BigScreen/GetCarParkingCarflowStatistics', { params: params })
// 省市区个数
const parkGroup = (params) => axios.get('/ParkingLotApi/BigScreen/ParkGroup', { params: params })
// 获取支付方式接口
const paymentMethod = () => axios.get('/ParkingLotApi/BigScreen/PaymentMethod')
// 获取支付订单
const getOrdeyinfo = () => axios.get('/ParkingLotApi/BigScreen/GetOrderRecordAndRankingList')
// 获取用户信息统计
const getStaticUser = () => axios.get('/ParkingLotApi/BigScreen/GetCarUsserAndPaymentMethod')
// 获取停车场前四条抓拍记录（路内停车场出入记录）
const getCurbParkRecord = (params) => axios.get('/ParkingLotApi/BigScreen/GetLatestEntryAndExitRecords', { params: params })
// 停车时长
const getParkingTime = () => axios.get('/ParkingLotApi/BigScreen/GetParkingTimeStatistics')
// 巡检人员
const getInspectors = () => axios.get('/ParkingLotApi/BigScreen/GetMapInspectorInformation')
// 组织人员
const getOrangeInformation = (params) => axios.get('/ParkingLotApi/BigScreen/GetMapOrangeInformation', { params: params })
// 视屏场景
const getSceneList = () => axios.get('/ParkingLotApi/MonitoringManager/GetSceneList')

export default {
  // getDataCount,
  cardStatistics,
  incomeStatistics,
  getParkingMap,
  recordList,
  getParkData,
  getDevices,
  trafficVolume,
  parkGroup,
  paymentMethod,
  getOrdeyinfo,
  getStaticUser,
  getCurbParkRecord,
  getParkingTime,
  getInspectors,
  getOrangeInformation,
  getSceneList
}
