<template>
  <div :class="['custom_big_screen_box', currentItem.screen_model === 1 ? 'default' : 'light']" id="parentBox">
    <!-- 自定义大屏页面 -->
    <!-- 地图版本 -->
    <ParkingMap :model="currentItem.screen_model" ref="ParkingMap" @closeBigScreen="closeBigScreen"
      :currentItem="currentItem" :isShowHeard="isShowHeard" v-if="currentItem.screen_type == 1" :key="currentItem._id"></ParkingMap>
    <!-- 报表版本 -->
    <div class="city-park-command-center-map-heard" v-if="currentItem.screen_type != 1 && (isFullScreen || isShowHeard)">
      <div class="left_weather_box">
      </div>
      <div class="heard-img-box">
        <span class="count">{{
          projectInfo && projectInfo.platform_name
          ? projectInfo.platform_name
          : "城市级停车指挥中心"
        }}</span>
      </div>
      <!-- 展示组件与退出全屏 -->
      <span class="icon_box">
        <!-- <i :class="`iconfont ${isShowModule ? 'lebo-hidden' : 'lebo-show'}`" @click="setShowModule(!isShowModule)"></i>
        <i class="iconfont lebo-guanbi" @click="closeBigScreen"></i> -->
        <span class="icon iconfont lebo-shezhi" @mouseover="showDropdownMenu=true" ></span>
        <div class="dropdownMenu" v-show="showDropdownMenu">
          <div class="dropdownMenuBox" >
            <ul @mouseleave="showDropdownMenu=false">
              <li :class="`icon iconfont ${isShowModule ? 'lebo-hidden' : 'lebo-show'}`" @click="setShowModule(!isShowModule)">
                {{isShowModule?'隐藏':'显示'}}图标
              </li>
              <li class="icon iconfont lebo-guanbi" @click="closeBigScreen">
                退出大屏
              </li>
            </ul>
          </div>
        </div>
      </span>
    </div>
    <div v-for="(item, index) in currentItem.children" :key="item.standard_id + index" :style="`top: ${item.location.top_disk * parentBox.yH + (isFullScreen || isShowHeard ? 80 : 0)}px;
          left:${item.location.left_disk * parentBox.wX}px;
          width:${item.location.wide_fill * parentBox.wX}px;
          height:${item.location.high_fill * parentBox.yH}px;`"
      :class="['custom_big_screen_item',isShowModule ? 'custom_big_screen_item-show' :  item.noNeedHide ? 'custom_big_screen_item-show' : 'custom_big_screen_item-hidden']">
      <customItem :model="currentItem.screen_model" :data="item" :index="index" :outBoxWidth="item.location.wide_fill * parentBox.wX" @toggleInParking="fntoggleInParking"
        @toggleOutParking="fntoggleOutParking" @toggleInspectorMarker="fntoggleInspectorMarker"
        @toggleScenceMarker="fntoggleScenceMarker" @toggleGroupMarker="fntoggleGroupMarker"
        ></customItem>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'

import ParkingMap from './components/parkingMap/index.vue'
// import ParkingMap from '@/components/bigScreen/customBigScreen/components/parkingMap.vue'
import customItem from '@/components/bigScreen/customBigScreen/components/customItem.vue'
export default {
  props: { data: { type: Object, default: null } },
  components: {
    ParkingMap,
    customItem
  },
  data () {
    return {
      isShowHeard: false,
      loading: true,
      currentItem: null,
      parentBox: { wX: 0, yH: 0 },
      showDropdownMenu: false // 下拉菜单
    }
  },
  created () {
    if (this.data) {
      // console.log('havedata', this.data)
      this.currentItem = this.data
      this.isShowHeard = true
    } else {
      var index = Number(this.$route.query.index)
      // console.log('nodata', this.screenCustomList[index])
      this.currentItem = this.screenCustomList[index]
      this.setBigScreenShowModule(this.screenCustomList[index].screen_type === 1)
      var that = this
      that.changeScreenStatus(false)
      that.setShowModule(true)
      window.onresize = function () {
        if (!that.checkFull()) {
          // 退出全屏后要执行的动作
          // console.log("退出全屏")
          that.changeScreenStatus(false)
        } else {
          that.changeScreenStatus(true)
        }
      }
    }
    this.currentItem.children.forEach(element => {
      // console.log(' this.currentItem.children', this.currentItem.children)
      if (element.block_enum === 1 || element.block_enum === 2 || element.block_enum === 3 || element.block_enum === 37 || element.block_enum === 38) {
        this.$set(element, 'noNeedHide', true)
      }
    })
    // console.log('this.currentItem.children', this.currentItem.children)
    // console.log('created---');
  },
  mounted () {
    this.setShowModule(true) // 打开重绘隐藏小组件功能
    // console.log('mounted---')
    this.$nextTick(() => {
      var parentBox = document.getElementById('parentBox')
      if (parentBox) {
        this.parentBox.wX = parentBox.clientWidth / 48
        this.parentBox.yH = parentBox.clientHeight / 24
        if (this.isShowHeard) {
          this.parentBox.yH = (parentBox.clientHeight - 80) / 24
        }
        var that = this
        window.addEventListener('resize', function () {
          that.parentBox.wX = parentBox.clientWidth / 48
          that.parentBox.yH = parentBox.clientHeight / 24
          if (that.isFullScreen || that.isShowHeard) {
            that.parentBox.yH = (parentBox.clientHeight) / 26
          }
        })
      }
      this.loading = false
    })
  },
  computed: {
    ...mapGetters('menuList', ['screenCustomList']),
    ...mapState('menuList', ['projectInfo']),
    ...mapState(['isFullScreen', 'isShowModule'])
  },
  watch: {
    data (val, old) {
      if (val) {
        this.currentItem = val
        this.isShowHeard = true
      }
    },
    '$route.query.index' (val, oldVal) {
      // console.log('watch-----', val)
      if (val) {
        this.$nextTick(() => {
          var index = Number(this.$route.query.index)
          this.currentItem = this.screenCustomList[index]
          // console.log('watchcurrentItem', this.screenCustomList[index])
          // 切换前去除背景地图标准件
          // const filterList = this.currentItem.children.filter(item => {
          //   return item.block_enum !== 28
          // })
          // this.currentItem.children = filterList
          this.setBigScreenShowModule(this.screenCustomList[index].screen_type === 1)
        })
      }
      // window.location.reload();
    }
  },
  methods: {
    ...mapMutations(['changeScreenStatus', 'setShowModule']),
    ...mapMutations('menuList', ['setBigScreenShowModule']),
    fntoggleInParking () {
      this.$refs.ParkingMap.toggleInParking()
    },
    fntoggleOutParking () {
      this.$refs.ParkingMap.toggleOutParking()
    },
    fntoggleInspectorMarker () {
      this.$refs.ParkingMap.toggleInspectorMarker()
    },
    fntoggleScenceMarker () {
      this.$refs.ParkingMap.toggleScenceMarker()
    },
    fntoggleGroupMarker () {
      this.$refs.ParkingMap.toggleGroupMarker()
    },
    // 判断浏览器是否处于全屏状态 （需要考虑兼容问题）
    checkFull () {
      // 判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      var isFull = document.mozFullScreen || document.fullScreen || document.webkitIsFullScreen || document.webkitRequestFullScreen || document.mozRequestFullScreen || document.msFullscreenEnabled
      if (isFull === undefined) {
        isFull = false
      }
      return isFull
    },
    // 退出全屏
    closeBigScreen () {
      this.showDropdownMenu = false // 关闭下拉框
      if (this.data) {
        this.isShowHeard = false
        return this.$emit('close')
      }
      this.changeScreenStatus(false)
      // var element = document.querySelector("#app");
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      } else {
        window.parent.showTopBottom()
      }
    },
    // 控制显示标准间，退出全屏下拉
    settingBoxCommand (command) {
      if (command === 'a') {
        this.setShowModule(!this.isShowModule)
      } else if (command === 'b') {
        this.closeBigScreen()
      }
    }
  }
}
</script>
<style scoped lang="less">
// @import url("./index.less");
@import url("~@/components/bigScreen/customBigScreen/index.less");
</style>
