<template>
  <!-- 现版本大屏 -->
  <div :class="[
    model == 1
      ? 'city-park-command-center-map'
      : 'city-park-command-center-map-light',
  ]">
    <div class="city-park-command-center-map-heard" v-if="isFullScreen || isShowHeard">
      <div class="left_weather_box" v-if="!currentItem.screen_3D_domain">
        <img class="weather_img" :src="require(`@/assets/images/${getweatherImg(
          cityInfo.weather
        )}.png`)
          " alt="" v-if="getweatherImg(cityInfo.weather)" />
        <span class="weather_box" v-if="cityInfo.temperature">
          <span class="title01">{{ cityInfo.weather }}</span>
          <span class="title02">{{ cityInfo.temperature }}℃</span>
        </span>
        <span class="city_box">
          <div class="icon iconfont lebo-weizhi"></div>
          <span class="title01">{{ cityInfo.city }}</span>
        </span>
      </div>
      <div class="heard-img-box">
        <span class="count">{{
          projectInfo && projectInfo.platform_name
          ? projectInfo.platform_name
          : "城市级停车指挥中心"
        }}</span>
      </div>
      <span class="time_box">
        <div class="yearMonthDayIcon"></div>
        <div class="icon iconfont lebo-riqi"></div>
        <span class="yearMonthDay">{{
          $moment(currrentDate).format("YYYY-MM-DD")
        }}</span>
        <div class="hourTimeIcon"></div>
        <div class="icon iconfont lebo-shijian"></div>
        <span class="hourTime">{{
          $moment(currrentDate).format("HH:mm:ss")
        }}</span>
      </span>
      <!-- 展示隐藏组件，退出全屏 -->
      <span class="icon_box">
        <!-- <i :class="`iconfont ${isShowModule ? 'lebo-hidden' : 'lebo-show'}`" @click="setShowModule(!isShowModule)"></i>
        <i class="iconfont lebo-guanbi" @click="closeBigScreen"></i> -->
        <span class="icon iconfont lebo-shezhi" @mouseover="showDropdownMenu = true"></span>
        <div class="dropdownMenu" v-show="showDropdownMenu">
          <div class="dropdownMenuBox">
            <ul @mouseleave="showDropdownMenu = false">
              <li :class="`iconfont ${isShowModule ? 'lebo-hidden' : 'lebo-show'}`" @click="setShowModule(!isShowModule)">
                {{ isShowModule ? '隐藏' : '显示' }}图标
              </li>
              <li class="iconfont lebo-guanbi" @click="closeBigScreen">
                退出大屏
              </li>
            </ul>
          </div>
        </div>
      </span>
      <!-- <span class="icon_box">
        <i :class="`iconfont ${isShowModule ? 'lebo-hidden' : 'lebo-show'}`" @click="setShowModule(!isShowModule)"
          v-if="!currentItem.screen_3D_domain"></i>
        <i class="iconfont lebo-guanbi" @click="closeBigScreen"></i>
      </span> -->
    </div>
    <!-- 大屏背景地图？ -->
    <div id="amapall" class="all-amap" v-show="!currentItem.screen_3D_domain"></div>
    <!-- 如果currentItem中有3D地址则背景图不显示，显示3D-->
    <div class="thress3Dbox" v-show="currentItem.screen_3D_domain">
      <iframe :src=currentItem.screen_3D_domain frameborder="0"></iframe>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import axios from 'axios'
import address from '@/utils/city'
import request from '@/api/bigScreenApi'
export default {
  props: {
    currentItem: { type: Object },
    model: { type: Number },
    isShowHeard: { type: Boolean, default: false }
  },
  data () {
    return {
      currrentDate: null,
      dateTimer: null,
      projectInfo: {}, // 当前用户数据信息（平台名称，经纬度等等）
      provinceList: [], // 省级数据
      cityList: [], // 市级数据
      districtList: [], // 区县数据
      mapNumber: {
        // 请求回来的数据
        provinceList: [], // 省级数据
        cityList: [], // 市级数据
        districtList: [] // 区县数据
      },
      outParkingList: [], // 路外停车场数据
      inParkingList: [], // 路内停车场数据
      inspectorList: [], // 巡检人员数据
      organizList: [], // 组织人员数据
      scenesList: [], // 监控场景数据
      isShowOutParkingMark: true,
      isShowInParkingMark: true,
      isShowInspectorMarker: true,
      isShowVideoMarker: true, // 场景点
      isShowOrganizMarker: true, // 组织点
      map: null, // 地图数据
      outParkingMarkerList: [], // 路外停车场图标
      inParkingMarkerList: [], // 路内停车场图标
      inspectorMarkerList: [], // 巡检人员图标
      videoMarkerList: [], // 场景图标
      organizMarkerList: [], // 组织图标
      // polygonsList: [], // 行政区绘制数据
      currentLevel: 0, // 行政区绘制数据的层级（1--省；2--市；3--区）
      cityCircleMarkerList: [],
      // provincePolygonList: [], // 省级行政区绘制信息
      // cityPolygonList: [], // 市级行政区绘制信息
      districtPolygonList: [], // 区县行政区绘制信息
      textMark: null,
      cityInfo: {
        city: null,
        temperature: null,
        weather: null
      },
      showMarks: true, // 是否添加展示mark点
      showDropdownMenu: false, // 下拉菜单
      provinceMarkList: [], // 所有标记点
      mapConfig: [] // 背景地图信息 1.路内停车场 2.路外停车场 3.巡检人员 4.视频监控 5.组织
    }
  },
  created () {
    // console.log('currentItem', this.currentItem)
    this.currentItem.children.forEach(item => {
      if (item.block_enum === 28) {
        this.mapConfig = item.select_data
      }
    })
    this.currrentDate = new Date()
    this.dateTimer = setInterval(() => {
      this.currrentDate = new Date()
    }, 60 * 1000)
    this.provinceList = this.deepClone(address.province_list)
    this.cityList = this.deepClone(address.city_list)
    this.districtList = this.deepClone(address.district_list)
    if (this.currentItem) {
      this.projectInfo.lng = this.currentItem.map_lng
      this.projectInfo.lat = this.currentItem.map_lat
      this.projectInfo.zoom = this.currentItem.map_zoom
      this.projectInfo.platform_name = this.currentItem.screen_name
      this.projectInfo.screen_model = this.currentItem.screen_model
    }
    this.setShowModule(true) // 打开重绘隐藏小组件功能
    this.requestAllData()
  },
  mounted () {
  },
  updated () { },
  computed: {
    ...mapState(['isFullScreen', 'isShowModule']),
    ...mapGetters('menuList', ['theme']),
    mapStyle () {
      var style = null
      if (this.model === 1) {
        style = 'amap://styles/e4500f6a8fc6db8c271dc0f1250134e3'
      } else {
        style = 'amap://styles/whitesmoke'
      }
      return style
    }
  },
  destroyed () {
    this.dateTimer && clearInterval(this.dateTimer)
    this.map && this.map.destroy()
  },
  watch: {
    currentItem (val, old) {
      if (val) {
        this.currrentDate = new Date()
        this.dateTimer = setInterval(() => {
          this.currrentDate = new Date()
        }, 60 * 1000)
        this.provinceList = this.deepClone(address.province_list)
        this.cityList = this.deepClone(address.city_list)
        this.districtList = this.deepClone(address.district_list)
        if (this.currentItem) {
          this.projectInfo.lng = this.currentItem.map_lng
          this.projectInfo.lat = this.currentItem.map_lat
          this.projectInfo.zoom = this.currentItem.map_zoom
          this.projectInfo.platform_name = this.currentItem.screen_name
          this.projectInfo.screen_model = this.currentItem.screen_model
        }
        this.requestAllData()
      }
    }
  },
  methods: {
    ...mapMutations(['setProjectInfo', 'setShowModule']),
    // 请求所有数据
    requestAllData () {
      // console.log('currentItem2', this.currentItem)
      // console.log('this.mapConfig', this.mapConfig)
      var that = this
      axios
        .all([
          this.fnGetParkingNum(1),
          this.fnGetParkingNum(2),
          this.fnGetParkingNum(3),
          this.fnGetParkingMap(),
          this.fnGetInspectors(),
          this.fnGetOrangeInformation(),
          this.fnGetSceneList()
        ])
        .then(
          axios.spread((province, city, district, park, inspectors, orangeInformation, sceneList) => {
            // console.log('获取当前用户的经纬度接口-----', project);
            this.handleParkingNumber(province, city, district, inspectors)
            if (
              park &&
              park.Code === 200 &&
              park.Data &&
              park.Data.length > 0
            ) {
              // console.log('park-------------', park);
              // eslint-disable-next-line camelcase
              const { parking_lotin = [], parking_lotout = [] } = park.Data[0]
              if (this.mapConfig.indexOf('2') === -1) {
                this.outParkingList = []
              } else {
                // eslint-disable-next-line camelcase
                this.outParkingList = parking_lotout
              }
              if (this.mapConfig.indexOf('1') === -1) {
                this.inParkingList = []
              } else {
                // eslint-disable-next-line camelcase
                this.inParkingList = parking_lotin
              }
            }
            if (orangeInformation && orangeInformation.Code === 200) {
              this.organizList = orangeInformation.Data ? orangeInformation.Data : []
            }
            if (sceneList && sceneList.Code === 200) {
              this.scenesList = sceneList.Data ? sceneList.Data : []
            }
            this.$nextTick(() => {
              this.initMap()
            })
          })
        )
    },
    // 获取省市区停车场个数并处理
    async fnGetParkingNum (type) {
      // 获取省市区停车场个数  1:省份 2:市 3:区
      return await request.parkGroup({ type: type })
    },
    // 处理数据
    handleParkingNumber (province, city, district, inspectors) {
      if (province && province.Code === 200) {
        // console.log('province', province)
        this.mapNumber.provinceList = province.Data
        this.provinceList = this.deepClone(address.province_list).map(
          (item) => {
            var sameitem = province.Data.find((obj) => obj.code === item.code)
            if (sameitem) {
              item.count = sameitem.count
              item.orange_count = sameitem.orange_count
            }
            return item
          }
        )
      }
      if (city && city.Code === 200) {
        // console.log('city', city.Data)
        // console.log(' this.cityList', this.cityList)
        this.mapNumber.cityList = city.Data
        this.cityList = this.deepClone(address.city_list).map((item) => {
          var sameitem = city.Data.find((obj) => obj.code === item.code)
          if (sameitem) {
            item.count = sameitem.count
            item.orange_count = sameitem.orange_count
            item.person_count = sameitem.person_count
          }
          return item
        })
      }
      if (district && district.Code === 200) {
        this.mapNumber.districtList = district.Data
        this.districtList = this.deepClone(address.district_list).map(
          (item) => {
            var sameitem = district.Data.find((obj) => obj.code === item.code)
            if (sameitem) {
              item.count = sameitem.count
            }
            return item
          }
        )
      }
    },
    // 处理省份数组添加人员数据
    arrayDeduplication (arr) {
      var newArr = []
      arr.forEach(item => {
        var dataItem = item
        if (newArr.length > 0) {
          var filterValue = newArr.filter(v => {
            return v.code === dataItem.code
          })
          if (filterValue.length > 0) {
            newArr.forEach(n => {
              if (n.code === filterValue[0].code) {
                n.inspectors_count = dataItem.inspectors_count
              }
            })
          } else {
            newArr.push(dataItem)
          }
        } else {
          newArr.push(dataItem)
        }
      })
      return newArr
    },
    // 获取停车场信息接口
    async fnGetParkingMap () {
      return await request.getParkingMap({ isDetail: true })
    },
    // 获取巡检人员信息接口
    async fnGetInspectors () {
      if (this.mapConfig.indexOf('3') === -1) return
      return await request.getInspectors()
    },
    // 获取组织人员
    async fnGetOrangeInformation () {
      if (this.mapConfig.indexOf('5') === -1) return
      return await request.getOrangeInformation({ isDetail: true })
    },
    // 获取监控场景
    async fnGetSceneList () {
      if (this.mapConfig.indexOf('4') === -1) return
      return await request.getSceneList()
    },
    // 初始化地图
    initMap () {
      const that = this
      lazyAMapApiLoaderInstance.load().then(() => {
        this.map = new AMap.Map('amapall', {
          pitch: 0, // 地图俯仰角度，有效范围 0 度- 83 度
          resizeEnable: true,
          rotateEnable: true,
          pitchEnable: true,
          zoom: 3.5,
          rotation: 0,
          viewMode: '3D', // 开启3D视图,默认为关闭
          buildingAnimation: true, // 楼块出现是否带动画
          expandZoomRange: true,
          zooms: [3, 18],
          mapStyle: this.mapStyle,
          // ,"point"
          features: ['bg', 'road', 'building']
        })
        // 设置地图中心点
        if (this.projectInfo && this.projectInfo.lng && this.projectInfo.lat) {
          this.map.setCenter([this.projectInfo.lng, this.projectInfo.lat])
        }
        // 设置地图层级
        if (this.projectInfo && this.projectInfo.zoom) {
          this.map.setZoom(
            this.projectInfo.zoom < 3 ? 3 : this.projectInfo.zoom
          )
          this.getZoomData(this.projectInfo.zoom)
        }
        this.mapZoomHandle() // 地图缩放
        AMap.event.addListener(this.map, 'click', function (e) {
          // 添加点击事件,传入对象名，事件名，回调函数
          // console.log(e);
          this.map.clearInfoWindow()
        })
        AMap.event.addListener(this.map, 'complete', function () {
          // 添加地图加载完成，回调函数
          // console.log("amap complete");
        })
        this.getWeatherCity() // 获取当前城市天气
      })
    },
    // 获取当前城市天气
    getWeatherCity () {
      // 加载天气查询插件
      var that = this
      var cityInfo = {
        city: null,
        temperature: null,
        weather: null
      }
      AMap.plugin('AMap.Weather', function () {
        // 创建天气查询实例
        var weather = new AMap.Weather()
        that.map.getCity(function (info) {
          // console.log('info-----', info);
          cityInfo.city = info.province + info.city
          // 执行实时天气信息查询
          weather.getLive(info.city, function (err, data) {
            // console.log(err);
            // console.log(data);
            const t = err
            // console.log(data);
            if (data && data.info === 'OK') {
              cityInfo.weather = data.weather
              cityInfo.temperature = data.temperature
            }
          })
        })
      })
      that.cityInfo = cityInfo
    },
    // 加载行政区划插件
    drawBounds (level) {
      this.textMark && this.map.remove(this.textMark)
      var district = null
      var that = this
      // 加载行政区划插件
      if (!district) {
        // 实例化DistrictSearch
        var opts = {
          extensions: 'all', // 返回行政区边界坐标组等具体信息
          // 关键字对应的行政区级别，country表示国家
          level: level,
          //  显示下级行政区级数，1表示返回下一级行政区
          subdistrict: 0
        }
        district = new AMap.DistrictSearch(opts)
      }
      // 行政区查询
      district.setLevel(level)
      this.mapNumber.districtList.forEach((item) => {
        district.search(item.code, function (status, result) {
          if (status === 'complete') {
            var bounds = result.districtList[0].boundaries
            // console.log(result.districtList[0].center);
            if (bounds) {
              for (var i = 0, l = bounds.length; i < l; i++) {
                // 生成行政区划polygon
                var polygon = new AMap.Polygon({
                  strokeWeight: 1,
                  path: bounds[i],
                  fillOpacity: 0.35,
                  fillColor: that.setFillColor(item.count),
                  strokeColor: '#fff'
                })
                // 点击行政区
                AMap.event.addListener(polygon, 'click', function (e) {
                  // 添加点击事件,传入对象名，事件名，回调函数
                  // console.log('polygon-+-----', e);
                  that.map.setCenter(e.lnglat)
                  // if (that.map.getZoom() <= 7) {
                  //   that.map.setZoom(8);
                  // } else if (that.map.getZoom() < 10) {
                  //   that.map.setZoom(10);
                  // } else {
                  //   that.map.setZoom(12);
                  // }
                })
                // 鼠标经过行政区
                AMap.event.addListener(polygon, 'mouseover', function (e) {
                  // console.log('polygon-+--mouseover---', e);
                  polygon.setOptions({
                    strokeWeight: 2,
                    strokeColor: '#fcb12d'
                  })
                  that.textMark = new AMap.Text({
                    position: result.districtList[0].center,
                    text: `${item.count}个<br />${result.districtList[0].name}`,
                    map: that.map,
                    anchor: 'center'
                  })
                })
                // 鼠标离开行政区
                AMap.event.addListener(polygon, 'mouseout', function (e) {
                  // console.log('polygon-+--mouseout---', e);
                  polygon.setOptions({
                    strokeWeight: 1,
                    strokeColor: '#fff'
                  })
                  that.map.remove(that.textMark)
                })
                // that.map.setCenter(result.districtList[0].center);
                that.districtPolygonList.push(polygon)
              }
            }
            // console.log('polygonList-------', that.districtPolygonList);
            that.map.add(that.districtPolygonList)
          }
        })
      })
    },
    // 加载省市级数据
    drawCityMark () {
      this.textMark && this.map.remove(this.textMark)
      this.cityCircleMarkerList = []
      if (this.showMarks) {
        this.provinceMarkList = []
        this.cityList.forEach((item) => {
          if (item.count || item.orange_count || item.person_count) {
            this.createCityMark(item)
          }
        })
        this.map.add(this.provinceMarkList)
        // console.log('!!!this.provinceMarkList', this.provinceMarkList)
      }
    },
    // 创建地图标点
    createCityMark (item) {
      this.showMarks = false
      // console.log('创建地图标点', item)
      // console.log('currentItem2', this.currentItem)
      // console.log('this.mapConfig', this.mapConfig)
      var that = this
      var center = new AMap.LngLat(item.lng, item.lat)
      // var circleMarker = new AMap.CircleMarker({
      //   center: center,
      //   radius: this.setRadius(item.count), // 3D视图下，CircleMarker半径不要超过64px
      //   strokeColor: '#fff',
      //   strokeWeight: 1,
      //   strokeOpacity: 0.1,
      //   fillColor: this.setFillColor(item.count),
      //   fillOpacity: 0.7,
      //   zIndex: 10,
      //   bubble: true,
      //   cursor: 'pointer',
      //   clickable: true
      // })
      // 绘制波动点
      var circleMarker = new AMap.Marker({
        position: center,
        content: `<div class="movebox">
          <div
        class="circle-marker-content"
        style='
          height:${this.setRadius(item.count)}px;
          width:${this.setRadius(item.count)}px;
          '
        >
          <div class="item item2" style='
          height:${this.setRadius(item.count) + 5}px;
          width:${this.setRadius(item.count) + 5}px;
          '></div>
          <div class="item item3" style='
          height:${this.setRadius(item.count) + 8}px;
          width:${this.setRadius(item.count) + 8}px;
          '></div>
          </div>`,
        offset: new AMap.Pixel(0, 4)
      })
      // 窗体信息
      var infoWindow = new AMap.InfoWindow({
        isCustom: true, // 使用自定义窗体
        autoMove: true,
        closeWhenClickMap: true,
        offset: new AMap.Pixel(-20, -75), // 偏移量
        anchor: 'top-right',
        content: `<div class="infowindow_add_box" style="border-color:${this.setFillColor(
          item.count
        )}"> <div>
            <span class="info_title">${item.name}</span>
            </div>
            <div>
            <div class="info_count">车场：${item.count}</div>
            <div class="info_count">组织：${item.orange_count}</div>
            <div class="info_count">人员：${item.person_count}</div>
            </div>
        </div>` // 使用默认信息窗体框样式，显示信息内容
      })
      // 监听marker的点击事件
      AMap.event.addListener(circleMarker, 'click', function (e) {
        that.map.setCenter(new AMap.LngLat(item.lng, item.lat))
        that.map.setZoom(12)
      })
      // 鼠标移近点标记时触发事件
      AMap.event.addListener(circleMarker, 'mouseover', function (e) {
        infoWindow.open(
          that.map,
          // 窗口信息的位置
          // circleMarker.getCenter(item.lng, item.lat)
          circleMarker.getPosition(item.lng, item.lat)
        )
      })
      // 鼠标移出点标记时触发事件
      AMap.event.addListener(circleMarker, 'mouseout', function (e) {
        that.map.clearInfoWindow()
      })
      this.provinceMarkList.push(circleMarker)
      // circleMarker.setMap(this.map)
      // this.map.add(circleMarker)
      // var that = this;
      // var circle = new AMap.Marker({
      //   position: new AMap.LngLat(item.lng, item.lat), // 圆心位置
      //   offset: new AMap.Pixel(-11, -11),
      //   // offset: 'center',
      //   content: `<div class="${this.setCircleClass(item.count)}"></div>`,
      // });
      // // 监听marker的点击事件
      // AMap.event.addListener(circle, "click", function (e) {
      //   that.map.setCenter(new AMap.LngLat(item.lng, item.lat));
      //   that.map.setZoom(12);
      // });
      // // 窗体信息
      // var infoWindow = new AMap.InfoWindow({
      //   isCustom: true, // 使用自定义窗体
      //   autoMove: true,
      //   closeWhenClickMap: true,
      //   anchor: "top-right",
      //   content: `<div class="infowindow_add_box" style="border-color:${this.setFillColor(
      //     item.count
      //   )}">
      //       <span class="info_title">${item.name}</span>
      //       <span class="info_count">${item.count}</span>
      //   </div>`, // 使用默认信息窗体框样式，显示信息内容
      // });
      // // 鼠标移近点标记时触发事件
      // AMap.event.addListener(circle, "mouseover", function (e) {
      //   infoWindow.open(
      //     that.map,
      //     // 窗口信息的位置
      //     circle.getPosition(item.lng, item.lat)
      //   );
      // });
      // // 鼠标移出点标记时触发事件
      // AMap.event.addListener(circle, "mouseout", function (e) {
      //   that.map.clearInfoWindow();
      // });
      // this.cityCircleMarkerList.push(circle);
      // this.map.add(circle);
    },
    setCircleClass (count) {
      if (count <= 0) {
        return ''
      } else if (count <= 10) {
        return 'marker_city_box3'
      } else if (count <= 20) {
        return 'marker_city_box2'
      } else {
        return 'marker_city_box1'
      }
    },
    setRadius (count) {
      if (count <= 0) {
        return 0
      } else if (count <= 10) {
        return 5
      } else if (count <= 20) {
        return 8
      } else {
        return 12
      }
    },
    setFillColor (count) {
      // console.log('setFillColor----', this.model);
      if (this.model === 1) {
        if (count <= 0) {
          return ''
        } else if (count <= 10) {
          return '#55a1ff'
        } else if (count <= 20) {
          return '#0071db'
        } else {
          return '#003a9c'
        }
      } else {
        if (count <= 0) {
          return ''
        } else if (count <= 10) {
          return '#8cc5ff'
        } else if (count <= 20) {
          return '#53a8ff'
        } else {
          return '#01A5FB'
        }
      }
    },
    // 地图缩放，数据处理
    mapZoomHandle () {
      // 添加监听事件，当前缩放级别
      var that = this
      AMap.event.addListener(this.map, 'zoomend', function () {
        var zoom = that.map.getZoom()
        that.getZoomData(zoom)
        that.map.clearInfoWindow()
      })
    },
    // 根据地图缩放zoom来展示不同数据
    getZoomData (zoom) {
      // provincePolygonList: [], // 省级行政区绘制信息
      // cityPolygonList: [], // 市级行政区绘制信息
      // districtPolygonList: [], // 区县行政区绘制信息
      // console.log('getZoomData-----', zoom)
      this.clearMark()
      if (zoom < 11) {
        // 省级和市级
        this.clearMark()
        this.drawCityMark()
        if (this.currentLevel !== 2) {
          this.currentLevel = 2
          this.districtPolygonList.forEach((item) => item.hide())
          // console.log('---drawCityMark--')
          // this.drawCityMark();
          // if (this.cityCircleMarkerList.length <= 0) {
          //   this.drawCityMark();
          // } else {
          //   this.cityCircleMarkerList.forEach((item) => item.show());
          // }
        }
      } else if (zoom >= 12) {
        // 去除省级地图标点 -缩小时恢复
        this.map.remove(this.provinceMarkList)
        this.provinceMarkList = []
        this.showMarks = true
        if (this.currentLevel !== 3) {
          this.currentLevel = 3
          this.cityCircleMarkerList.forEach((item) => item.hide())
          // this.cityCircleMarkerList = []
          if (this.districtPolygonList.length <= 0) {
            // this.drawBounds('district')  取消展示地区边界
          } else {
            this.districtPolygonList.forEach((item) => item.show())
          }
        }
        // console.log(this.isShowInParkingMark)
        // 绘制路内 路外 巡检人员点
        if (this.isShowInParkingMark && this.inParkingMarkerList.length <= 0) { this.initMarker(this.inParkingList, this.isShowOutParkingMark, 1) }
        if (this.isShowOutParkingMark && this.outParkingMarkerList.length <= 0) { this.initMarker(this.outParkingList, this.isShowInParkingMark, 2) }
        if (this.isShowInspectorMarker && this.inspectorMarkerList.length <= 0) { this.initMarker(this.inspectorList, this.isShowInspectorMarker, 3) }
        if (this.isShowVideoMarker && this.videoMarkerList.length <= 0) { this.initMarker(this.scenesList, this.isShowVideoMarker, 4) }
        if (this.isShowOrganizMarker && this.organizMarkerList.length <= 0) { this.initMarker(this.organizList, this.isShowOrganizMarker, 5) }
      }
      // if (zoom < 10) { // 省级和市级
      //   if (this.currentLevel !== 2) {
      //     this.currentLevel = 2;
      //     this.provincePolygonList.forEach(item => item.hide());
      //     this.districtPolygonList.forEach(item => item.hide());
      //     if (this.cityPolygonList.length <= 0) {
      //       this.drawBounds('city', 2, this.mapNumber.cityList, this.cityPolygonList);
      //     } else {
      //       this.cityPolygonList.forEach(item => item.show());
      //     }
      //   }
      // } else if (zoom < 12) { // 区级
      //   this.clearMark();
      //   if (this.currentLevel !== 3) {
      //     this.currentLevel = 3;
      //     this.provincePolygonList.forEach(item => item.hide());
      //     this.cityPolygonList.forEach(item => item.hide());
      //     if (this.districtPolygonList.length <= 0) {
      //       this.drawBounds('district', 3, this.mapNumber.districtList, this.districtPolygonList);
      //     } else {
      //       this.districtPolygonList.forEach(item => item.show());
      //     }
      //   }
      // } else if (zoom >= 13) { // 区级

      // }
      // // 关闭信息窗体
      this.map.clearInfoWindow()
    },
    // 设置停车场、巡检人员icon  1 -- 路内；2-路外；3-巡检人员；4-场景; 5-组织
    initMarker (list, flag, type) {
      if (flag) {
        // type  1 -- 路内；2-路外；3-巡检人员；4-场景; 5-组织
        if (type === 1) {
          this.map.remove(this.inParkingMarkerList)
          this.inParkingMarkerList = []
          list.forEach((data) => {
            if (data.longitude && data.latitude) {
              this.inParkingMarkerList.push(this.createMarkInfo(data, type))
            }
          })
        } else if (type === 2) {
          this.map.remove(this.outParkingMarkerList)
          this.outParkingMarkerList = []
          list.forEach((data) => {
            if (data.longitude && data.latitude) {
              this.outParkingMarkerList.push(this.createMarkInfo(data, type))
            }
          })
        } else if (type === 3) {
          this.map.remove(this.inspectorMarkerList)
          this.inspectorMarkerList = []
          list.forEach((data) => {
            if (data.longitude && data.latitude) {
              this.inspectorMarkerList.push(this.createMarkInfo(data, type))
            }
          })
        } else if (type === 4) {
          this.map.remove(this.videoMarkerList)
          this.videoMarkerList = []
          list.forEach((data) => {
            if (data.longitude && data.latitude) {
              this.videoMarkerList.push(this.createMarkInfo(data, type))
            }
          })
        } else if (type === 5) {
          this.map.remove(this.organizMarkerList)
          this.organizMarkerList = []
          list.forEach((data) => {
            if (data.longitude && data.latitude) {
              this.organizMarkerList.push(this.createMarkInfo(data, type))
            }
          })
        }
      }
    },
    createMarkInfo (data, type) {
      var that = this
      var marker = new AMap.Marker({
        position: [data.longitude, data.latitude],
        content: contentMarker(type),
        map: this.map,
        offset: new AMap.Pixel(-13, -30)
      })
      // 窗体信息
      var infoWindow = new AMap.InfoWindow({
        isCustom: true, // 使用自定义窗体
        autoMove: true,
        closeWhenClickMap: true,
        anchor: 'top-right',
        content: contentInfoWindow(type), // 使用默认信息窗体框样式，显示信息内容
        offset: new AMap.Pixel(0, 15)
      })
      // 监听marker的点击事件
      AMap.event.addListener(marker, 'mouseover', function (e) {
        infoWindow.open(
          that.map,
          // 窗口信息的位置
          marker.getPosition(data.longitude, data.latitude)
        )
      })
      AMap.event.addListener(marker, 'mouseout', function (e) {
        // console.log('marker-----mouseou-', e);
        that.map.clearInfoWindow()
      })
      function contentInfoWindow (type) {
        switch (type) {
          case 1:
          case 2:
            return `<div class="infowindow_park_box">
              <div class="leftIcon ${data.status === 1 ? 'leftIconOpen' : 'leftIconClose'}"></div>
              <div class="park_name_box">
                ${data.parking_name}
                <div class="park_name_box_font_size">
                  ${data.position}
                </div>
              </div>
              <span class="status_box ${data.status === 1 ? 'openingPark' : 'closedPark'}">${data.status === 1 ? '运营中' : '已停运'}</span>
              <div class="${data.status === 1 ? 'count_box' : 'count_box count_box_close'
              }">
                <div class="count_item">
                    <span class="count_span">${data.berth_num}</span>
                    <span class="title_span">车位</span>
                </div >
                <div class="count_item">
                  <span class="count_span">${data.carflow_number}</span>
                  <span class="title_span">车流量</span>
               </div>
                <div class="count_item">
                  <span class="count_span">${data.order_number}</span>
                  <span class="title_span">订单</span>
               </div>
                <div class="count_item">
                  <span class="count_span">${data.totalIncome}</span>
                  <span class="title_span">收入</span>
               </div>
              </div >
          </div > `
          // 巡检人员
          case 3:
            return ` <div class="infowindow_user_box">
              <div class="left_img">
               <img src="${data.head_photo}"></img>
              </div>
              <div class="right_box">
                <span class="row_box">
                  <i class="iconfont lebo-xjrxm"></i>
                  <span>${data.user_name}</span>
                </span>
                 <span class="row_box">
                  <i class="iconfont lebo-dianhua"></i>
                  <span>${data.phone}</span>
                </span>
              </div>
          </div >`
          // 视屏监控
          case 4:
            return `<div class="infowindow_park_box">
              <div class="leftIcon  leftIconOpen"></div>
              <div class="park_name_box">
                ${data.scene_name}
                <div class="park_name_box_font_size">
                  ${data.position}
                </div>
              </div>
              <span class="checkVideo">查看</span>
              <div class="count_box_large">
                <div class="count_item_large">
                    <span class="count_span">${data.monitor_count}</span>
                    <span class="title_span">视频监控设备</span>
                </div >
              </div >
          </div > `
          // 组织人员
          case 5:
            return `<div class="infowindow_park_box">
              <div class="leftIcon  leftIconOpen"></div>
              <div class="park_name_box">
                ${data.organize_name}
                <div class="park_name_box_font_size">
                  ${data.addres}
                </div>
              </div>
              <div class="count_box_large">
                <div class="count_item_large">
                    <span class="count_span">${data.person_count}</span>
                    <span class="title_span">组织成员</span>
                </div >
                <div class="count_item_large">
                    <span class="count_span">${data.visitor_count}</span>
                    <span class="title_span">访客人员</span>
                </div >
              </div >
          </div > `
        }
      }
      // 设置marker样式
      function contentMarker (type) {
        switch (type) {
          case 1:
            return ` <div class="marker__box" >
              <div class="${data.status === 1
                ? 'openMark'
                : 'closeMark'
              }">
              <div class="${data.status === 1 ? 'mark_move' : ''}"></div>
                <i class="iconfont lebo-lntcq"></i>
                </div>
                </div>`
          case 2:
            return ` <div class="marker__box" >
              <div class="${data.status === 1
                ? 'openMark'
                : 'closeMark'
              }">
              <div class="${data.status === 1 ? 'mark_move' : ''}"></div>
                <i class="iconfont lebo-lwtcc"></i>
                </div>
              </div>`
          case 3:
            return ` <div class="marker__box" >
              <div class="defaultMark">
                <div class= 'mark_move'"></div>
                <i class="iconfont lebo-xjry"></i>
                </div>
              </div >`
          case 4:
            return ` <div class="marker__box" >
              <div class="defaultMark">
                <div class= 'mark_move'"></div>
                <i class="iconfont lebo-shipinjiankong"></i>
                </div>
              </div >`
          case 5:
            return `<div class="marker__box" >
              <div class="defaultMark">
                <div class= 'mark_move'"></div>
                <i class="iconfont lebo-qiye"></i>
                </div>
              </div >`
        }
      }
      return marker
    },
    // 清除mark
    clearMark () {
      this.outParkingMarkerList && this.map.remove(this.outParkingMarkerList)
      this.outParkingMarkerList = []
      this.inParkingMarkerList && this.map.remove(this.inParkingMarkerList)
      this.inParkingMarkerList = []
      this.inspectorMarkerList && this.map.remove(this.inspectorMarkerList)
      this.inspectorMarkerList = []
      this.videoMarkerList && this.map.remove(this.videoMarkerList)
      this.videoMarkerList = []
      this.organizMarkerList && this.map.remove(this.organizMarkerList)
      this.organizMarkerList = []
    },
    // 获取天气图片
    getweatherImg (string) {
      switch (string) {
        case '晴':
          return 'qing'
        case '少云':
        case '晴间多云':
        case '多云':
          return 'duoyun'
        case '阴':
          return 'ying'
        case '有风':
        case '平静':
        case '微风':
        case '和风':
        case '清风':
        case '强风/劲风':
        case '强风':
        case '劲风':
        case '疾风':
        case '大风':
        case '烈风':
        case '风暴':
        case '狂爆风':
        case '飓风':
        case '龙卷风':
        case '热带风暴':
          return 'feng'
        case '霾':
        case '中度霾':
        case '重度霾':
        case '严重霾':
          return 'ai'
        case '雷阵雨':
        case '雷阵雨并伴有冰雹':
          return 'leiyu'
        case '毛毛雨 / 细雨':
        case '毛毛雨':
        case '细雨':
        case '小雨':
          return 'xiaoyu'
        case '雨':
        case '小雨-中雨':
        case '中雨-大雨':
        case '阵雨':
        case '中雨':
          return 'zhongyu'
        case '大雨-暴雨':
        case '暴雨 - 大暴雨':
        case '大暴雨 - 特大暴雨':
        case '大雨':
        case '暴雨':
        case '大暴雨':
        case '特大暴雨':
        case '强阵雨':
        case '强雷阵雨':
        case '极端降雨':
          return 'dayu'
        case '雨雪天气':
        case '雨夹雪':
        case '阵雨夹雪':
        case '冻雨':
          return 'yujiaxue'
        case '雪':
        case '阵雪':
        case '小雪-中雪':
        case '小雪':
          return 'xiaoxue'
        case '中雪-大雪':
        case '中雪':
          return 'zhongxue'
        case '大雪':
        case '大雪-暴雪':
        case '暴雪':
          return 'daxue'
        case '浮尘':
        case '扬沙':
        case '沙尘暴':
        case '强沙尘暴':
          return 'shachen'
        case '雾':
        case '浓雾':
        case '强浓雾':
        case '轻雾':
        case '大雾':
        case '特强浓雾':
          return 'wu'
        default:
          return null
      }
    },
    // 关闭大屏幕
    closeBigScreen () {
      this.showDropdownMenu = false
      this.$emit('closeBigScreen')
    },
    // 切换路内停车场mark
    toggleInParking () {
      this.isShowInParkingMark = !this.isShowInParkingMark
      if (this.currentLevel !== 3) return
      if (this.isShowInParkingMark) {
        this.initMarker(this.inParkingList, true, 1)
      } else {
        this.map.remove(this.inParkingMarkerList)
        this.map.clearInfoWindow()
      }
    },
    // 切换路外停车场mark
    toggleOutParking () {
      this.isShowOutParkingMark = !this.isShowOutParkingMark
      if (this.currentLevel !== 3) return
      if (this.isShowOutParkingMark) {
        this.initMarker(this.outParkingList, true, 2)
      } else {
        this.map.remove(this.outParkingMarkerList)
        this.map.clearInfoWindow()
      }
    },
    // 切换巡检人员mark
    toggleInspectorMarker () {
      this.isShowInspectorMarker = !this.isShowInspectorMarker
      if (this.currentLevel !== 3) return
      if (this.isShowInspectorMarker) {
        this.initMarker(this.inspectorList, true, 3)
      } else {
        this.map.remove(this.inspectorMarkerList)
        this.map.clearInfoWindow()
      }
    },
    // 切换场景
    toggleScenceMarker () {
      this.isShowVideoMarker = !this.isShowVideoMarker
      if (this.currentLevel !== 3) return
      if (this.isShowVideoMarker) {
        this.initMarker(this.scenesList, true, 4)
      } else {
        this.map.remove(this.videoMarkerList)
        this.map.clearInfoWindow()
      }
    },
    // 切换组织
    toggleGroupMarker () {
      this.isShowOrganizMarker = !this.isShowOrganizMarker
      if (this.currentLevel !== 3) return
      if (this.isShowOrganizMarker) {
        this.initMarker(this.organizList, true, 5)
      } else {
        this.map.remove(this.organizMarkerList)
        this.map.clearInfoWindow()
      }
    }
  }
}
</script>
<style scoped lang="less">
@keyframes alarmDeviceBreath {
  0% {
    margin-left: 0;
    margin-top: 0;
    width: 30px;
    height: 30px;
    box-shadow: 0px 0px 15px #f61212;
    opacity: 1.2;
  }

  100% {
    margin-left: 5px;
    margin-top: 5px;
    width: 20px;
    height: 20px;
    box-shadow: 0px 0px 10px #f61212;
    opacity: 0;
  }
}

// 深色
.city-park-command-center-map {
  width: 100%;
  height: 100%;
  position: relative;

  /deep/ .city-park-command-center-map-heard {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 70px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    .left_weather_box {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      align-items: center;

      .weather_img {
        height: 45px;
      }

      .city_box,
      .weather_box {
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-left: 12px;

        .iconfont {
          font-size: 26px;
          line-height: 26px;
          font-weight: 400;
          margin-right: 10px;
          background-image: -webkit-linear-gradient(90deg, #FFE900 40%, #fff 60%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .title01 {
          // color: @homeTextColor;
          color: #fff;
          font-size: 24px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
        }

        .title02 {
          // color: @homeTextColor;
          color: #fff;
          font-size: 24px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          margin-right: 50px;
        }
      }
    }

    .heard-img-box {
      width: 60%;
      height: 100%;
      // background-image: url("../../../../assets/images/map-big.png");

      background-image: url("~@/assets/images/map-big-dark2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;

      .count {
        font-size: 48px;
      }
    }

    .time_box {
      position: absolute;
      top: 50%;
      right: 200px;
      transform: translate(0, -50%);
      display: flex;
      align-items: center;

      .iconfont {
        font-size: 26px;
        line-height: 26px;
        font-weight: 400;
        margin-right: 10px;
        background-image: -webkit-linear-gradient(90deg, #FFE900 40%, #fff 60%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .yearMonthDay {
        // color: @homeTextColor;
        color: #fff;
        font-size: 24px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        margin-right: 50px;
      }

      .hourTime {
        // color: @homeTextColor;
        color: #fff;
        font-size: 24px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
      }
    }

    // 标准间展示与隐藏，退出全屏下拉框
    .icon_box {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(0, -50%);

      .iconfont {
        cursor: pointer;
        font-size: 26px;
        line-height: 26px;
        font-weight: 400;
        margin-right: 10px;
        background-image: -webkit-linear-gradient(90deg, #FFE900 40%, #fff 60%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .dropdownMenu {
        position: absolute;
        width: 120px;
        background-color: rgba(6, 29, 92, .8);
        border: 1px solid #01ADF8;
        box-shadow: inset 0px 0px 5px 0px #0079FF, inset 0px 0px 5px 0px #00BAFF;
        top: 95%;
        right: 5px;
        box-sizing: border-box;

        ul {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          box-sizing: border-box;

          li {
            margin: 10px 0;
            padding: 5px;
            box-sizing: border-box;

            &:hover {
              background-color: rgba(5, 45, 121, .9);
              // border: 1px solid #02A2D0;
              box-shadow: inset 0px 0px 4px 0px #0079FF, inset 0px 0px 4px 0px #00BAFF;
            }
          }
        }

        .iconfont {
          background-image: -webkit-linear-gradient(90deg, #fff 40%, #fff 60%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 15px;
          cursor: pointer;

          &::before {
            margin-right: 8px;
          }
        }
      }
    }
  }

  /deep/ .map-right-info {
    position: absolute;
    z-index: 9999;
  }

  #amapall {
    width: 100%;
    height: 100%;

    /deep/.amap-marker {
      .amap-marker-content {
        .movebox {
          position: relative;

          .circle-marker-content {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 10px;
            width: 10px;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            text-align: center;
            background: #25F4A6;
            border: 1px solid #25F4A6;

            // box-shadow: 0px 0px 5px #25F4A6;
            @keyframes scaless {
              0% {
                transform: scale(0);
                opacity: 0.5;
              }

              50% {
                transform: scale(0.5);
                opacity: 0.2;
              }

              100% {
                transform: scale(1);
                opacity: 0;
              }
            }

            .item {
              width: 100%;
              height: 100%;
              position: absolute;
              border-radius: 100%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            .item:before {
              content: "";
              position: absolute;
              left: 0px;
              top: 0px;
              display: inline-block;
              width: 100%;
              height: 100%;
              // border: 1px solid #25F4A6;
              border-radius: 100%;
              opacity: 0;
              background-color: #25F4A6;
              animation: scaless 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
            }

            .item1:before {
              animation-delay: 0s;
            }

            .item2:before {
              animation-delay: 1s;
            }

            .item3:before {
              animation-delay: 2s;
            }

            .item4:before {
              animation-delay: 3s;
            }

            .item5::before {
              animation-delay: 4s;
            }
          }
        }
      }
    }

    /deep/ .amap-overlay-text-container {
      font-size: 15px;
      color: #fcb12d;
      font-weight: 700;
      background: none;
      border: none;
    }

    /deep/ .marker__box {
      width: 45px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      border-radius: 50%;
      white-space: normal;
      position: relative;

      &:hover {
        .openMark {
          transform: scale(1.2);
          transition: all .5s;
        }
      }

      .mark_move {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        box-sizing: border-box;
        border: 2.5px solid #fff;
        box-shadow: 0 0 5px 1px #fff inset;
        border-radius: 50%;
        animation: markMove 1.5s linear;
        animation-iteration-count: infinite;
      }

      // border: 1px solid #fff;
      .openMark {
        width: 100%;
        height: 100%;
        background-image: url('~@/assets/images/openMark01.png');
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .closeMark {
        width: 100%;
        height: 100%;
        background-image: url('~@/assets/images/closeMark01.png');
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .defaultMark {
        width: 100%;
        height: 100%;
        background-image: url('~@/assets/images/defaultMark01.png');
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .iconfont {
        font-size: 16px;
        // color: @homeTextColor;
        color: #fff;
      }
    }

    /deep/ .marker_city_box1 {
      position: relative;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      opacity: 0.7;
      border: 1px solid #eee;
      background-color: #003a9c;
      // span {
      //   position: absolute;
      //   box-sizing: border-box;
      //   border: none;
      //   background-color: #003a9c;
      //   border-radius: 50%;
      //   animation: animate 4s linear infinite;
      //   animation-delay: calc(0.5s * var(--i));
      //   -webkit-animation: animate 4s linear infinite;
      //   -webkit-animation-delay: calc(0.5s * var(--i));
      // }
      // .box {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 22px;
      //   height: 22px;
      //   z-index: 999;
      //   opacity: 0.7;
      //   background-color: #003a9c;
      //   border-radius: 50%;
      //   border: 1px solid #eee;
      // }
    }

    /deep/ .marker_city_box2 {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      opacity: 0.7;
      border: 1px solid #eee;
      background-color: #0071db;
    }

    /deep/ .marker_city_box3 {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 1px solid #eee;
      opacity: 0.7;
      background-color: #55a1ff;
    }

    /deep/ .infowindow_add_box {
      padding: 5px 10px;
      border-radius: 8px;
      border-width: 1px;
      border-style: solid;
      // color: @homeTextColor;
      background-color: rgba(12, 5, 75, 1);
      font-size: 16px;
      font-family: YouSheBiaoTiYuan;
      // color: #FFD700;
      // background-image: -webkit-linear-gradient(top, #ffffff, #FFD700);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;

      .info_title {
        margin-right: 10px;
      }

      .info_count {
        color: #0EDB95;
        color: #FFD700;
        background-image: -webkit-linear-gradient(top, #ffffff, #FFD700);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

    }

    /deep/ .infowindow_park_box {
      width: 380px;
      height: 200px;
      // background-image: url("../../../../assets/images/info-bg.png");
      background-image: url("~@/assets/images/info-bg02.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      position: relative;
      padding: 30px 20px;
      box-sizing: border-box;

      .leftIcon {
        position: absolute;
        width: 20px;
        height: 40px;
        left: 20px;
        background-size: 100% 90%;
        background-position: center bottom;
        background-repeat: no-repeat;
      }

      .leftIconOpen {
        background-image: url('~@/assets/images/leftOpening.png');
      }

      .leftIconClose {
        background-image: url('~@/assets/images/leftClosed.png');
      }

      .park_name_box {
        position: absolute;
        left: 40px;
        width: 60%;
        font-size: 16px;
        text-align: left;
        // padding: 10px 40px;
        box-sizing: border-box;
        // color: @homeTextColor;
        color: #fff;

        .park_name_box_font_size {
          font-size: 12px;
        }
      }

      .checkVideo {
        position: absolute;
        top: 30px;
        right: 16px;
        width: 55px;
        height: 55px;
        background-image: url('~@/assets/images/right-info-open03.png');
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
        color: #fff;
        text-align: center;
        line-height: 55px;
      }

      .status_box {
        width: 90px;
        height: 40px;
        font-size: 14px;
        position: absolute;
        top: 30px;
        right: 16px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .openingPark {
        background-image: url('~@/assets/images/openingPark.png');
      }

      .closedPark {
        background-image: url('~@/assets/images/closedPark.png');
      }

      .address_box {
        padding: 8px 15px;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 100%;
        display: flex;
        align-items: center;
        text-align: left;
        // color: @homeTextColor;
        color: #fff;

        .iconfont {
          font-size: 18px;
          margin-right: 10px;
          // color: @homeTextColor;
          color: #fff;
          margin-bottom: 10px;
        }

        span {
          margin-bottom: 10px;
        }
      }

      .count_box {
        transform: translateY(70px);
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-evenly;
        box-sizing: border-box;

        .count_item {
          width: 24%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-image: url('~@/assets/images/info-inner01.png');
          background-size: 100% 100%;
          background-position: center center;
          background-repeat: no-repeat;

          // &:nth-child(2n + 1) {
          //   background-image: url("../../../../assets/images/count-line.png");
          //   background-repeat: no-repeat;
          //   background-position: right center;
          // }

          .count_span {
            font-family: YouSheBiaoTiYuan;
            font-size: 18px;
            color: #00FFF5;
            background-image: -webkit-linear-gradient(top, #00FFF5, #fff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .title_span {
            font-size: 12px;
            // color: @homeTextColor;
            color: #fff;
          }
        }
      }

      .count_box_large {
        transform: translateY(70px);
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;

        .count_item_large {
          width: 45%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-image: url('~@/assets/images/info-inner01.png');
          background-size: 100% 100%;
          background-position: center center;
          background-repeat: no-repeat;

          // &:nth-child(2n + 1) {
          //   background-image: url("../../../../assets/images/count-line.png");
          //   background-repeat: no-repeat;
          //   background-position: right center;
          // }

          .count_span {
            font-family: YouSheBiaoTiYuan;
            font-size: 18px;
            color: #00FFF5;
            background-image: -webkit-linear-gradient(top, #00FFF5, #fff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .title_span {
            font-size: 12px;
            // color: @homeTextColor;
            color: #fff;
          }
        }
      }

      .count_box_close {
        .count_item .count_span {
          color: #fff;
        }
      }
    }

    /deep/ .infowindow_user_box {
      width: 250px;
      height: 130px;
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      text-align: left;
      background-image: url('~@/assets/images/info-bg02.png');
      background-size: 100% 100%;
      background-position: center center;

      .left_img {
        width: 90px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('~@/assets/images/leftImgBack.png');
        background-size: 100% 100%;
        background-position: center center;

        img {
          width: 70%;
          height: 70%;
        }
      }

      .right_box {
        height: 90px;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;

        .row_box {
          font-size: 14px;
          // color: @homeTextColor;
          color: #fff;

          .iconfont {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .thress3Dbox {
    width: 100%;
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  /deep/ .tooltip_box {
    position: absolute;
    z-index: 9999;

    .tooltip_item {
      display: flex;
      // color: @homeTextColor;
      color: #fff;

      .tooltip_item_box {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #fff;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

// 浅色
.city-park-command-center-map-light {
  width: 100%;
  height: 100%;
  position: relative;

  /deep/ .city-park-command-center-map-heard {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 70px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    .left_weather_box {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      align-items: center;

      .weather_img {
        height: 45px;
      }

      .city_box,
      .weather_box {
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-left: 12px;

        .iconfont {
          font-size: 26px;
          line-height: 26px;
          font-weight: 400;
          margin-right: 10px;
          background-image: -webkit-linear-gradient(-90deg, #11ABFB 40%, #0060FB 60%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .title01 {
          color: #666;
          font-size: 24px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
        }

        .title02 {
          color: #666;
          font-size: 24px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          margin-right: 50px;
        }
      }
    }

    .heard-img-box {
      width: 100%;
      height: 100%;
      background-image: url("~@/assets/images/map-big02.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;

      .count {
        font-size: 48px;
        color: #01a5fa;
        background: -moz-linear-gradient(top, #01a5fa, #0ddb95);
        background: -webkit-gradient(linear,
            0 70%,
            100% 70%,
            from(#01a5fa),
            to(#0ddb95));
        background: -o-linear-gradient(top, #01a5fa, #0ddb95);
        background: -webkit-linear-gradient(top, #01a5fa, #0ddb95);
        /*Opera11*/
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .time_box {
      position: absolute;
      top: 50%;
      right: 200px;
      transform: translate(0, -50%);
      display: flex;
      align-items: center;

      .iconfont {
        font-size: 26px;
        line-height: 26px;
        font-weight: 400;
        margin-right: 10px;
        background-image: -webkit-linear-gradient(-90deg, #11ABFB 40%, #0060FB 60%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .yearMonthDay {
        color: #666;
        font-size: 24px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        margin-right: 50px;
      }

      .hourTime {
        color: #666;
        font-size: 24px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
      }
    }

    // 标准间展示与隐藏，退出全屏下拉框
    .icon_box {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(0, -50%);

      .iconfont {
        cursor: pointer;
        font-size: 26px;
        line-height: 26px;
        font-weight: 400;
        margin-right: 10px;
        background-image: -webkit-linear-gradient(-90deg, #11ABFB 40%, #0060FB 60%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .dropdownMenu {
        position: absolute;
        width: 120px;
        background-color: #fff;
        top: 95%;
        right: 5px;
        border: 1px solid #666;
        border-radius: 5px;
        box-sizing: border-box;

        ul {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          box-sizing: border-box;

          li {
            margin: 10px 0;
            padding: 5px;
            box-sizing: border-box;

            &:hover {
              background-color: rgba(192, 192, 192, .9);
              // border: 1px solid #02A2D0;
            }
          }

          .iconfont {
            background-image: -webkit-linear-gradient(-90deg, #666 40%, #666 60%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 600;
            font-size: 15px;
            cursor: pointer;

            &::before {
              margin-right: 8px;
            }
          }
        }

      }
    }
  }

  /deep/ .map-right-info {
    position: absolute;
    z-index: 9999;
  }

  #amapall {
    width: 100%;
    height: 100%;

    /deep/.amap-marker {
      .amap-marker-content {
        .movebox {
          background-color: #fff;
          position: relative;

          .circle-marker-content {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 10px;
            width: 10px;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            text-align: center;
            background: #25F4A6;
            border: 1px solid #25F4A6;

            // box-shadow: 0px 0px 5px #25F4A6;
            @keyframes scaless {
              0% {
                transform: scale(0);
                opacity: 0.5;
              }

              50% {
                transform: scale(0.5);
                opacity: 0.2;
              }

              100% {
                transform: scale(1);
                opacity: 0;
              }
            }

            .item {
              width: 100%;
              height: 100%;
              position: absolute;
              border-radius: 100%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            .item:before {
              content: "";
              position: absolute;
              left: 0px;
              top: 0px;
              display: inline-block;
              width: 100%;
              height: 100%;
              // border: 1px solid #25F4A6;
              border-radius: 100%;
              opacity: 0;
              background-color: #25F4A6;
              animation: scaless 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
            }

            .item1:before {
              animation-delay: 0s;
            }

            .item2:before {
              animation-delay: 1s;
            }

            .item3:before {
              animation-delay: 2s;
            }

            .item4:before {
              animation-delay: 3s;
            }

            .item5::before {
              animation-delay: 4s;
            }
          }
        }
      }
    }

    /deep/ .alarmDevice {
      text-align: center;
      margin: 0 auto;
      width: 30px;
      height: 30px;
      background-color: #f13737;
      box-shadow: 0px 0px 15px #f61212;
      border-radius: 50%;
      -webkit-animation-name: "alarmDeviceBreath";
      /*动画属性名，也就是我们前面keyframes定义的动画名*/
      -webkit-animation-duration: 1s;
      /*动画持续时间*/
      -webkit-animation-timing-function: ease;
      /*动画频率，和transition-timing-function是一样的*/
      -webkit-animation-delay: 0s;
      /*动画延迟时间*/
      -webkit-animation-iteration-count: infinite;
      /*定义循环资料，infinite为无限次*/
      -webkit-animation-direction: alternate;
      /*定义动画方式*/
    }

    /deep/ .amap-overlay-text-container {
      font-size: 15px;
      color: #fcb12d;
      font-weight: 700;
      background: none;
      border: none;
    }

    /deep/ .marker__box {
      width: 45px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      border-radius: 50%;
      white-space: normal;
      position: relative;

      // border: 1px solid #fff;
      .openMark {
        width: 100%;
        height: 100%;
        background-image: url('~@/assets/images/openMark-light01.png');
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;

        &:hover {
          animation: expansion 1.5s forwards;
          // animation-iteration-count: infinite;
        }

      }

      .closeMark {
        width: 100%;
        height: 100%;
        background-image: url('~@/assets/images/closeMark-light01.png');
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .defaultMark {
        width: 100%;
        height: 100%;
        background-image: url('~@/assets/images/defaultMark-light01.png');
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .iconfont {
        font-size: 16px;
        color: @homeTextColor;
        // animation: shakeIcon 2s linear;
        // animation-iteration-count: infinite;
      }
    }

    /deep/ .marker_city_box1 {
      position: relative;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      opacity: 0.7;
      border: 1px solid #eee;
      background-color: #01a5fb;

      // span {
      //   position: absolute;
      //   box-sizing: border-box;
      //   border: none;
      //   background-color: #01a5fb;
      //   border-radius: 50%;
      //   animation: animate 3s linear infinite;
      //   animation-delay: calc(0.8s * var(--i));
      //   -webkit-animation: animate 3s linear infinite;
      //   -webkit-animation-delay: calc(0.8s * var(--i));
      // }

      // .box {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 22px;
      //   height: 22px;
      //   z-index: 999;
      //   opacity: 0.7;
      //   background-color: #01a5fb;
      //   border-radius: 50%;
      //   border: 1px solid #eee;
      // }
    }

    /deep/ .marker_city_box2 {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      opacity: 0.7;
      border: 1px solid #eee;
      background-color: #53a8ff;
    }

    /deep/ .marker_city_box3 {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 1px solid #eee;
      opacity: 0.7;
      background-color: #8cc5ff;
    }

    /deep/ .infowindow_add_box {
      padding: 5px 10px;
      border-radius: 8px;
      border-width: 0;
      border-style: solid;
      color: @homeTextColor;
      background-color: #fff;
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;

      .info_title {
        color: #666;
        margin-right: 10px;
      }

      .info_count {
        color: #0EDB95;

      }
    }

    /deep/ .infowindow_park_box {
      width: 380px;
      height: 200px;
      padding: 30px 20px;
      box-sizing: border-box;
      background: rgba(253, 254, 255, .9);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: 5px 5px 5px 5px;
      position: relative;

      .leftIcon {
        position: absolute;
        width: 20px;
        height: 40px;
        left: 20px;
        background-size: 80% 90%;
        background-repeat: no-repeat;
        background-position: center bottom;
      }

      .leftIconOpen {
        background-image: url('~@/assets/images/leftOpening-light.png');
      }

      .leftIconClose {
        background-image: url('~@/assets/images/leftClosed-light.png');
      }

      .park_name_box {
        position: absolute;
        left: 40px;
        width: 60%;
        font-size: 16px;
        text-align: left;
        // padding: 10px 40px;
        box-sizing: border-box;
        color: black;

        .park_name_box_font_size {
          font-size: 12px;
        }
      }

      .checkVideo {
        position: absolute;
        top: 30px;
        right: 16px;
        width: 55px;
        height: 55px;
        background-image: url('~@/assets/images/checkVideoLight.png');
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
        color: #fff;
        text-align: center;
        line-height: 55px;
      }

      .status_box {
        width: 90px;
        height: 40px;
        font-size: 14px;
        position: absolute;
        top: 30px;
        right: 16px;
        text-align: center;
        line-height: 40px;
        color: green;
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .openingPark {
        color: #0EDB95;
        background-image: url('~@/assets/images/openingPark-light.png');
      }

      .closedPark {
        color: #FFB22E;
        background-image: url('~@/assets/images/closedPark-light.png');
      }

      .address_box {
        padding: 8px 15px;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 100%;
        display: flex;
        align-items: center;
        text-align: left;
        color: #666;

        .iconfont {
          width: 35px;
          height: 35px;
          background: #ffffff;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          border-radius: 5px 5px 5px 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          margin-right: 10px;
          color: #01a5fa;
        }
      }

      .count_box {
        transform: translateY(70px);
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-evenly;
        box-sizing: border-box;

        .count_item {
          width: 23%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #FAFAFA;
          border: 1px solid #fff;
          // box-shadow: 0px 1px 5px  gray;
          border-radius: 5px;
          margin-bottom: 5%;

          .count_span {
            font-family: YouSheBiaoTiYuan;
            font-size: 18px;
            color: #666;
          }

          .title_span {
            font-size: 12px;
            color: #989898;
          }
        }
      }

      .count_box_large {
        transform: translateY(70px);
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: flex-start;
        box-sizing: border-box;

        .count_item_large {
          width: 45%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #FAFAFA;
          border: 1px solid #fff;
          // box-shadow: 0px 1px 5px  gray;
          border-radius: 5px;
          margin-bottom: 5%;

          .count_span {
            font-family: YouSheBiaoTiYuan;
            font-size: 18px;
            color: #666;
          }

          .title_span {
            font-size: 12px;
            color: #989898;
          }
        }
      }

      .count_box_close {
        .count_item .count_span {
          color: #666;
        }
      }
    }

    /deep/ .infowindow_user_box {
      width: 250px;
      height: 130px;
      padding: 10px;
      display: flex;
      align-items: center;
      text-align: left;
      box-sizing: border-box;
      background: rgba(253, 254, 255, .8);
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

      .left_img {
        width: 90px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        img {
          width: 70%;
          height: 70%;
        }
      }

      .right_box {
        height: 90px;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;

        .row_box {
          font-size: 14px;
          color: #666;

          .iconfont {
            margin-right: 5px;
            color: #01a5fa;
          }
        }
      }
    }

    /deep/.amap-marker-content {
      position: relative;

      /deep/.circle-marker-content {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 50px;
        width: 50px;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        text-align: center;
        background: blue;
        border: 1px solid blue;
        box-shadow: 0px 0px 14px blue;

        // .item_count {
        //   position: absolute;
        //   top: 50%;
        //   left: 50%;
        //   transform: translate(-50%, -50%);
        //   color: #1C77C3;
        //   font-weight: bold;
        //   font-size: 13px;
        //   z-index: 10;
        // }

        @keyframes scaless {
          0% {
            transform: scale(0);
            opacity: 1;
          }

          100% {
            transform: scale(2);
            opacity: 0;
          }
        }

        .item {
          width: 100%;
          height: 100%;
          position: absolute;
          border-radius: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .item:before {
          content: "";
          position: absolute;
          left: -1px;
          top: -1px;
          display: inline-block;
          width: 100%;
          height: 100%;
          border: 1px solid blue;
          border-radius: 100%;
          opacity: 0;
          background-color: blue;
          animation: scaless 5s infinite cubic-bezier(0, 0, 0.49, 1.02);
        }

        .item1:before {
          animation-delay: 0s;
        }

        .item2:before {
          animation-delay: 1s;
        }

        .item3:before {
          animation-delay: 2s;
        }

        .item4:before {
          animation-delay: 3s;
        }

        .item5::before {
          animation-delay: 4s;
        }
      }
    }
  }

  /deep/ .tooltip_box {
    position: absolute;
    z-index: 9999;

    .tooltip_item {
      display: flex;
      color: @homeTextColor;

      .tooltip_item_box {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #fff;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

@keyframes animate {
  0% {
    width: 0;
    height: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    width: 50px;
    height: 50px;
    opacity: 0;
  }
}

@-webkit-keyframes animate {
  0% {
    width: 0;
    height: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    width: 50px;
    height: 50px;
    opacity: 0;
  }
}

/*
* 右侧数据显示或隐藏动画
*/
.map-right-info-show {
  animation: rightIconShow 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  -webkit-animation: rightIconShow 1s;
  /* Safari and Chrome */
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
}

.map-right-info-hidden {
  animation: rightIconHidden 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  -webkit-animation: rightIconHidden 1s;
  /* Safari and Chrome */
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
}

@keyframes rightIconHidden {
  from {
    right: 28%;
  }

  to {
    right: 20px;
  }
}

@-webkit-keyframes rightIconHidden {
  from {
    right: 28%;
  }

  to {
    right: 20px;
  }
}

@keyframes rightIconShow {
  from {
    right: -20px;
  }

  to {
    right: 28%;
  }
}

@-webkit-keyframes rightIconShow {
  from {
    right: -20px;
  }

  to {
    right: 28%;
  }
}

/*
* 左侧数据显示或隐藏动画
*/
.map-tooltip-info-show {
  animation: leftShow 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  -webkit-animation: leftShow 1s;
  /* Safari and Chrome */
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
}

.map-tooltip-info-hidden {
  animation: leftHidden 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  -webkit-animation: leftHidden 1s;
  /* Safari and Chrome */
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
}

@keyframes leftHidden {
  from {
    left: 28%;
    bottom: 35%;
  }

  to {
    left: 20px;
    bottom: 20px;
  }
}

@-webkit-keyframes leftHidden {
  from {
    left: 28%;
    bottom: 35%;
  }

  to {
    left: 20px;
    bottom: 20px;
  }
}

@keyframes leftShow {
  from {
    left: -20px;
    bottom: 20px;
  }

  to {
    left: 28%;
    bottom: 35%;
  }
}

@keyframes markMove {
  0% {
    transform: scale(0.5);
    opacity: 0.1;
  }

  50% {
    transform: scale(1);
    opacity: 0.7;
  }

  100% {
    transform: scale(1.5);
    opacity: 0.07;
  }
}

@keyframes expansion {
  0% {
    transform: scale(1) rotate(0deg);
  }

  10% {
    transform: scale(1) rotate(-30deg);
  }

  20% {
    transform: scale(1.2) rotate(10deg);
  }

  30% {
    transform: scale(1.2) rotate(-5deg);
  }

  40% {
    transform: scale(1.2) rotate(5deg);
  }

  // 75%{
  //   transform:scale(2) rotate(5deg);
  // }
  // 90%{
  //   transform:scale(2) rotate(-5deg);
  // }
  100% {
    // transform: scale(1);
    transform: scale(1.2) rotate(0deg);
  }
}

// @keyframes shakeIcon {
//   0% {

//     transform: scale(1);
//   }
//   25%{
//     transform: scale(2);
//   }
//   50%{
//     transform: scale(1.4);
//   }
//   75%{
//     transform: scale(0.8);
//   }
//   100% {
//     transform: scale(1);
//   }
// }
@-webkit-keyframes leftShow {
  from {
    left: -20px;
    bottom: 20px;
  }

  to {
    left: 28%;
    bottom: 35%;
  }
}
</style>
