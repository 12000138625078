<template>
  <div :class="['map-right-info-item', isCheck ? 'active': '']" @click="$emit('click', $event)">
    <div class="item-top">
      <div class="surround"></div>
      <i :class="`iconfont ${icon}`"></i>
    </div>
    <span class="title">{{title}}</span>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  props: {
    title: { type: String },
    icon: { type: String },
    isCheck: { type: Boolean, default: false }
  },
  data () {
    return {}
  },
  created () { },
  mounted () { },
  computed: {},
  watch: {},
  methods: {}
}
</script>
<style scoped lang="less">
.item-top{
  position: relative;
  .surround{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
