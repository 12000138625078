<template>
  <div class="command-center-device-item">
    <div class="item-right">
      <div class="item-left">
      <i :class="`iconfont  ${icon}`" :style="`font-size:${iconSize}px;`"></i>
      </div>
      <span class="count">{{count}}</span>
      <span class="title">{{title}}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  props: {
    icon: { type: String },
    iconSize: {
      type: String, default: '16'
    },
    title: { type: String },
    count: { type: Number }
  },
  data () {
    return {}
  },
  created () { },
  mounted () { },
  computed: {},
  watch: {},
  methods: {}
}
</script>
<style scoped lang="less">
</style>
