<!-- 视屏画面组件 -->
<template>
  <div :class="backModel===1?'videoBox':'videoBoxLight'">
    <div class="nosources" v-if="!this.videoMessage || !this.videoMessage.MainId">
      <span>无监控源</span>
    </div>
    <div class="videoPlace" v-else>
      <video class="videosource" ref="videoPlayers" :id="videoMessage.MainId+uniqueIndex" autoplay muted  @mouseenter="showMask=true" @mouseout="leaveMask" @dblclick="dblclick(videoMessage.ChannelName)">
      </video>
      <div class="zhezhao" v-show="showMask" @mouseenter="showMask=true"  @mouseout="leaveMask">
        <span>{{videoMessage.ChannelName }}</span>
      </div>
    </div>

  </div>
</template>

<script>
import flv from 'flv.js'
import { getMonitorUrlList } from '@/api/monitoring'
export default {
  name: 'videoBox',
  components: {},
  props: ['videoMessage', 'backModel', 'uniqueIndex'],
  data () {
    return {
      loading: true,
      url: '',
      // sources: '1',
      recordeFlag: false,
      MediaServerId: '',
      ChannelName: '',
      showMask: false // 遮罩
    }
  },
  watch: {
    videoMessage (newV, oldV) {
      this.url = ''
      console.log('vew', newV)
      if (newV && newV.MainId) {
      // this.detachMediaElement()
        if (this.player) {
          this.player.unload()
          this.player.detachMediaElement()
          this.player.destroy()
          this.player = null
        }
        this.fnGetMonitorUrlList(newV.MainId)
      }
    }
  },
  computed: {},
  methods: {
    // 获取视频流地址
    async fnGetMonitorUrlList (str) {
      const res = await getMonitorUrlList({ MainId: str })
      if (res && res.Data.length > 0) {
        if (res.Data[0].PlayUrl.length > 0) {
          this.url = res.Data[0].PlayUrl[0]
          setTimeout(() => {
            this.initVideo(res.Data[0].PlayUrl)
          }, 300)
          console.log('Playurl', this.videoMessage.ChannelName, this.url)
        }
      }
    },
    initVideo (playUrl) {
      if (flv.isSupported()) {
        const videoElement = document.getElementById(this.videoMessage.MainId + this.uniqueIndex) // 根据video盒子id寻找
        this.player = flv.createPlayer({
          type: 'flv',
          url: playUrl[0],
          // url: 'wss://fatcity.lebopark.com/188_128_0_240/rtp/576730972146372608.flv',
          isLive: true,
          cors: true
        },
        {
          enableStashBuffer: false,
          stashInitialSize: 128,
          autoCleanupSourceBuffer: true
        }
        )
        // this.player.unload()

        this.player.attachMediaElement(videoElement)
        this.player.load() // 加载
        // this.player.play() // 播放

        // ---
        // this.player = flv.createPlayer({
        //   type: 'flv',
        //   url: this.videoMessage.PlayUrl.length > 0 ? this.videoMessage.PlayUrl[0] : '',
        //   // url: 'wss://fatcity.lebopark.com/188_128_0_240/rtp/576730972146372608.flv',
        //   isLive: true,
        //   cors: true
        // },
        // {
        //   enableStashBuffer: false,
        //   stashInitialSize: 128,
        //   autoCleanupSourceBuffer: true
        // }
        // )
        // this.player.attachMediaElement(videoElement)
        // this.player.load() // 加载
        // this.player.play() // 播放
      }
    },
    dblclick (ChannelName) {
      // this.requestFullScreen(document.getElementById(this.videoMessage.MainId + this.uniqueIndex))
      this.$emit('fullVideo', this.url, ChannelName)
    },
    // // 全屏
    // requestFullScreen (element) {
    //   if (element.requestFullscreen) {
    //     element.requestFullscreen()
    //   } else if (element.mozRequestFullScreen) {
    //     element.mozRequestFullScreen()
    //   } else if (element.msRequestFullscreen) {
    //     element.msRequestFullscreen()
    //   } else if (element.oRequestFullscreen) {
    //     element.oRequestFullscreen()
    //   } else if (element.webkitRequestFullscreen) {
    //     element.webkitRequestFullScreen()
    //   } else {
    //     var docHtml = document.documentElement
    //     var docBody = document.body
    //     var videobox = document.getElementById('monitors')
    //     var cssText = 'width:100%;height:100%;overflow:hidden;'
    //     docHtml.style.cssText = cssText
    //     docBody.style.cssText = cssText
    //     videobox.style.cssText = cssText + ';' + 'margin:0px;padding:0px;'
    //     document.IsFullScreen = true
    //   }
    // },
    // fullScreen () {
    //   // 全屏展示
    //   // this.requestFullScreen(document.getElementById(this.id))
    // },
    // // 退出全屏
    // exitFullscreen () {
    //   if (document.exitFullscreen) {
    //     document.exitFullscreen()
    //   } else if (document.msExitFullscreen) {
    //     document.msExitFullscreen()
    //   } else if (document.mozCancelFullScreen) {
    //     document.mozCancelFullScreen()
    //   } else if (document.oRequestFullscreen) {
    //     document.oCancelFullScreen()
    //   } else if (document.webkitExitFullscreen) {
    //     document.webkitExitFullscreen()
    //   } else {
    //     var docHtml = document.documentElement
    //     var docBody = document.body
    //     var videobox = document.getElementById('monitors')
    //     docHtml.style.cssText = ''
    //     docBody.style.cssText = ''
    //     videobox.style.cssText = ''
    //     document.IsFullScreen = false
    //   }
    // },
    leaveMask () {
      this.showMask = false
    },
    detachMediaElement () {
      this.flvPlayer.pause()
      this.flvPlayer.unload()
      this.flvPlayer.detachMediaElement()

      this.flvPlayer.destroy()
      this.flvPlayer = ''
    }

  },
  created () { },
  mounted () {
    console.log('videoMessage', this.videoMessage)
    if (this.videoMessage && this.videoMessage.MainId) {
      this.fnGetMonitorUrlList(this.videoMessage.MainId)
      // this.initVideo()
    }
  }
}
</script>

<style scoped lang="less">
.videoBox {
  width: 100%;
  height: 100%;
  // background-color: green;
  background-image: url('~@/assets/img/mclz/cctvborder.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  margin: 0 auto;

  .videoPlace{
    .zhezhao{
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 20%;
      background-color: rgba(0,0,0,.6);
      text-align: center;
      color: #666;
      display:flex;
      justify-content:center;
      align-items:center;
      span{
        overflow: hidden;
              /* 超出一行文字自动隐藏 */
              text-overflow: ellipsis;
              /* 文字隐藏后添加省略号 */
              white-space: nowrap;
              /* 强制文本不换行 */
      }
    }
    video{
      width: 90%;
      height: 90%;
    }
  }
}
.videoBoxLight {
  width: 100%;
  height: 100%;
  background-position: center;
  position: relative;
  margin: 0 auto;
  .videoPlace{
    .zhezhao{
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 20%;
      background-color: rgba(0,0,0,.6);
      text-align: center;
      color: #fff;
      display:flex;
      justify-content:center;
      align-items:center;
      span{
        overflow: hidden;
              /* 超出一行文字自动隐藏 */
              text-overflow: ellipsis;
              /* 文字隐藏后添加省略号 */
              white-space: nowrap;
              /* 强制文本不换行 */
      }
    }
  }
  video{
      width: 90%;
      height: 90%;
    }
}
.nosources {
    text-align: center;
    font-size: 16px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    display:flex;
    justify-content:center;
    align-items:center;
  }
  .videosource {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(121, 121, 121, 0.5);
  }
</style>
